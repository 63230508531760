import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import icon from "../../images/cta/top-icon.png";
import { Link } from "react-router-dom";
// import Path from "../../images1/volunteer/home/path.png";
import Path from "../../images1/volunteer/home/benefit.jpg";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ServiceCard from "../../components/ServiceSection/ServiceCard";

import cImg1 from "../../images1/school-college/benefits/encourage.png";
import cImg2 from "../../images1/school-college/benefits/experience.png";
import cImg3 from "../../images1/school-college/benefits/time.png";
import cImg4 from "../../images1/school-college/benefits/recognition.png";
import cImg5 from "../../images1/school-college/benefits/register.png";
import cImg6 from "../../images1/school-college/benefits/volunteering.png";
import cImg7 from "../../images1/school-college/benefits/diagram.png";
import cImg8 from "../../images1/school-college/benefits/reward.png";
import cImg9 from "../../images1/school-college/benefits/award-symbol.png";
import cImg10 from "../../images1/school-college/benefits/build.png";
import cImg11 from "../../images1/school-college/benefits/marketing.png";
import cImg12 from "../../images1/school-college/benefits/tracking.png";
import cImg13 from "../../images1/school-college/benefits/institution.png";
import cImg14 from "../../images1/school-college/benefits/shuttle.png";
import cImg15 from "../../images1/school-college/benefits/job.png";
import cImg16 from "../../images1/school-college/benefits/expand.png";
import cImg17 from "../../images1/school-college/benefits/ngo.png";
import cImg18 from "../../images1/school-college/benefits/foster-family.png";
import cImg19 from "../../images1/school-college/benefits/community-building.png";
import cImg20 from "../../images1/school-college/benefits/encouragement.png";

const Services = [
  {
    Id: "01",
    sImg: cImg1,
    sSImg: sSimg1,
    title: "Encourage Student Engagement",
    slug: "1",
    description:
      "Engage students in impactful community service, promoting a culture of responsibility and social contribution.",
  },
  {
    Id: "02",
    sImg: cImg2,
    sSImg: sSimg2,
    title: "Real-World Experience",
    slug: "2",
    description:
      "Provide students with practical experience through diverse tasks like event management and community projects, helping them gain valuable skills.",
  },
  {
    Id: "03",
    sImg: cImg3,
    sSImg: sSimg3,
    title: "Efficient Task Tracking",
    slug: "3",
    description:
      "Schools and colleges can easily monitor ongoing tasks, ensuring effective management of student activities.",
  },
  {
    Id: "04",
    sImg: cImg4,
    sSImg: sSimg4,
    title: "Recognition and Motivation",
    slug: "4",
    description:
      "With gamified features like leaderboards, students are recognised for their contributions, motivating higher participation and enthusiasm.",
  },
  {
    Id: "05",
    sImg: cImg5,
    sSImg: sSimg1,
    title: "Register with Sevak Army",
    slug: "5",
    description:
      "Institutions can register seamlessly, encouraging students to volunteer in a wide range of volunteering tasks.",
  },
  {
    Id: "06",
    sImg: cImg6,
    sSImg: sSimg2,
    title: "Flexible Volunteering",
    slug: "6",
    description:
      "Students can choose tasks that align with their interests and fit within their academic schedules, enabling balanced participation.",
  },
  {
    Id: "07",
    sImg: cImg7,
    sSImg: sSimg3,
    title: "Grow Student Engagement in Community",
    slug: "7",
    description:
      "Encourage students to join community projects across sectors like education, healthcare, and the environment, fostering a sense of social responsibility.",
  },
  {
    Id: "08",
    sImg: cImg8,
    sSImg: sSimg4,
    title: "Earn Points and Track Progress on the Leaderboard",
    slug: "8",
    description:
      "Every completed task contributes points to your institution’s ranking on Sevak Army’s leaderboard. More engagement leads to a higher institutional rank!",
  },
  {
    Id: "09",
    sImg: cImg9,
    sSImg: sSimg1,
    title: "Award Students with e-Badges and e-Certificates",
    slug: "9",
    description:
      "Recognise student achievements with e-badges and e-certificates, adding motivation to their volunteer journey.",
  },
  {
    Id: "10",
    sImg: cImg10,
    sSImg: sSimg2,
    title: "Build a Culture of Changemakers",
    slug: "10",
    description:
      "Inspiring students to volunteer reflects your institution’s commitment to social responsibility, positioning you as a leader in community service.",
  },
  {
    Id: "11",
    sImg: cImg11,
    sSImg: sSimg3,
    title: "Create a Purpose-Driven Campus Culture",
    slug: "11",
    description:
      "Promote a campus environment where students work together on meaningful causes, building lifelong values and relationships.",
  },
  {
    Id: "12",
    sImg: cImg12,
    sSImg: sSimg4,
    title: "Track Collective Impact Easily",
    slug: "12",
    description:
      "Use Sevak Army’s platform to monitor student participation, completed tasks, and the overall community impact created by your institution.",
  },
  {
    Id: "13",
    sImg: cImg13,
    sSImg: sSimg1,
    title: "Get Recognised for Institutional Contributions",
    slug: "13",
    description:
      "Gain recognition for your institution’s social impact as students complete tasks, showcasing your role in driving positive change.",
  },
  {
    Id: "14",
    sImg: cImg14,
    sSImg: sSimg2,
    title: "Boost Your Institution’s Reputation for Social Engagement",
    slug: "14",
    description:
      "Supporting student volunteering enhances your institution’s image as a socially responsible educational entity.",
  },
  {
    Id: "15",
    sImg: cImg15,
    sSImg: sSimg3,
    title: "Offer Students Practical Real-World Experience",
    slug: "15",
    description:
      "Enable students to develop hands-on skills through volunteer roles that complement their academic learning.",
  },
  {
    Id: "16",
    sImg: cImg16,
    sSImg: sSimg4,
    title: "Expand Influence Beyond the Classroom",
    slug: "16",
    description:
      "Connecting students with real-world causes expands your institution’s impact beyond academics, nurturing well-rounded, socially conscious graduates.",
  },
  {
    Id: "17",
    sImg: cImg17,
    sSImg: sSimg1,
    title: "Connect Students with Trusted NGOs",
    slug: "17",
    description:
      "Partner students with verified NGOs through Sevak Army, ensuring their efforts go to impactful, trustworthy causes.",
  },
  {
    Id: "18",
    sImg: cImg18,
    sSImg: sSimg2,
    title: "Foster Lasting Positive Change in Communities",
    slug: "18",
    description:
      "Each student’s volunteer work strengthens communities, allowing your institution to make a meaningful social impact.",
  },
  {
    Id: "19",
    sImg: cImg19,
    sSImg: sSimg3,
    title: "Strengthen Your Institution’s Social Responsibility Profile",
    slug: "18",
    description:
      "Consistently promoting community involvement positions your school or college as a champion of social responsibility.",
  },
  {
    Id: "20",
    sImg: cImg20,
    sSImg: sSimg4,
    title: "Encourage and Recognise Student Leadership",
    slug: "18",
    description:
      "Motivate students to take on leadership roles through volunteer initiatives, nurturing them as compassionate, active citizens",
  },
];

const SchoolCollegeBenefitsPage = (props) => {
  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5 pb-0">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Path} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    <span>Benefits</span>
                    <h2 className="about-heading-second text-titleeee">
                      Benefits to Register Your Institute
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Sevak Army offers schools and colleges a platform to
                    register and connect students with meaningful volunteer
                    opportunities. By registering with the Sevak Army,
                    institutions can provide students with real-world
                    experiences that foster leadership, teamwork, and social
                    responsibility. This platform simplifies the process of
                    engaging students in community service while tracking their
                    progress and recognising their efforts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container section-padding">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle title="Empowering students to make a real impact" />
          </div>
        </div>
        <div className="service-wrap">
          <div className="row justify-content-center column-gappp">
            {Services.slice(0, 20).map((Service, sitem) => (
              <div
                className="col col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <ServiceCard
                    width="313px"
                    height="425px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <section className="wpo-cta-section-s3 section-padding pt-0">
        <div className="container">
          <div className="bg-color" style={{ background: "#007560" }}>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <span>Join the Movement</span>
                  <h3
                    className="pt-3 px-2"
                    style={{ color: "white", fontSize: "32px" }}
                  >
                    Empower Students, Transform Communities
                  </h3>
                  <p className="text-white text-center">
                    Register today with Sevak Army and give your students the
                    opportunity to make a real impact through volunteering.
                    Inspire social change and leadership in your institution.
                  </p>
                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn mt-3"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Below section not in requirement */}
      {/* section start */}

      {/* <section className="wpo-cta-section section-padding">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Join the Movement</span>
                    //  <h2>Register Now and Start Volunteering</h2> 
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Register today with Sevak Army and give your students the
                      opportunity to make a real impact through volunteering.
                      Inspire social change and leadership in your institution.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <span> Empower Students, Transform Communities</span>
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* section end */}
    </>
  );
};

export default SchoolCollegeBenefitsPage;
