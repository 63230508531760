import React, { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import Logo from "../../images/logo.svg";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";
import NgoRegistrationProcessSection from "./NgoRegistrationProcessSection";

function NgoRegistrationProcess({show, setShow}) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={'Registration Process'} pagesub={'Registration Process'} />
            <NgoRegistrationProcessSection show={show} setShow={setShow}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default NgoRegistrationProcess;
