import React, { Fragment } from "react";
import Logo from '../images/logo.svg'
import Navbar from "./Navbar/Navbar";
import PageTitle from "./pagetitle/PageTitle";
import Footer from "./footer/Footer";
import Scrollbar from "./scrollbar/scrollbar";
import ReferAndEarnPage from "./Volunteer/ReferAndEarnPage";

function ReferAndEarn() {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"Refer And Earn"} pagesub={"Volunteer"} />
            <ReferAndEarnPage />
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default ReferAndEarn;
