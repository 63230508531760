import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import FunFact2 from "../../components/FunFact/FunFact2";
import Logo from "../../images/logo.svg";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Slider from "react-slick";
import Shape from "../../images/testimonial/bg.png";
import { Link } from "react-router-dom";
import Vision from "../../images/service/arrow.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import blog1 from "../../images1/team/sevak-army-team-7.jpg";
import blog2 from "../../images1/team/sevak-army-team-3.jpg";
import blog3 from "../../images1/team/sevak-army-team-8.jpg";
import Instagram from "../../api/Instagram";
import icon from "../../images/cta/top-icon.png";

/* team imags */
import team1 from "../../images1/team/sevak-army-team-1.jpg";
import team2 from "../../images1/team/sevak-army-team-7.jpg";
import team3 from "../../images1/team/sevak-army-team-3.jpg";
import team4 from "../../images1/team/sevak-army-team-6.jpg";
import team5 from "../../images1/team/sevak-army-team-5.jpg";

/* HeroSlide Image  */
import HeroSlide1 from "../../images1/school-college/home/sevak-army-school-college-home-slider-1.jpg";
import HeroSlide2 from "../../images1/school-college/home/sevak-army-school-college-home-slider-2.jpg";
import HeroSlide3 from "../../images1/school-college/home/sevak-army-school-college-home-slider-3.jpg";
import HeroSlide4 from "../../images1/school-college/home/sevak-army-school-college-home-slider-4.jpg";
import abImg1 from "../../images1/school-college/home/sevak-army-school-college-home-2.jpg";
import abImg4 from "../../images1/school-college/home/sevak-army-influencer-services-12.png";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ServiceCard from "../../components/ServiceSection/ServiceCard";
// import simg1 from "../../images/service/1.svg";
// import simg2 from "../../images/service/2.svg";
// import simg3 from "../../images/service/3.svg";
// import simg4 from "../../images/service/4.svg";
// import simg5 from "../../images/service/4.svg";

import sImg1 from "../../images/service/inspirational-2.png";
import sImg2 from "../../images/service/encourage.png";
import sImg3 from "../../images/service/share-link-2.png";
import sImg4 from "../../images/service/collaboration.png";
import sImg5 from "../../images/service/hard-work.png";

import vsImg1 from "../../images/service/opportunity.png";
import vsImg2 from "../../images/service/experience.png";
import vsImg3 from "../../images/service/accredited.png";

import Img1 from "../../images/service-single/awareness.png";
import Img2 from "../../images/service-single/social-support.png";
import Img3 from "../../images/service-single/school.png";

import aImg1 from "../../images/service-single/notification.png";
import aImg2 from "../../images/service-single/new-message.png";
import aImg3 from "../../images/service-single/time.png";
import aImg4 from "../../images/service-single/impact.png";
import aImg5 from "../../images/service-single/school.png";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import NewsletterPopup from "../../components/NewsletterPopup";

const SchoolCollegeHome = (props) => {
  const [shownewsletter, setShowNewsletter] = useState(false);

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const Services = [
    {
      Id: "01",
      sImg: vsImg1,
      sSImg: sSimg1,
      title: "Regular Updated Opportunities",
      slug: "1",
      description:
        "The Sevak Army frequently updates the platform with new volunteer tasks and projects, offering students from schools and colleges fresh opportunities to get involved and make a difference in the communities.",
    },
    {
      Id: "02",
      sImg: vsImg2,
      sSImg: sSimg2,
      title: "Gain Real-World Experience",
      slug: "2",
      description:
        "Sevak Army allows students to participate in hands-on projects that directly benefit communities. These experiences help build essential skills like teamwork, leadership, and problem-solving",
    },
    {
      Id: "03",
      sImg: vsImg3,
      sSImg: sSimg3,
      title: "Earn Recognition and Certificates",
      slug: "3",
      description:
        "Volunteers receive digital certificates of achievement for their contributions, providing recognition that can be shared on social media or used in future career opportunities.",
    },
  ];

  const Services1 = [
    {
      Id: "01",
      sImg: sImg1,
      sSImg: sSimg1,
      title: "Inspire to Serve",
      slug: "1",
      description:
        "Sevak Army offers schools and colleges a platform to inspire students to volunteer for impactful causes, encouraging a commitment to social service and community welfare.",
    },
    {
      Id: "02",
      sImg: sImg2,
      sSImg: sSimg4,
      title: "Develop a Culture of Service",
      slug: "4",
      description:
        "Encouraging student volunteering fosters a service culture, building leadership, empathy, and commitment to social good.",
    },
    {
      Id: "03",
      sImg: sImg3,
      sSImg: sSimg2,
      title: "Share a Unique Link",
      slug: "2",
      description:
        "After registering, your institute will receive a unique link to share with students, allowing them to easily sign up as volunteers and start making a difference.",
    },
    {
      Id: "04",
      sImg: sImg4,
      sSImg: sSimg3,
      title: "Support Meaningful Causes",
      slug: "3",
      description:
        "Promoting Sevak Army helps students engage in causes like education, healthcare, and sustainability, building responsibility and empathy.",
    },
    {
      Id: "05",
      sImg: sImg5,
      sSImg: sSimg4,
      title: "Recognise Efforts",
      slug: "4",
      description:
        "Sevak Army’s leaderboard and achievements recognize students and your institute, motivating ongoing engagement and celebrating social impact.",
    },
  ];

  const blogs = [
    {
      id: "1",
      title: "Cultivating Empathy and Responsibility",
      slug: "1",
      subtitle:
        "We believe that education goes beyond the classroom. When we registered at Sevak Army, we found the perfect way to introduce our students to the importance of community service. Our students volunteered with NGOs that needed help with various tasks, including organising charity events, assisting in local environmental projects and many more.The impact on the students was remarkable. They learned valuable life lessons in empathy, teamwork, and responsibility while earning digital certificates that acknowledged their efforts. Watching them grow through these experiences made us proud, and we know this involvement with Sevak Army will shape them into compassionate and socially responsible individuals.",
      bsing: blog1,
      athor: "Principal",
    },
    {
      id: "2",
      title: "Learning Through Service",
      slug: "2",
      subtitle:
        "We always encourage students to engage in extracurricular activities, but registering with Sevak Army took it to the next level. Our students participated in a range of volunteering tasks, from teaching underprivileged children to organising fundraisers for local NGOs. Sevak Army connected us with NGOs needing support, allowing students to dedicate their time and energy to meaningful causes.Our students gained valuable leadership and teamwork skills through these experiences, and many reflected on how much they learned about themselves and the importance of community service. Sevak Army provides a platform that not only benefits society but also nurtures personal growth in our students.",
      bsing: blog2,
      athor: "Coordinator",
    },
    {
      id: "4",
      title: "Building Future Leaders Through Social Impact",
      slug: "4",
      subtitle:
        "We strive to create future leaders who excel academically and value social responsibility. Registering with Sevak Army allowed our students to participate in impactful projects, like health camps and awareness campaigns with local NGOs. These experiences helped them develop organizational and communication skills while giving back to the community. The certificates they earned recognized their contributions, helping them grow as socially conscious individuals ready to make a difference.",
      bsing: blog3,
      athor: "Dean",
    },
    {
      id: "5",
      title: "Encouraging Volunteerism for Personal Growth",
      slug: "5",
      subtitle:
        "We have always encouraged our students to be active members of society, and Sevak Army helped us make this vision a reality. Our students volunteered with various NGOs, supporting causes like educational programmes for children and community welfare projects. Many students shared that volunteering with Sevak Army increased their empathy and awareness of social issues. The certificates they earned were a valuable recognition of their dedication, and we’re proud to have fostered a strong spirit of volunteerism at our college.",
      bsing: blog3,
      athor: "Faculty Advisor",
    },
    {
      id: "3",
      title: "Instilling the Value of Seva in Young Minds",
      slug: "3",
      subtitle:
        "When we registered with Sevak Army, we aimed to go beyond academics and instil in our students the value of ‘seva’ or selfless service. Through the platform, our students engaged in meaningful volunteer tasks, such as food distribution drives and environmental clean-up activities in collaboration with local NGOs. The experience was impactful, as students developed a sense of gratitude, learned the importance of humility, and felt empowered by their ability to contribute to society. The certificates they received recognised their efforts, and the positive impact on their character was evident. Sevak Army has strengthened our school’s commitment to holistic education.",
      bsing: blog3,
      athor: "Vice Principal",
    },
  ];

  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const settingst = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const featuresData = [
    {
      iconClass: "flaticon-volunteer",
      img: Img1,
      title: "Service Opportunities",
      description:
        "Providing students a platform to actively engage and serve our communities.",
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Student Engagement",
      img: Img2,
      description:
        "Inspire students to join meaningful community service projects through Sevak Army.",
    },
    {
      iconClass: "flaticon-charity",
      img: Img3,
      title: "Real Impact",
      description:
        "Schools and colleges can drive social change by involving students in community causes.",
    },
  ];

  // Below section not in requirement
  // section start

  // const featuresData1 = [
  //   {
  //     iconClass: "flaticon-volunteer",
  //     img: aImg1,
  //     title: "Real-Time Alerts",
  //     description:
  //       "Stay updated with notifications about upcoming events, community drives and volunteer opportunities near you.",
  //   },
  //   {
  //     iconClass: "flaticon-solidarity",
  //     img: aImg2,
  //     title: "Diverse Opportunities",
  //     description:
  //       "Whether it’s a blood donation drive, or tree plantation event, Sevak Army ensures you're always informed.",
  //   },
  //   {
  //     iconClass: "flaticon-charity",
  //     img: aImg3,
  //     title: "Never Miss An Opportunity",
  //     description:
  //       "Timely alerts help you stay connected and contribute to meaningful causes and projects.",
  //   },
  //   {
  //     iconClass: "flaticon-chatting",
  //     img: aImg4,
  //     title: "Tangible Impact",
  //     description:
  //       "Volunteers can make a significant difference in society while gaining valuable experiences through active participation.",
  //   },
  //   {
  //     iconClass: "flaticon-crib-toy",
  //     img: aImg5,
  //     title: "Support For Schools",
  //     description:
  //       "Educational institutions can rely on these alerts to attract dedicated volunteers, ensuring smooth and successful event execution.",
  //   },
  // ];

  // section end

  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      {/* Hero3 */}
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content">
                <div className="slide-title-sub titleeee">
                  <h6>
                    Inspiring Students to Serve Through Volunteer Opportunities
                  </h6>
                </div>
                <div className="slide-title titleeee2">
                  <h2>A Platform to Volunteer and Serve</h2>
                </div>
                <div className="slide-text slideee-texttt">
                  <p>
                    Give your students a chance to contribute to society by
                    registering your institution and encouraging your students
                    to join as volunteers on our platform promoting a culture of
                    service in young minds.
                  </p>
                </div>
                <div className="hero-btn">
                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn"
                  >
                    Register Your Institution
                  </Link>
                  <div className="wpo-supporter-img">
                    <ul className="wpo-supporter-slide">
                      <Slider {...settings}>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Aliza Anny"
                        >
                          <img src={team1} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="David Miller"
                        >
                          <img src={team2} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Maria Silva"
                        >
                          <img src={team3} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Takila Mas"
                        >
                          <img src={team4} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Jenny Lawba"
                        >
                          <img src={team5} alt="" />
                        </li>
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          parallax={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1800,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide1})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide2})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide3})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide4})` }}
            ></div>
          </SwiperSlide>
          ...
        </Swiper>
      </section>

      {/* <FeatureSection /> */}
      <section className="wpo-features-area">
        <div className="container">
          <div className="features-wrap">
            <div className="row justify-content-center">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div
                      className="feature-item"
                      style={{ alignItems: "start" }}
                    >
                      <div className="feature-icon">
                        <div className="icon">
                          <img src={feature.img} className="h-[40px]" />
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <About /> */}

      <section className="wpo-about-section section-padding">
        {/* Below section not in requirement */}
        {/* section start */}
        {/* <section className="pt-4">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row ">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    // <div className="wpo-about-left">
                //    <img src={abImg2} alt="img" />
              //    </div> 
                    <div className="wpo-about-right pt-0">
                      <img src={abImg1} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <span>Who Are We?</span>
                      <h6 className="fw-bold display-5">
                        Empowering Education: Connecting Schools, Colleges And
                        Volunteers For A Brighter Future
                      </h6>
                    </div>
                    <p>
                      Sevak Army is an engaging platform created to simplify
                      collaboration between schools, colleges and volunteers. It
                      offers an easy and efficient way for schools and colleges
                      to post tasks, find the right volunteers and track
                      progress.
                    </p>

                    <div className="about-info-wrap flex-column pb-0">
                      <div className="about-info-item align-items-start pb-4">
                        <div className="about-info-icon">
                          <div className="icon">
                          <img src={Vision} />
                          </div>
                        </div>
                        <div className="about-info-text ">
                          <h4>Our Mission</h4>
                          <p className="mission-paragraph volunteer-home-text ">
                            At Sevak Army, our mission is to create a
                            sustainable platform that bridges the gap between
                            schools, colleges, volunteers and non-governmental
                            organizations.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="about-info-item align-items-start">
                        <div className="about-info-icon">
                          <div className="icon">
                            <i className="fi flaticon-mountain"></i>
                          </div>
                        </div>
                        <div className="about-info-text">
                          <h4>Our Vision</h4>
                          <p className="vision-paragraph volunteer-home-text">
                            Our vision is to create a world where volunteering
                            is accessible and rewarding for everyone, regardless
                            of background, location or skill set.
                          </p>
                        </div>
                      </div>
                    </div>

                    //<Link onClick={ClickHandler} to="/about" className="theme-btn">
                //    Discover More
                 // </Link> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* section end */}

        <section className="section-padding pb-0">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="flex-containerrr justify-content-center">
                <div className="">
                  <div className="wpo-about-text mt-0">
                    <div className="wpo-section-title mt-0">
                      {/* <span>Welcome to Sevak Army!</span> */}
                      <h2 className="about-heading-third text-titleeee">
                        {" "}
                        Join Sevak Army{" "}
                      </h2>
                    </div>
                    <p>
                      <b>Be the Change:</b> Encourage students to make a
                      difference through volunteer opportunities with Sevak
                      Army.{" "}
                    </p>
                    <p>
                      <b>Guide Students:</b> Join Sevak Army today to guide your
                      students towards meaningful volunteer opportunities and
                      real-world experiences.{" "}
                    </p>
                    <p>
                      <b>Leadership Path:</b> Help students take the lead in
                      serving our communities through Sevak Army's volunteer
                      platform.
                    </p>
                    {/* <h4 className=".services-title-text">It’s simple, it’s effective and it starts with a click.</h4> */}

                    {/* <Link onClick={ClickHandler} to="/school-college/events" className="theme-btn">
                    Know More
                  </Link> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left-side">
                    <img src={abImg3} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} height={400} width={480} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* <ServiceSection hclass={"wpo-service-section"} /> */}
      <section className={"section-padding " + "wpo-service-section pt-0"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                // subtitle="How Does It Work?"
                title="Volunteer, Grow, and Shine"
              />
            </div>
          </div>
          <div className="service-wrap mt-3">
            <div className="row justify-content-center">
              {Services.slice(0, 4).map((Service, sitem) => (
                <div
                  className="col col-xl-4 col-lg-5 col-md-7 col-sm-8 col-12 "
                  key={sitem}
                >
                  <ServiceCard
                    height="450px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <FunFact2 hclass={"wpo-fun-fact-section"} />

      {/* <ServiceSection hclass={"wpo-service-section"} /> */}

      <section className={"pt-0" + "wpo-service-section"}>
        <div className="container section-padding pb-0">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                subtitle="Why Join Sevak Army?"
                title="Create a Spirit of Service in Students"
              />
            </div>
          </div>
          <div className="service-wrap">
            <div className="row justify-content-center">
              {Services1.slice(0, 5).map((Service, sitem) => (
                <div
                  className="col col-xl-3 col-lg-4 col-md-6 col-sm-8 col-12"
                  key={sitem}
                >
                  <ServiceCard
                    height="450px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Features2 */}

      {/* Below section not in requirement */}
      {/* section start */}
      {/* 
      <section className="wpo-features-area">
        <div className="container">
          <div className="features-wrap">
            <div className="row justify-content-center">
              {featuresData1.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12 mb-5" key={index}>
                  <div className="feature-item-wrap">
                    <div
                      className="feature-item"
                      style={{ alignItems: "start" }}
                    >
                      <div className="feature-icon">
                        <div className="icon">
                          <img src={feature.img} className="h-[40px]" />
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      {/* section end */}

      <section className="">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Testimonials" title="Voices of Impact" />
            </div>
          </div>
          <div className="blog-wrap">
            <div className="row align-items-stretch justify-content-center">
              {blogs.map((blog, bl) => (
                <div className="col col-lg-6 col-md-6 col-12" key={bl}>
                  <div
                    className="blog-single-card d-flex flex-wrap"
                    style={{ position: "relative" }}
                  >
                    <div className="content p-3">
                      <h2 className="blog-title-home mt-2">
                        <a
                          className="blog-title-home mt-2 w-100 text-center d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          {blog.title}
                        </a>
                      </h2>
                      <p>{blog.subtitle}</p>
                      <ul
                        className="top-content"
                        style={{ position: "", bottom: "15px" }}
                      >
                        <li className="mb-3 fs-5">
                          {/* <div className="image-single">
                          <img src={blog.bsing} alt="" />
                        </div> */}
                          - {blog.athor}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <p className="fs-3 mt-0">
              <b>Join the platform</b>: Register your Institution with the Sevak
              Army!
            </p>
          </div>
        </div>
      </section>

      <section className="wpo-instagram-section s2 section-padding pb-0">
        <div className="container">
          <div className="instagram-wrap">
            <div className="wpo-section-title mb-5">
              <span>Gallery</span>

              <SectionTitle
                subtitle=""
                title="Our Gallery"
                paragarap="Explore our gallery to witness the inspiring efforts of the
                students as volunteers of Sevak Army."
              />

              {/* <h2 className="about-heading-third">Our Gallery</h2>
              <p className="text-center">
                Explore our gallery to witness the inspiring efforts of the
                students as volunteers of Sevak Army.
              </p> */}
            </div>
            <div className="row">
              {Instagram.map((item) => (
                <div className="col col-lg-4 col-md-6 col-12" key={item.id}>
                  <div className="instagram-card">
                    <div className="image">
                      <Link
                        to={item.imag}
                        className="fancybox"
                        data-fancybox-group="gall-1"
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(item.imag);
                        }}
                      >
                        <img
                          src={item.imag}
                          alt={`Instagram ${item.id}`}
                          className="img img-responsive"
                        />
                        <div className="popup-icon">
                          <i className="ti-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="text">
                      <span>{item.title}</span>
                      <h2>{item.subtitle}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {modalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-wrap">
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage} alt="Modal" />
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Stay Informed, Stay Inspired!</span>
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Sign up for our newsletter to receive updates on impactful
                      volunteer opportunities for students, and tips on creating
                      positive change. Empower your students to get involved and
                      make a difference with Sevak Army.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <button
                        onClick={() => setShowNewsletter(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Subscribe Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <BlogSection hclass={"wpo-blog-section-s2 section-padding"} /> */}
      {/* <CtaSection /> */}
      <Footer />
      <Scrollbar />
      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
    </>
  );
};

export default SchoolCollegeHome;
