import React from "react";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../images/logo.svg";
import TermsAndConditionsContent from "./content";

const TermsAndConditions = () => {
  return (
    <>
      <Navbar hclass={"wpo-site-header pb-5"} Logo={Logo} />
      <TermsAndConditionsContent />
      <Scrollbar />
      <Footer />
    </>
  );
};

export default TermsAndConditions;
