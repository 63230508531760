import React, { useState } from "react";
import { Link } from "react-router-dom";
// import abImg2 from "../../images1/volunteer/home/sevak-army-volunteer-home-3.png";
import abImg2 from "../../images1/volunteer/home/helpingman.png";
import Inspire from "../../images1/volunteer/home/inspire.png";
import SectionTitle from "../SectionTitle/SectionTitle";
import Volunteerhomecards from "../Volunteer/Volunteerhomecards";
import volunteerhomeEvents from "../Volunteer/Volunteerhomeevents";
import Volunteerhometestmonial from "../Volunteer/Volunteerhometestmonial";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Logo from "../../images/logo.svg";
import Scrollbar from "../scrollbar/scrollbar";
import icon from "../../images/cta/top-icon.png";

import Image1 from "../../images1/ngo/home/sevak-army-ngo-home-1.jpg";
import Videobg from "../../images1/ngo/home/sevak-army-ngo-home-2.jpg";

import Imgs1 from "../../images/volunteer/add-user.png";
import Imgs2 from "../../images/volunteer/data-discovery.png";
import Imgs3 from "../../images/volunteer/apply.png";
import Imgs4 from "../../images/volunteer/search.png";
import Imgs5 from "../../images/volunteer/chat.png";

import ShapeBg from "../../images/slider/stick-man.svg";
import SupportersSlider from "../hero/SupportersSlider";
import ServiceCard from "../ServiceSection/ServiceCard";
import FunFact from "../FunFact/FunFact";
import NGO from "../../images1/volunteer/home/ngo.png";
import Opportunities from "../../images1/volunteer/home/Opportunities.png";
import Progress from "../../images1/volunteer/home/progress.png";
import Impact from "../../images1/volunteer/home/impact.png";
import Newsletter from "../../images1/volunteer/home/newsletter.png";
import ShareInspire from "../../images1/volunteer/home/share-inspire.png";
import NewsletterPopup from "../NewsletterPopup";
import Vision from "../../images/service/arrow.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const featuresData = [
  {
    // iconClass: "flaticon-volunteer",
    svg: NGO,
    title: "Work with Trusted NGOs",
    description:
      "Apply for opportunities with verified NGOs to make a real difference.",
  },
  {
    // iconClass: "flaticon-charity",
    svg: Opportunities,
    title: "Find the Right Opportunities",
    description: "Choose tasks that fit your skills, interests, and schedule.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: Progress,
    title: "Track Your Progress",
    description:
      "Log your hours, earn e-certificates, and move up the leaderboard.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Be Part of a Caring Community",
    description: "Join a group of volunteers dedicated to helping others.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: Impact,
    title: "Make a Real Impact",
    description:
      "Every task you complete contributes to meaningful social work and drives positive change.",
  },
];

const howWorksData = [
  {
    iconClass: "flaticon-volunteer",
    img: Imgs1,
    title: "Sign Up",
    description: "Register on Sevak Army and create your volunteer profile.",
  },
  {
    iconClass: "flaticon-charity",
    title: "Browse & Filter Tasks",
    img: Imgs2,
    description:
      "Search and filter volunteer opportunities based on your skills and interests.",
  },
  {
    iconClass: "flaticon-solidarity",
    img: Imgs3,
    title: "Apply for Opportunities",
    description:
      "If an opportunity suits your profile, apply and fill out the eligibility form required by the NGO.",
  },
  {
    iconClass: "flaticon-solidarity",
    img: Imgs4,
    title: "NGO Review",
    description:
      "The NGO will review your application and accept it if you meet the criteria for the role.",
  },
  {
    iconClass: "flaticon-solidarity",
    img: Imgs5,
    title: "Connect & Chat",
    description:
      "Once accepted, the chat portal will activate for direct communication with the NGO to coordinate your task.",
  },
];

const Volunteerhome = (props) => {
  const [shownewsletter, setShowNewsletter] = useState(false);
  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <section className="static-hero">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content">
                <div className="slide-title-sub">
                  <span>Discover the Best Way to Serve</span>
                </div>
                <div className="slide-title">
                  <h2 className="slide-heading">
                    Find Your Perfect Role in Volunteering with Sevak Army
                  </h2>
                </div>
                <div className="slide-text">
                  <p style={{ textAlign: "justify" }}>
                    Sevak Army makes it easy for you to find the right volunteer
                    opportunity. We connect passionate individuals with verified
                    NGOs to match your skills, interests, and availability.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div data-swiper-parallax="500" className="slide-video-content">
                  <div className="slide-video-img">
                    <img src={Videobg} alt="img" />
                    {/* <VideoModal /> */}
                  </div>
                  <div className="slide-video-text">
                    <h4 className="slide-video-text-show">
                      Join Us Today and Start Making a Real Difference!
                    </h4>
                    <p className="slide-video-para">
                      Explore volunteering opportunities in various fields like
                      education, healthcare, community outreach, or
                      environmental work, and make your contribution count!
                    </p>
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    onClick={() => props.setShow(true)}
                    className="theme-btn"
                  >
                    Join as a Volunteer Today
                  </button>
                </div>
                <div className="charius-pic image-positionnnn">
                  <div className="charius-pic-main">
                    <img src={Image1} width={700} alt="img" />
                  </div>
                  {/* <div className="charius-inner">
                        <img src={Image2} alt="img" />
                      </div> */}

                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      <div className="icon">
                        <i>
                          <img src={ShapeBg} alt="img" />
                        </i>
                      </div>
                      <div className="content">
                        <h3>500+</h3>
                        <p>Volunteers Partnered</p>
                      </div>
                    </div>
                    <SupportersSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding">
        <div className="bg-overlay">
          <div className="">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Join Sevak Army</span>
                    <h2>Register Now and Start Volunteering</h2>
                    <p
                      className="px-2 text-center"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      We are on a mission to achieve 2 million volunteer hours
                      by 2025. Your time and dedication can help us get there.
                      Sign up today, find the right task, and be part of
                      something extraordinary.
                    </p>
                    <Link
                      onClick={() => props.setShow(true)}
                      to="#"
                      className="theme-btn"
                    >
                      Join as a Volunteer Today
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section section-padding pt-0 pb-0">
        <div className="container">
          {/* <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 text-center">
                <SectionTitle
                  subtitle="Inspiring Leaders, Driving Change."
                  title="Empowering changemakers with impactful action!"
                  paragarap="Sevak Army is a community-driven portal. It connects volunteers to meaningful tasks. These tasks span various causes."
                />
              </div>
            </div> */}

          {/* <section className="section-padding">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 text-center">
                <SectionTitle
                  title="Empower action, inspire change."
                  paragarap="Sevak Army connects you to projects where your skills make a real difference, creating change."
                />
                <Link onClick={ClickHandler} to="/volunteer/events" className="theme-btn">
                  View Available Tasks
                </Link>
              </div>
            </div>
          </section> */}

          <section className="pt-100">
            {/* <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle subtitle="Serve with Impact" />
              </div>
            </div> */}
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle subtitle="Serve with Impact" title="Step Into Purpose with Sevak Army" paragarap="Apply for opportunities with verified NGOs to make a real
                  difference." />
              </div>
            </div>

            <div className="wpo-features-area pt-3">
              <div className="features-wrap">
                {/* <p
                  className="pb-2 w-100"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Apply for opportunities with verified NGOs to make a real
                  difference.
                </p> */}
                <div className="row justify-content-center column-gappp">
                  {featuresData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div
                          className="feature-item d-flex align-items-center"
                          style={{ height: "162px" }}
                        >
                          <div className="feature-icon">
                            <div className="icon">
                              {feature.iconClass ? (
                                <i className={`fi ${feature.iconClass}`}></i>
                              ) : (
                                <img src={feature.svg} alt={feature.svg} />
                              )}
                            </div>
                          </div>
                          <div className="feature-text">
                            <h2 className="fs-5">{feature.title}</h2>
                            <p
                              className="fs-6"
                              style={{ textAlign: "justify" }}
                            >
                              {feature.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="section-padding">
            <div className="wpo-about-wrap mt-5">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                      <img src={abImg2} alt="img" />
                    </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg2} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <span>Who Are We?</span>
                      <h2 className="about-heading-second text-titleeee">
                        Empowering People to Serve with Purpose
                      </h2>
                    </div>
                    <p className="volunteer-home-text">
                      Sevak Army connects people of all ages, including
                      students, professionals, and retirees, with verified NGOs
                      to make a lasting social impact. Everyone has something to
                      offer, whether a few hours or a long-term commitment.
                      Instead of simply volunteering, why not choose
                      opportunities that match your interests, skills, and
                      schedule! This way, your efforts will help the community
                      and bring you personal satisfaction. <br />
                      We welcome volunteers from all walks of life - corporate
                      employees looking for meaningful CSR activities, retirees
                      wanting to give back, students eager to learn the value of
                      service, and anyone who wishes to contribute to society.
                    </p>
                  </div>
                </div>
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <div className="about-info-wrap col-10 justify-content-center align-items-center gap-5">
                    <div
                      className="about-info-item "
                      style={{ alignItems: "self-start" }}
                    >
                      <div className="about-info-icon">
                        <div className="icon me-3" style={{marginTop:'8px'}}>
                          <img src={Vision} />
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Mission</h4>
                        <p className="mission-paragraph volunteer-home-text">
                          To create a platform where individuals can connect
                          with trusted NGOs, engage in meaningful service, and
                          contribute to society through tasks that match their
                          skills, interests, and time.
                        </p>
                      </div>
                    </div>
                    <div
                      className="about-info-item "
                      style={{ alignItems: "self-start" }}
                    >
                      <div className="about-info-icon">
                        <div className="icon me-3" style={{marginTop:'8px'}}>
                          <i className="fi flaticon-mountain"></i>
                        </div>
                      </div>
                      <div className="about-info-text ">
                        <h4>Our Vision</h4>
                        <p className="vision-paragraph volunteer-home-text">
                          By 2025, we aim to achieve 2 million hours of
                          volunteer work across India, building active,
                          compassionate communities that grow through social
                          responsibility.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <Link
                      onClick={() => props.setShow(true)}
                      to="#"
                      className="theme-btn"
                    >
                      Register Now
                    </Link>
                  </div>
                </div>

                {/* Comment */}
                {/* <div className="row justify-content-center section-padding">
                  <div className="col-lg-12 col-md-12 col-12 text-center p-2">
                    <SectionTitle
                      subtitle="Sevak Army’s Journey"
                      title="Together, We Make Every Effort Count!"
                      paragarap="Volunteers constantly seek new ways to engage with causes. Sevak Army offers diverse tasks for different skills. Opportunities include teaching, marketing and event organising. We also support food distribution efforts. There's something for everyone."
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <Volunteerhomecards />
          {/* <section className="section-padding">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 text-center">
                <SectionTitle
                  title="Flexible memberships for every volunteer!"
                  paragarap="Sevak Army offers flexible memberships, tailored to fit your schedule and commitment level. "
                />
                <Link onClick={()=>()=>setShow(true)} to="#" className="theme-btn">
                  Become a Member
                </Link>
              </div>
            </div>
          </section> */}

          <section className="wpo-cta-section-s3 section-padding">
            <div className="container">
              <div className="bg-color" style={{ background: "#007560" }}>
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="cta-wrap">
                      <span>Lend a Hand, Change a Life</span>
                      <h2>Join Sevak Army and Make Your Contribution Count.</h2>
                      <Link
                        onClick={() => props.setShow(true)}
                        to="#"
                        className="theme-btn"
                      >
                        Sign up to volunteer today!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="service-wrap mt-5">
            <SectionTitle
              title="Why Volunteer with Sevak Army?"
              paragarap="Join Sevak Army to make a difference while building valuable skills and connections."
            />
            {/* <div className="row mt-5">
              {volunteerhomeEvents.slice(0, 3).map((event, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="event-card">
                    <div className="top-date">
                      <span>{event.date}</span> {event.month}
                      <div className="shape">
                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none">
                          <path d="M18.9999 14L0.5 14L18.9999 -3.6902e-05L18.9999 14Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="image">
                      <img src={event.pimg} alt="" />
                    </div>
                    <div className="text">
                      <h3 className="text-center fw-bold text-white">{event.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div className="row justify-content-center mt-5">
              {volunteerhomeEvents.slice(0, 10).map((Service, sitem) => (
                <div
                  className="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={sitem}
                >
                  <div className="service-card-wrapper d-flex flex-column h-100">
                    <ServiceCard
                      height="380px"
                      title={Service.title}
                      img={Service.pimg}
                      slug={Service.slug}
                      sdescription={Service.description}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <section className="pt-100">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle title="How It Works?" />
              </div>
            </div>

            <div className="wpo-features-area pt-3">
              <div className="features-wrap">
                {/* <p className="pb-2" style={{ width: '100%', textAlign: 'center' }}>Apply for opportunities with verified NGOs to make a real difference.</p> */}
                <div className="row justify-content-center column-gappp">
                  {howWorksData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div
                          className="feature-item d-flex align-items-center"
                          style={{ height: "162px" }}
                        >
                          <div className="d-flex align-items-start">
                            <div className="feature-icon">
                              <div className="icon">
                                <img src={feature.img} />
                              </div>
                            </div>
                            <div className="feature-text">
                              <h2 className="fs-5">{feature.title}</h2>
                              <p
                                className="fs-6"
                                style={{ textAlign: "justify" }}
                              >
                                {feature.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-100 d-flex justify-content-center mt-5">
                  <Link onClick={ClickHandler} to="become-volunteer" className="theme-btn">
                    Know more
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="section-padding ">
            <Volunteerhomestories />
          </section> */}
          <section className="section-padding">
            <Volunteerhometestmonial />
          </section>

          <section className="">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle title="Top Volunteers Leading the Way" paragarap=" Celebrate the dedication of our top volunteers who are making a
              real difference through their service. These individuals have
              contributed the most hours and earned top spots on the
              leaderboard."/>
              </div>
            </div>
            {/* <p
              className="pb-2 text-center"
              style={{ width: "100%", textAlign: "center" }}
            >
              Celebrate the dedication of our top volunteers who are making a
              real difference through their service. These individuals have
              contributed the most hours and earned top spots on the
              leaderboard.
            </p> */}
            <div className="mt-3">
              <FunFact hclass="wpo-fun-fact-section-s3" />
            </div>
          </section>

          <div className="container section-padding pt-5">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={Inspire} height={530} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text p-3 mt-0">
                    <div className="wpo-section-title mt-0">
                      <span>Become a Sevak Army Ambassador</span>
                      <h2 className="about-heading-second text-titleeee">
                        Lead the Change, Inspire Others
                      </h2>
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      Take your volunteer journey to the next level by becoming
                      a Sevak Army Ambassador. Share your passion, promote
                      volunteering opportunities, and help expand our community
                      of change-makers. As an ambassador, you will be a leader,
                      inspiring others to join and make a real difference.
                    </p>

                    <h3>Ready to Lead?</h3>
                    <p>
                      Become a Sevak Army Ambassador today and inspire others to
                      serve!
                    </p>
                    <div>
                      <button
                        className="theme-btn"
                        onClick={() => props.setShow(true)}
                      >
                        Apply Now to Be an Ambassador
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container section-padding pt-5">
            <div className="wpo-about-wrap">
              <div className="row ">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text p-3 mt-0">
                    <div className="wpo-section-title mt-0">
                      <span>Stay Connected with Sevak Army</span>
                      <h2 className="about-heading-second text-titleeee w-100">
                        Join Our Volunteer Community Newsletter{" "}
                      </h2>
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      Be part of our growing community! Receive inspiring
                      stories, volunteer highlights, updates on social impact,
                      and tips on making the most of your service. Stay informed
                      about the amazing work being done and how you can stay
                      engaged with Sevak Army.
                    </p>
                    <h3>Keep the Spirit Alive!</h3>
                    <p>
                      Subscribe now to stay updated with all the latest news and
                      inspiration from Sevak Army.
                    </p>
                    <div>
                      <button
                        className="theme-btn"
                        onClick={() => setShowNewsletter(true)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Subscribe to Our Newsletter
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-5">
                      <img src={Newsletter} width={440} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container section-padding pt-0">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-5">
                      <img src={ShareInspire} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text p-3 mt-0">
                    <div className="wpo-section-title mt-0">
                      <span className="about-heading-second">
                        Make a Bigger Impact through Social Media and Referrals
                      </span>
                      <h2 className="about-heading-second text-titleeee">
                        Share, Inspire, and Earn Points with Sevak Army{" "}
                      </h2>
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      Share your volunteering experiences on social media using
                      #SevakArmy to inspire others and grow our community of
                      volunteers. By sharing your journey, you can motivate
                      friends and followers to join in making a difference.{" "}
                      <br />
                      Earn extra points by inviting others! Use your unique
                      referral link, and for every person who registers and
                      volunteers through your link, you will move up the
                      leaderboard. The more you share, the more you contribute,
                      and the more you earn! Start Sharing & Invite Others to
                      Earn Points Today!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Volunteer with Flexibility</span>
                    {/* <h2>Register Now and Start Volunteering</h2> */}
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Sevak Army understands that everyone’s time is unique.
                      Whether you're a student, a working professional, or a
                      retiree, we offer tasks that fit into your schedule—from
                      one-time events to ongoing projects. You decide how you
                      want to contribute!
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <span>Register Now and Start Volunteering</span>
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Join as a Volunteer Today
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
    </>
  );
};

export default Volunteerhome;
