import React, { Fragment } from "react";
import Logo from "../../images/logo.svg";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import BecomeInfluencerPage from "./BecomeInfluencerPage";

function BecomeInfluencer({show, setShow}) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"Join Sevak Army"} pagesub={"Influencer"} />
            <BecomeInfluencerPage show={show} setShow={setShow} />
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default BecomeInfluencer;
