import React, { Fragment } from "react";
import Logo from "../../images/logo.svg";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import OrganizationRecognitionPage from "./OrganizationRecognitionPage";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";

function OrganizationRecognition({ show, setShow }) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"Recognition Event"} pagesub={"School/College"} />
            <OrganizationRecognitionPage show={show} setShow={setShow} />
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default OrganizationRecognition;
