import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cta1 from "../../images/cta/shape-1.png";
import Cta2 from "../../images/cta/top-icon.png";
import Cta3 from "../../images/cta/shape-2.png";
import AuthModal from "../../auth-modal";
const NgoCtaSection2 = () => {
    // const ClickHandler = () => {
    //   window.scrollTo(10, 0);
    // };
    const [show, setShow] = useState(false);
    return (
        <>
            <section className="wpo-cta-section" style={{marginTop:'100px'}}>
                <div className="shape-1">
                    <img src={Cta1} alt="" />
                </div>
                <div className="bg-overlay">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="cta-wrap">
                                    {/* <div className="icon">
                                        <img src={Cta2} alt="" />
                                    </div> */}
                                    <span className="">Unlock Potential, Achieve Greater Change</span>
                                    <h2 className="mb-2 mt-2">Expand Your Reach, Empower Your Mission</h2>
                                    <p style={{ color: 'whitesmoke' }}>Join Sevak Army to connect with skilled volunteers who are passionate about supporting your cause. Register your NGO, post tasks, and find the right people to help you make a greater impact. Together, we can achieve more and drive real change in communities across India.</p>
                                    <Link to="#" className="theme-btn-cta" onClick={() => setShow(true)}>
                                        Register Your NGO Today
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-2">
                    <img src={Cta3} alt="" />
                </div>
            </section>
            <AuthModal show={show} setShow={setShow} />
        </>
    );
};

export default NgoCtaSection2;
