import React from 'react'
import ServiceCard from "../ServiceSection/ServiceCard";
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import sSimg3 from '../../images/service-single/img3.jpg'
import sSimg4 from '../../images/service-single/img4.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';
import ConnectVolunteers from '../../images1/volunteer/home/Connect-Volunteers-NGOs.png'
import VolunteerTasks from '../../images1/volunteer/home/volunteer-tasks.png'
import AgeGroup from '../../images1/volunteer/home/age-group.png'
import CustomerService from '../../images1/volunteer/home/customer-service.png'

function Volunteerhomecards() {
    const Services = [
        {
            Id: '01',
            sImg: ConnectVolunteers,
            sSImg: sSimg1,
            title: 'Connect Volunteers & NGOs',
            slug: '1',
            description: 'Sevak Army connects people with NGOs for impactful volunteering.',
        },
        {
            Id: '02',
            sImg: VolunteerTasks,
            sSImg: sSimg2,
            title: 'Offer Diverse Volunteer Tasks',
            slug: '2',
            description: 'Sevak Army lets you choose tasks that match your skills and interests.',
        },
        {
            Id: '03',
            sImg: AgeGroup,
            sSImg: sSimg3,
            title: 'For All Age Groups',
            slug: '3',
            description: 'People of all ages can join and contribute.'

        },
        {
            Id: '04',
            sImg: CustomerService,
            sSImg: sSimg4,
            title: 'Enable Meaningful Service',
            slug: '4',
            description: 'We help you serve in ways that fit your time and make a difference.',
        },

    ]

    return (
        <div className="container mt-2">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-12 col-12">
                    <SectionTitle subtitle="What We Do?" title="Connecting Passionate Volunteers with Social Work Opportunities" />
                </div>
            </div>
            <div className="service-wrap">
                <div className="row pt-3">
                    {Services.slice(0, 4).map((Service, sitem) => (
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                            <div className="service-card-wrapper d-flex flex-column h-100">
                                <ServiceCard width="301px" height="335px" title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default Volunteerhomecards