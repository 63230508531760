import React, { Fragment } from "react";
import StrongCommunity from "../../images1/volunteer/about-us/community.png";
import About2 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-2.jpg";
import About3 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-3.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";
import ServiceCard from "../ServiceSection/ServiceCard";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import TeamWork from "../../images1/volunteer/home/teamwork.png";
import Trust from "../../images1/volunteer/home/trust.png";
import CreateImpact from "../../images1/volunteer/home/create-impact.png";
import Welcome from "../../images1/volunteer/home/welcome-back.png";

function VolunteerAboutUsPage() {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };
  const featuresData = [
    {
      iconClass: "flaticon-volunteer",
      title: "Our Mission",
      img: About2,
      description:
        "To connect people with meaningful volunteer opportunities, helping them contribute their skills to causes they care about. We want to make volunteering easy so everyone can take part and feel the joy of giving back.",
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Our Vision",
      img: About3,
      description:
        "To build a stronger, more connected and vibrant society by reaching over 2 million hours of volunteer work across India by 2025. We firmly believe that we can help transform lives and communities for the better through meaningful volunteerism.",
    },
  ];

  const Services = [
    {
      Id: "01",
      sImg: TeamWork,
      sSImg: sSimg1,
      title: "Working Together",
      slug: "1",
      description:
        "We believe change happens when people and communities work together.",
    },
    {
      Id: "02",
      sImg: Trust,
      sSImg: sSimg2,
      title: "Trust and Honesty",
      slug: "2",
      description:
        "We ensure that all the NGOs we work with are verified so you know your time is getting invested in the right place.",
    },
    {
      Id: "03",
      sImg: CreateImpact,
      sSImg: sSimg3,
      title: "Creating Impact",
      slug: "3",
      description:
        "Every small action counts and has the potential to create real change.",
    },
    {
      Id: "04",
      sImg: Welcome,
      sSImg: sSimg4,
      title: "Everyone is Welcome",
      slug: "4",
      description:
        "We offer volunteering opportunities for people of all ages and backgrounds, making sure everyone can serve in a way that suits them.",
    },
  ];

  return (
    <Fragment>
      <section className="wpo-about-section mb-0">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-between pt-100">
              <div className="wpoimg">
                <div className="wpo-about-img">
                  <div className="bg-image">
                    <img src={StrongCommunity} alt="" />
                  </div>
                </div>
              </div>
              <div className="wpodesc">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="about-heading-second text-titleeee">
                      Making Communities Stronger, Together
                    </h2>
                  </div>
                  <p className="volunteer-home-text">
                    At Sevak Army, we bring people together to help create
                    positive change in communities across India. Our platform
                    connects volunteers like you with trusted NGOs, offering a
                    simple way to give back to society using your time and
                    skills. <br />
                    Whether you're a student, working professional, or retiree,
                    Sevak Army makes it easy for everyone to find volunteer
                    tasks that fit their interests and schedule. From teaching
                    and organising events to supporting causes like education,
                    healthcare, and the environment, there’s a way for everyone
                    to make a difference.
                  </p>
                </div>
              </div>
            </div>

            <section className="wpo-features-area pt-5 mt-5 section-padding">
              <div className="features-wrap">
                <div className="row justify-content-center">
                  {featuresData.map((feature, index) => (
                    <div className="col col-lg-5 col-md-6 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div className="feature-item">
                          <div className="feature-text">
                            <div className="mb-4 d-flex justify-content-center align-items-center">
                              <img
                                src={feature.img}
                                alt=""
                                className="img-fluid"
                                style={{
                                  height: "300px",
                                  width: "100%",
                                  objectFit: "cover",
                                  maxHeight: "320px",
                                }}
                              />
                            </div>
                            <h2 className="ps-3 fw-bold">{feature.title}</h2>
                            <p className="p-2">
                              {<p className="p-2">{feature.description}</p>}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <section className="pb-5">
              <div className="container mt-2">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-12 col-12">
                    <SectionTitle subtitle="" title="Our Values" />
                  </div>
                </div>
                <div className="service-wrap">
                  <div className="row pt-3 justify-content-center">
                    {Services.slice(0, 4).map((Service, sitem) => (
                      <div
                        className="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                        key={sitem}
                      >
                        <div className="service-card-wrapper d-flex flex-column h-100">
                          <ServiceCard
                            width="301px"
                            height="383px"
                            title={Service.title}
                            img={Service.sImg}
                            slug={Service.slug}
                            sdescription={Service.description}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default VolunteerAboutUsPage;
