import React from 'react'
import SectionTitle from '../SectionTitle/SectionTitle';
import NGO from '../../images1/volunteer/home/ngo.png'
import RightVolunteer from '../../images1/ngo/home/right.png'
import Task from '../../images1/ngo/home/easy-task.png'
import Choose from '../../images1/ngo/home/alternative.png'
import Approval from '../../images1/ngo/home/approval.png'
import Planning from '../../images1/ngo/home/planning.png'
import Megaphone from '../../images1/ngo/home/megaphone.png'

function NgoFeaturesData() {
    const featuresData = [
        {
            // iconClass: 'flaticon-volunteer',
            svg: RightVolunteer,
            title: 'Find the Right Volunteers',
            description: 'Access a network of volunteers with the skills and passion to support your projects effectively'
        },
        {
            // iconClass: 'flaticon-volunteer',
            svg: Task,
            title: 'Easy Task Posting',
            description: 'Create and share tasks on our platform, letting volunteers apply directly to your listings.'
        },
        {
            // iconClass: 'flaticon-volunteer',
            svg: Choose,
            title: 'Choose the Best Fit',
            description: 'Volunteers apply, and you pick the ones that suit your needs best, ensuring quality support.'
        },
        {
            // iconClass: 'flaticon-volunteer',
            svg: Approval,
            title: 'Simple Registration & Approval',
            description: 'Register your NGO, get approved by Sevak Army, and start posting tasks right away.'
        },
        {
            // iconClass: 'flaticon-volunteer',
            svg: Planning,
            title: 'Track & Manage Effortlessly',
            description: 'Use an easy dashboard to track progress, communicate with volunteers, and manage tasks smoothly.'
        },
        {
            // iconClass: 'flaticon-volunteer',
            svg: Megaphone,
            title: 'Promote Your Cause Widely',
            description: 'Boost your NGO’s visibility by connecting with volunteers passionate about making a difference.'
        },
    ];

    return (
        <section className="pt-100">
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <SectionTitle title="Empower Your NGO with Sevak Army" />
                    </div>
                </div>

                <div className="wpo-features-area pt-0">
                    <div className="features-wrap">
                        <p className="pb-2 w-100" style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>Connect with Skilled Volunteers and Grow Your Impact </p>
                        <div className="row justify-content-center align-items-start column-gappp">
                            {featuresData.map((feature, index) => (
                                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                                    <div className="feature-item-wrap">
                                        <div className="feature-item d-flex align-items-start" style={{ height: '198px' }}>
                                            <div className="feature-icon">
                                                <div className="icon">
                                                    {feature.iconClass ? <i className={`fi ${feature.iconClass}`}></i> : <img src={feature.svg} alt={feature.svg} />}
                                                </div>
                                            </div>
                                            <div className="feature-text">
                                                <h2 className="fs-5">{feature.title}</h2>
                                                <p className="fs-6" style={{ textAlign: 'justify' }}>{feature.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='w-100 text-center mt-5'>
                    <h2 style={{fontWeight:'700'}}>Find Skilled Volunteers Today!</h2>
                    <p className='text-center'>Register your NGO and connect with passionate, skilled volunteers ready to support your cause.</p>
                </div>
            </div>
        </section>
    )
}

export default NgoFeaturesData