import React from "react";

const ServiceCard = ({ img, title, sdescription, slug, width, height,bgColor }) => {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };
  return (
    <div className="service-item px-4 " style={{height:height, alignSelf:'stretch', marginBottom:'10px'}}>
      <div className="icon">
        <img src={img} width={60} alt="img" />
      </div>
      <div className="text">
        <h2 className="services-title-text fw-bold mt-4">
          {/* <Link className="services-title-text" to={``} style={{ cursor: "default" }}> */}
          {title}
          {/* </Link> */}
        </h2>
        <p className="service-paragraph volunteer-home-text text-center" style={{textAlign:'justify'}}>{sdescription}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
