import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from "../../images/logo.svg";
import PageTitle from '../../components/pagetitle/PageTitle';
import EmployeeEngagementPage from '../../components/Organization/employeeEngagement';

const EmployeeEngagement = ({show, setShow}) => {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={'wpo-site-header'}/>
            <PageTitle pageTitle={'Employee Engagement'} pagesub={'Organization'} />
            <EmployeeEngagementPage  show={show} setShow={setShow} />
            <Scrollbar />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}
export default EmployeeEngagement