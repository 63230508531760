import React, { Fragment } from "react";
import Logo from "../../images/logo.svg";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import OrganizationKeyFeaturesPage from "./OrganizationKeyFeaturesPage";

function OrganizationKeyFeatures({show, setShow}) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"Key Features"} pagesub={"Organization"} />
            <OrganizationKeyFeaturesPage show={show} setShow={setShow}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default OrganizationKeyFeatures;
