import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.svg";
import VolunteerOpportunities from "./VolunteerOpportunities";

const VolunteerOpportunitiesPage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <PageTitle pageTitle={"Opportunities"} pagesub={"Volunteer"} />
      <VolunteerOpportunities />

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default VolunteerOpportunitiesPage;
