import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import icon from "../../images/cta/top-icon.png";
// import Path from "../../images1/volunteer/home/path.png";
import Path from "../../images1/volunteer/home/img.jpg";

import VolunteerOpportunities from "../../images1/volunteer/home/Volunteer-opportunities.png";
import Opportunities from "../../images1/volunteer/home/equal-rights.png";
import Volunterring from "../../images1/volunteer/home/volunterring.png";
import Review from "../../images1/volunteer/home/review.png";
import United from "../../images1/volunteer/home/united.png";
import SignUp from "../../images1/volunteer/home/register.jpg";

import BrowseTask from "../../images1/volunteer/home/refer.jpg";
import ShareLink from "../../images1/volunteer/home/link.png";
import Connect from "../../images1/volunteer/home/connect.png";
import StartVolunteering from "../../images1/influencer/home/voluntear.png";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import NewsletterPopup from "../../components/NewsletterPopup";
import Inspirepeople from "../../images1/influencer/home/inspirepeop.png";

const featuresData = [
  {
    // iconClass: "flaticon-volunteer",
    svg: Opportunities,
    title: "Be a medium for Change",
    description:
      "Bring your followers into a mission-driven community and empower them to contribute to meaningful causes.",
  },
  {
    // iconClass: "flaticon-charity",
    title: "Grow Your Influence",
    svg: Volunterring,
    description:
      "Show your followers you’re dedicated to creating real change, which can boost trust and loyalty within your community.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: United,
    title: "Inspire Collective Action",
    description:
      " By sharing your journey, you encourage your followers to do the same, building a movement of positive impact together.",
  },
];

const BecomeInfluencerPage = (props) => {
  const [shownewsletter, setShowNewsletter] = useState(false);
  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Path} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    <span>
                      Join the Sevak Army through Your Influencer Link and Start
                      Creating Impact Together!
                    </span>
                    <h2 className="about-heading-second text-titleeee">
                      Encourage Your Followers to Make a Difference with Sevak
                      Army
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    As an influencer, you have the power to inspire change. By
                    encouraging your followers to join the Sevak Army, you can
                    help bring passionate volunteers to meaningful causes. Every
                    volunteer who joins through your personal referral link will
                    contribute to impactful tasks that match their skills and
                    interests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container d-flex flex-column justify-content">
          <div className="wpo-event-details-text pt-0 mt-0">
            <SectionTitle
              subtitle=""
              title="How It Works: From Sign-Up to Inspiring Others Through Sevak Army"
              paragarap="As an influencer, you play a vital role in bringing your followers into a community dedicated to making a difference. Here’s how you can easily join Sevak Army, inspire others, and create a positive impact together"
            />
            {/* <div className="w-100 d-flex justify-content-center pt-0 mt-0">
                       <p className="text-center col-lg-8 col-md-10 col-12">As an influencer, you play a vital role in bringing your followers into a community dedicated to making a difference. Here’s how you can easily join Sevak Army, inspire others, and create a positive impact together:</p>
                       </div> */}
          </div>
        </div>

        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 1: Register as an Influencer on Sevak Army</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Begin by signing up on the Sevak Army platform as an
                    influencer. Fill in your details and create a profile that
                    highlights your passion for social causes and the difference
                    you’re aiming to make. This profile helps NGOs and their
                    followers understand your commitment to volunteerism and
                    community support.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-4">
                    {/* <img src={abImg7} alt="img" /> */}
                    <img src={SignUp} alt="Sign Up" width={450} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-5">
                    {/* <img src={abImg7} alt="img" /> */}
                    <img src={ShareLink} alt="Sign Up" width={450} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-5">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 2: Get Your Unique Referral Link</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Once registered, you’ll receive a unique referral link. This
                    link is your personal invitation for your followers to join
                    the Sevak Army, connect with causes they care about, and
                    volunteer for impactful tasks.
                    {/* <ul className="list-disc list-inside">
                                            <li>
                                                <p className="mb-0">  <b>Skills : </b> Whether you’re experienced in teaching, event planning, or logistics, there’s a task for you.</p>
                                            </li>
                                            <li>
                                                <p className="mb-0"> <b>Interests : </b> Passionate about the environment? Love helping in education? Filter tasks to suit your passion.</p>
                                            </li>
                                            <li>
                                                <p className="mb-0"><b>Time Availability : </b> Whether you have a few hours or want a regular commitment, choose tasks that fit your schedule.</p>
                                            </li>
                                        </ul> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>
                      Step 3: Share Your Link and Encourage Your Followerss
                    </h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Use your social media platforms to share your referral link
                    with your followers, encouraging them to sign up as
                    volunteers. Explain how they can easily make a difference by
                    registering and finding tasks that match their skills and
                    interests - whether it's supporting education, healthcare,
                    the environment, or other causes.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-5">
                    <img
                      src={BrowseTask}
                      width={500}
                      alt="Volunteer Opportunities"
                    />
                    {/* <img src="https://placehold.co/324x324/png" alt="Sign Up" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-3">
                    <img src={Inspirepeople} width={500} alt="Review" />
                    {/* <img src="https://placehold.co/324x324/png" alt="Sign Up" /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>
                      Step 4: Inspire Followers to Find Volunteer Opportunities
                    </h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Guide your followers on how to explore tasks that match
                    their skills, availability, and passions on the Sevak Army
                    platform. Your endorsement helps them feel confident that
                    they’re joining a meaningful platform supported by someone
                    they trust.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 5: Track Your Impact and Inspire More Change</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    As your followers join and start volunteering through Sevak
                    Army, you’ll be able to see the growing community you've
                    inspired. Track the collective impact, share success
                    stories, and continue to encourage more followers to join
                    and make a difference alongside you.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-5">
                    <img src={Connect} width={450} alt="Connect" />
                    {/* <img src="https://placehold.co/324x324/png" alt="Sign Up" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* below section not in requirement */}
        {/* section start */}
        {/* <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-3 ">
                    <img src={StartVolunteering} width={500} alt="img" />
                    // <img src="https://placehold.co/324x324/png" alt="Sign Up" /> 
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  // <div className="wpo-section-title mt-0 mb-5">
                                  //      <span>Become a Volunteer</span>
                               //         <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                 //   </div> 
                  <div className="custom-panell">
                    <h3>Step 6: Start Volunteering and Track Your Progress</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Once you are set up with the NGO, it’s time to start making
                    a difference! Whether you’re working on-site or remotely,
                    you can log your hours, track your progress, and earn
                    recognition for your efforts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* section end */}
      </section>

      <section className="pt-100 container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-6 col-md-12 col-12">
                        <SectionTitle subtitle="Serve with Impact" />
                    </div> */}
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle title="Why Join as an Influencer on Sevak Army?" />
          </div>
        </div>

        <div className="wpo-features-area pt-5">
          <div className="features-wrap">
            <div className="row justify-content-center column-gappp">
              {featuresData.map((feature, index) => (
                <div
                  className="col col-lg-4 col-md-6 col-12"
                  key={index}
                  style={{}}
                >
                  <div
                    className="feature-item-wrap"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <div
                      className="feature-item"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "460px",
                        height: "220px",
                      }}
                    >
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <div className="feature-icon">
                          <div className="icon">
                            {feature.iconClass ? (
                              <i className={`fi ${feature.iconClass}`}></i>
                            ) : (
                              <img src={feature.svg} alt={feature.svg} />
                            )}
                          </div>
                        </div>
                        <div className="feature-text">
                          <h2 className="fs-5">{feature.title}</h2>
                          <p className="fs-6" style={{ textAlign: "justify" }}>
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section-s3 section-padding">
        <div className="container">
          <div className="bg-color" style={{ background: "#007560" }}>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <span> Start Inspiring Today</span>
                  <p className="pt-3 px-2" style={{ color: "whitesmoke" }}>
                    Sign up as an influencer with Sevak Army, share your link,
                    and let’s create meaningful change, one task at a time.
                    Together, we’re building a stronger, more connected
                    community for social good.
                  </p>
                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn mt-3"
                  >
                    Share Your Link Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    {/* <span>Join Sevak Army</span> */}
                    <h2>Subscribe to Sevak Army</h2>
                    <p
                      className="px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Stay connected and inspired by subscribing to our updates
                      for influencers. Be the first to know about new volunteer
                      opportunities and campaigns that need your voice. Help
                      motivate your followers to join the Sevak Army and make a
                      real difference.
                    </p>
                    <Link
                      onClick={() => setShowNewsletter(true)}
                      to="#"
                      className="theme-btn"
                    >
                      Subscribe now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomeInfluencerPage;
