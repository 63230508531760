import pimg1 from '../../images1/volunteer/home/sevak-army-volunteer-home-3.jpg'
import pimg2 from '../../images1/volunteer/home/sevak-army-volunteer-home-4.jpg'
import pimg3 from '../../images1/volunteer/home/sevak-army-volunteer-home-5.jpg'
import psimg1 from '../../images1/volunteer/home/sevak-army-volunteer-home-3.jpg'
import psimg2 from '../../images1/volunteer/home/sevak-army-volunteer-home-4.jpg'
import psimg3 from '../../images1/volunteer/home/sevak-army-volunteer-home-5.jpg'
import WorkNGO from '../../images1/volunteer/home/Connect-Volunteers-NGOs.png'
import ChooseTopic from '../../images1/volunteer/home/choose-topic.png'
import AgeGroup from '../../images1/volunteer/home/age-group.png'
import Help from '../../images1/volunteer/home/help.png'
import Points from '../../images1/volunteer/home/cards.png'
import Track from '../../images1/volunteer/home/log.png'
import Reward from '../../images1/volunteer/home/reward.png'
import Caring from '../../images1/volunteer/home/caring.png'
import Invite from '../../images1/volunteer/home/invite.png'
import Communication from '../../images1/volunteer/home/communication.png'
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import simg1 from '../../images/service/1.svg'
import simg2 from '../../images/service/2.svg'
import simg3 from '../../images/service/3.svg'
import simg4 from '../../images/service/4.svg'



const volunteerhomeEvents = [
    {
        id: '1',
        title: 'Work with Verified NGOs',
        description: 'Connect with trusted NGOs dedicated to important causes, ensuring your efforts go to real, impactful projects.',
        slug: '1',
        pimg: WorkNGO,
        psimg: sSimg1,
        date: "25th",
        month: "Sept"
    },
    {
        id: '2',
        title: 'Choose Tasks that Suits You',
        description: 'Find volunteer work that aligns with your skills, interests, and schedule short-term or long-term.',
        slug: '2',
        pimg: ChooseTopic,
        psimg: psimg2,
        date: "1st",
        month: "Oct"
    },
    {
        id: '3',
        title: 'Volunteers of All Ages Welcome',
        description: 'Everyone can make a difference and find suitable tasks, from students and working professionals to retirees.',
        slug: '3',
        pimg: AgeGroup,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '4',
        title: 'Flexible Volunteering',
        description: 'Whether you can contribute a few hours or more regularly, there are tasks that suit your time and commitment level.',
        slug: '4',
        pimg: Help,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '5',
        title: 'Earn Points and Rewards',
        description: 'Every task you complete earns you points that unlock e-certificates and e-badges, recognising your dedication.',
        slug: '4',
        pimg: Points,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '6',
        title: 'Track Your Impact',
        description: 'Log your hours, track your progress, and see your impact as you move up the leaderboard.',
        slug: '4',
        pimg: Track,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '7',
        title: 'Get Recognition',
        description: 'Receive e-certificates for every completed task, which you can showcase on your profile or share on social media.',
        slug: '4',
        pimg: Reward,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '8',
        title: 'Be Part of a Caring Community',
        description: 'Join a supportive community of volunteers committed to serving society and making positive change.',
        slug: '4',
        pimg: Caring,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '9',
        title: 'Invite and Earn',
        description: 'Refer friends or colleagues to join as volunteers and earn additional points, moving up the leaderboard faster.',
        slug: '4',
        pimg: Invite,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
    {
        id: '10',
        title: 'Simple Communication',
        description: 'Chat directly with NGOs to clarify tasks, coordinate schedules, and ensure smooth communication throughout the process.',
        slug: '4',
        pimg: Communication,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
]

export default volunteerhomeEvents;