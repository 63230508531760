import React, { Fragment } from "react";
import Logo from "../../images/logo.svg";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import InfluencerFaqPage from "./InfluencerFaqPage";

function InfluencerFaq () {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"FAQ"} pagesub={"Influencer"} />
            <InfluencerFaqPage />
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default InfluencerFaq;
