import React, { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import NgoAboutFirstSection from "./NgoAboutFirstSection";
import Logo from "../../images/logo.svg";
import NgoFaq from "./NgoFaq";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";

function NgoAbout() {
  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <PageTitle pageTitle={'About Us'} pagesub={'About Us'} />
      <NgoAboutFirstSection />
      <NgoFaq />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
}

export default NgoAbout;
