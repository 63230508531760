import React from "react";
import CountUp from "react-countup";

const FunFact = (props) => {
  return (
    <section
      className={" section-padding " + props.hclass}
      style={{ paddingBottom: "190px" }}
    >
      <div className="container">
        <div className="fun-fact-wrap">
          <div className="row pt-5">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div
                className="info"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 className="d-flex">
                  <span>
                    <CountUp end={5} enableScrollSpy />
                  </span>{" "}
                  +
                </h3>
                <p>
                  {" "}
                  <span>Active Campaigns</span>{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div
                className="info "
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 className="d-flex">
                  <span>
                    <CountUp end={100} enableScrollSpy />
                  </span>{" "}
                  +
                </h3>
                <p>Hours Contributed</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div
                className="info"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 className="d-flex">
                  <span>
                    <CountUp end={50} enableScrollSpy />
                  </span>{" "}
                  +
                </h3>
                <p>Registered Volunteers</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div
                className="info"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 className="d-flex">
                  <span>
                    <CountUp end={25} enableScrollSpy />
                  </span>{" "}
                  +
                </h3>
                <p>Days Since Launch</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
