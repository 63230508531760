import React from "react";

import Image1 from "../../images1/ngo/home/sevak-army-ngo-home-1.jpg";
import Videobg from "../../images1/ngo/home/sevak-army-ngo-home-2.jpg";

import ShapeBg from "../../images/slider/stick-man.svg";
import SupportersSlider from "../hero/SupportersSlider";

function NgoHero(props) {
  return (
    <section className="static-hero">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container-fluid">
            <div className="hero-content">
              <div className="slide-title-sub">
                <span>Get Skilled Help for Your NGO Tasks</span>
              </div>
              <div className="slide-title">
                <h2 className="slide-heading">
                  Get Skilled Help for Your NGO Tasks{" "}
                </h2>
              </div>
              <div className="slide-text">
                <p>
                  Sevak Army brings NGOs and volunteers together to create
                  positive changes. Our platform helps NGOs find dedicated
                  people who want to use their skills to support social causes,
                  building a strong network to make a meaningful impact on
                  communities.
                </p>
              </div>
              <div className="clearfix"></div>
              <div
                data-swiper-parallax="500"
                className="slide-video-content"
                style={{ marginTop: "30px" }}
              >
                <div className="slide-video-img">
                  <img src={Videobg} alt="img" />
                </div>
                <div className="slide-video-text">
                  <h4 className="slide-video-text-show">
                    Join Us and Make a Difference!
                  </h4>
                  <p>
                    Sign up today and get dedicated volunteers to drive your
                    projects forward.
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <button
                  onClick={() => props.setShow(true)}
                  className="theme-btn"
                >
                  Register Your NGO
                </button>
              </div>
              <div className="charius-pic image-positionnnn">
                <div className="charius-pic-main">
                  <img src={Image1} width={700} alt="img" />
                </div>
                {/* <div className="charius-inner">
                  <img src={Image2} alt="img" />
                </div> */}

                <div className="wpo-supporter">
                  <div className="wpo-supporter-text">
                    <div className="icon">
                      <i>
                        <img src={ShapeBg} alt="img" />
                      </i>
                    </div>
                    <div className="content">
                      <h3>500+</h3>
                      <p>NGOs Partnered</p>
                    </div>
                  </div>
                  <SupportersSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NgoHero;
