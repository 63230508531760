import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import About2 from "../../images1/volunteer/about-us/points.jpg";

import About3 from "../../images1/volunteer/about-us/recognition.jpg";
import Referalimg from "../../images1/school-college/home/referal.jpg";
import Compimg from "../../images1/school-college/home/competition.jpg";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import abImg7 from "../../images1/school-college/home/leader-2.jpg";

import LeaderboardWorking from "../../images1/school-college/home/leaderboard.jpg";
import Growth from "../../images1/volunteer/home/growth.png";
import Boost from "../../images1/volunteer/home/boost.png";
import Podium from "../../images1/volunteer/home/podium.png";
import Certification from "../../images1/volunteer/home/certification.png";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Opportunities from "../../images1/volunteer/home/correct.png";
import Award from "../../images/service/benefit.png";
import HelpingNgos from "../../images1/volunteer/home/helping-ngos.png";
import ServiceCard from "../../components/ServiceSection/ServiceCard";
import lImg1 from "../../images1/influencer/leaderboard/motivation.png";
import lImg2 from "../../images1/influencer/leaderboard/influencer.png";
import Img1 from "../../images/service-single/awareness.png";
import Img2 from "../../images/service-single/social-support.png";
import Img3 from "../../images/service-single/school.png";
import icon from "../../images/cta/top-icon.png";

import Cimg from "../../images/service-single/fireworks.png";

const Services = [
  {
    Id: "01",
    sImg: Growth,
    sSImg: sSimg1,
    title: "Track Your Progress in Real Time",
    slug: "1",
    description:
      "Each influencer can log in to their profile and view their standing on the leaderboard. You will also receive regular email updates showing your rank and progress. These updates keep you motivated and informed as you inspire your followers to join the Sevak Army and make an impact.",
  },
  {
    Id: "02",
    sImg: Boost,
    sSImg: sSimg2,
    title: "Boost Your Rank with Referrals",
    slug: "2",
    description:
      "Help grow the Sevak Army community by inviting your followers to join. Each follower who signs up using your referral link will add to your points, helping you climb higher on the leaderboard.",
  },
  {
    Id: "03",
    sImg: Podium,
    sSImg: sSimg3,
    title: "Why Does the Leaderboard Matters?",
    slug: "3",
    description:
      "The Sevak Army Influencer Leaderboard is more than competition—it celebrates your commitment to social good. The more volunteers you inspire, the higher you climb, earning greater recognition. It also helps you set goals and stay motivated as you work toward a better world.",
  },
  {
    Id: "04",
    sImg: Certification,
    sSImg: sSimg4,
    title: "Earn Digital Badges and Certificates",
    slug: "4",
    description:
      "As you climb the leaderboard, you’ll unlock digital badges and certificates for your achievements. These milestones showcase your leadership in social impact and can be shared on social media or added to your portfolio, highlighting your dedication to meaningful causes.",
  },
  {
    Id: "05",
    sImg: lImg1,
    sSImg: sSimg4,
    title: "Stay Motivated and Keep Inspiring Change",
    slug: "4",
    description:
      "Whether aiming for the top spot or simply wanting to track your success in inspiring others, the Sevak Army Influencer Leaderboard keeps you engaged and driven. It’s more than just points - it’s about building a legacy of social change, one follower at a time.",
  },
  {
    Id: "06",
    sImg: lImg2,
    sSImg: sSimg4,
    title: "Join the Influencer Leaderboard Today",
    slug: "4",
    description:
      "Become a Sevak Army influencer and earn points for every follower you inspire to volunteer. Track your progress, climb the ranks, and gain recognition for your role in making a positive impact.",
  },
];

const Services2 = [
  {
    Id: "01",
    sImg: Growth,
    sSImg: sSimg1,
    title: "Encourage Participation",
    slug: "1",
    description:
      "Motivate employees to engage in various volunteer activities. The more involved your team is, the better your rankings will be.",
  },
  {
    Id: "02",
    sImg: Boost,
    sSImg: sSimg2,
    title: "Track Progress",
    slug: "2",
    description:
      "Use our platform to monitor volunteer hours and task completion regularly. This helps set new goals for improvement.",
  },
  {
    Id: "03",
    sImg: Cimg,
    sSImg: sSimg2,
    title: "Celebrate Success",
    slug: "2",
    description:
      "Share achievements within your organisation. Celebrating successes boosts morale and encourages others to participate.",
  },
];

const featuresData = [
  {
    iconClass: "flaticon-volunteer",
    title: "Points for Participation",
    img: About2,
    description:
      "Every task that a student completes as a volunteer earns them points. Different tasks may carry different point values based on their complexity or time commitment. For example, a short-term task might earn fewer points compared to a long-term project or a more complex challenge.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Recognition for Performance",
    img: About3,
    description:
      "Students and Institutions who top the leaderboard are publicly recognised for their efforts, and schools/colleges can also receive collective recognition if their students perform exceptionally well. This fosters a culture of service and achievement among students and institutions alike.",
  },
];

const featuresData1 = [
  {
    iconClass: "flaticon-volunteer",
    img: Img1,
    title: "Weekly Leaderboard",
    description:
      "View the organisations whose employees have logged the most volunteer hours during the week.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Monthly Leaderboard",
    img: Img2,
    description:
      "See which organisations have the highest cumulative service hours throughout the month.",
  },
  {
    iconClass: "flaticon-charity",
    img: Img3,
    title: "Yearly Leaderboard",
    description:
      "Recognise organisations making the greatest overall contributions and impact over the year.",
  },
];

const LeaderboardPage = (props) => {
  // const { slug } = useParams()

  // const EventDetails = events.find(item => item.slug === slug)

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  // const SubmitHandler = (e) => {
  //     e.preventDefault()
  // }

  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img width={580} src={LeaderboardWorking} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3 pb-0">
                    {/* <div className="custom-panell"> */}
                    <span>Welcome to the Leaderboard!</span>
                    <h2 className="about-heading-second text-titleeee mb-0 pb-0">
                      Celebrating Top Performers!
                    </h2>
                    {/* </div> */}
                  </div>
                  {/* <h3 className="mt-0">
                    How the Sevak Army Influencer Leaderboard Works?
                  </h3> */}
                  <p style={{ textAlign: "justify" }}>
                    Our Leaderboard showcases the amazing efforts of
                    organisations and individual volunteers making a difference
                    through their commitment to social good. Here, you can see
                    who’s leading the way in volunteerism and inspire your team
                    to reach new heights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container section-padding pt-0">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    <span>Top Organisations Leaderboard </span>
                    <h2 className="about-heading-second text-titleeee">
                      Leading the Way in Corporate Social Responsibility{" "}
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    This section highlights organisations excelling in volunteer
                    efforts. The rankings are based on
                    <p className="mt-2 mb-1">
                      <b>Total Volunteer Hours:</b> The number of hours
                      employees have volunteered.
                    </p>
                    <p className="mb-1">
                      <b>Tasks Completed:</b> How many volunteer tasks employees
                      have successfully finished.
                    </p>
                    <p className="mb-1">
                      <b>Overall Impact:</b> A combined score of volunteer hours
                      and tasks completed, showing each organisation’s total
                      contribution to the community.
                    </p>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={abImg7} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="wpo-features-area section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle title="How it works?" />
            </div>
          </div>
          <div className="features-wrap mt-3">
            <div className="row justify-content-center">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-5 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div className="feature-item">
                      <div className="feature-text">
                        <div className="mb-4 d-flex justify-content-center align-items-center">
                          <img
                            src={feature.img}
                            alt=""
                            className="img-fluid"
                            style={{
                              height: "300px",
                              width: "100%",
                              objectFit: "cover",
                              maxHeight: "320px",
                            }}
                          />
                        </div>
                        <h2 className="ps-3 fw-bold">{feature.title}</h2>
                        <p className="p-2">
                          {<p className="p-2">{feature.description}</p>}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      <section className="wpo-features-area section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle title="Check out the current top organisations!" />
            </div>
          </div>
          <div className="features-wrap">
            <div className="row justify-content-center">
              {featuresData1.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div
                      className="feature-item"
                      style={{ alignItems: "center", height: "200px" }}
                    >
                      <div className="feature-icon">
                        <div className="icon">
                          <img src={feature.img} className="h-[40px]" />
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <div className="wpo-event-details-area section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="wpo-event-item">
                <div className="wpo-event-img">
                </div>
                <div className="wpo-event-details-text mt-2">
                  <SectionTitle
                    subtitle=""
                    title="Weekly, Monthly, and Yearly Rankings"
                  />
                  <p className="text-center">
                    Sevak Army maintains leaderboards on a weekly, monthly, and yearly basis. This time-based ranking allows students and institutions to continuously strive for improvement while offering fresh opportunities for recognition. It keeps the engagement ongoing and motivates participants to remain active throughout the year.
                  </p>
                </div>
                <div className="wpo-event-details-wrap">
                  <div className="wpo-event-details-tab">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 0
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(0)}
                        >
                          Weekly Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 1
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(1)}
                        >
                          Monthly Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 2
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(2)}
                        >
                          Yearly Leaderboard
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="wpo-event-details-content">
                    <div className="tab-content">
                      <div className={activeTab === 0 ? "active" : "hidden"}>
                        <div id="Schedule" className="tab-pane active w-100">
                          <LeaderboardTable
                            data={weeklyLeaderboard}
                            title="Weekly Leaderboard"
                          />
                        </div>
                      </div>
                      <div className={activeTab === 1 ? "active" : "hidden"}>
                        <div id="Map" className="tab-pane">
                          <LeaderboardTable
                            data={monthlyLeaderboard}
                            title="Monthly Leaderboard"
                          />
                        </div>
                      </div>
                      <div className={activeTab === 2 ? "active" : "hidden"}>
                        <div id="Contact" className="tab-pane">
                          <LeaderboardTable
                            data={yearlyLeaderboard}
                            title="Yearly Leaderboard"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section className="section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle title="Get Involved and Achieve Recognition" />
            </div>
          </div>
          <div className="service-wrap">
            <div className="row justify-content-center column-gappp pt-3">
              {Services2.slice(0, 19).map((Service, sitem) => (
                <div
                  className="col col-lg-4 col-md-8 col-sm-6 col-12"
                  key={sitem}
                >
                  <div className="service-card-wrapper d-flex flex-column h-100">
                    <ServiceCard
                      width="313px"
                      height="320px"
                      title={Service.title}
                      img={Service.sImg}
                      slug={Service.slug}
                      sdescription={Service.description}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-12 col-12">
              <p className="text-center" style={{ fontWeight: "500" }}>
                Join the Leaderboard today and help your organisation make a
                positive impact.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeaderboardPage;
