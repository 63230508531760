import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.svg";
import Scrollbar from "../../components/scrollbar/scrollbar";
import PrivacyPolicyContent from "./content";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar hclass={"wpo-site-header pb-5"} Logo={Logo} />

      <PrivacyPolicyContent />
      <Footer />
      <Scrollbar />
    </>
  );
};

export default PrivacyPolicy;
