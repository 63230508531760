import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import ServiceCard from "../ServiceSection/ServiceCard";

// import simg1 from "../../images/service/1.svg";
// import simg2 from "../../images/service/2.svg";
// import simg3 from "../../images/service/3.svg";

// import sSimg1 from "../../images/service-single/img1.jpg";
// import sSimg2 from "../../images/service-single/img2.jpg";
// import sSimg3 from "../../images/service-single/img3.jpg";

import sSimg4 from "../../images/service-single/img4.jpg";
import simg4 from "../../images/service/details.png";
import SectionTitle from "../SectionTitle/SectionTitle";
import simg1 from "../../images/service/university-card.png";
import simg2 from "../../images/service/industry.png";
import simg3 from "../../images/service/earth.png";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";

import abImg1 from "../../images1/organization/registration/sevak-army-organization-registration-2.png";
import abImg4 from "../../images1/organization/registration/sevak-army-organization-registration-5.jpg";

import About4 from "../../images1/organization/registration/sevak-army-organization-registration-1.jpg";
import About4s2 from "../..//images/slider/stick-man.svg";
import RegisterSevakArmy from "../Volunteer/RegisterSevakArmy";

import Oimg from "../../images/service-single/leaderboard.png";

const OrganizationRegistration = (props) => {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };

  const Services = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      slug: "1",
      description: [
        <h3>Various Social Tasks For Your CSR Goals</h3>,
        <br></br>,
        "We understand that every organisation is different. Our platform provides you with various volunteer opportunities so you can choose the ones that align with your values and objectives. This means your employees can engage in causes that truly matter to them, creating a more meaningful impact.",
      ],
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      slug: "2",
      description: [
        <h3>Easy Employee Engagement</h3>,
        <br></br>,
        "Getting your employees involved has never been easier! Our user-friendly platform lets you invite and motivate your employees to register and participate in volunteer tasks effortlessly. With just a few clicks, you can foster a culture of giving back within your organisation.",
      ],
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      slug: "3",
      description: [
        <h3>Track Your Impact in Real-Time</h3>,
        <br></br>,
        "Stay informed about your team's progress with our comprehensive tracking tools. You can easily monitor volunteer hours, completed tasks, and overall contributions. This insight allows you to report on your CSR achievements and demonstrate your organisation’s commitment to social responsibility.",
      ],
    },
    {
      Id: "04",
      sImg: Oimg,
      sSImg: sSimg3,
      slug: "3",
      description: [
        <h3>Get Top On Organisation Leaderboard</h3>,
        <br></br>,
        "We give recognition to your organisation’s efforts towards social work through our organisation’s leaderboard. The more social work is done by your organisation the more points you will receive and will be ranked at the the top of the leaderboard.",
      ],
    },
  ];

  const Services2 = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      title: "organisation Name",
      slug: "2",
      description: "Register your business under its official name.",
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      title: "Industry",
      slug: "1",
      description:
        "Tell us which industry you belong to so we can match you with relevant volunteering opportunities.",
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      title: "CSR Goals",
      slug: "3",
      description:
        "Share your organisation’s CSR vision and the causes that matter most to your organisation.",
    },
    {
      Id: "04",
      sImg: simg4,
      sSImg: sSimg4,
      title: "organisation Details",
      slug: "4",
      description:
        "Provide contact information and details about your organisation size to help us customise opportunities for your business.",
    },
  ];

  const Services3 = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      title: "Custom Volunteer Opportunities",
      slug: "2",
      description:
        "Choose activities matching your organisation’s values and industry.",
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      title: "Employee Engagement Tools",
      slug: "1",
      description:
        "Easily invite and track your employees' volunteer activities, creating a culture of responsibility and kindness.",
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      title: "Impact Tracking",
      slug: "3",
      description:
        "Monitor volunteer hours and completed tasks to show the positive change your organisation is making.",
    },
    {
      Id: "04",
      sImg: simg4,
      sSImg: sSimg4,
      title: "Recognition and Rewards",
      slug: "4",
      description:
        "Showcase your organisation’s volunteer work with our public leaderboard and achievements system to build brand loyalty.",
    },
  ];

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <section className="wpo-about-section-s4 section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-3 align-items-center">
              <div className="w-lg-50 w-100 order-lg-2 d-flex justify-content-center">
                <div className="wpo-about-img-s5">
                  <div className="image">
                    <img src={About4} alt="" />
                  </div>
                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      <div className="icon">
                        <i>
                          <img src={About4s2} alt="" />
                        </i>
                      </div>
                      <div className="content">
                        <h3>100+</h3>
                        <p>Happy Organizations</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Register with Sevak Army</span>
                    <h2 className="about-heading-third text-titleeee">
                      Making Corporate Social Responsibility Easy
                    </h2>
                  </div>
                  <p>
                    At Sevak Army, we believe every organisation can make an
                    impact on society. Our platform helps organisations sign up
                    and encourage their employees to participate in volunteer
                    activities posted by NGOs. Whether you're a small business
                    or a big organisation, we provide the tools you need to
                    reach your CSR goals and support important causes.{" "}
                  </p>
                  <p>
                    {" "}
                    Get started today! Register your organisation with Sevak
                    Army and encourage your employees to make a positive impact!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="col-12 d-flex align-items-center justify-content-center section-padding pb-0">
          <div className="col col-lg-8 col-md-10">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="w-100 text-center text-titleeee">Register Your Company and Make a Difference.</h2>
              </div>
              <div className="wpo-event-details-wrap" style={{ boxShadow: '' }}>
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              Joining Sevak Army is quick and straightforward. Simply fill out our Company Registration
                              Form with your basic details and we’ll guide you through the rest. Once you’ve completed
                              the form, you’ll gain access to our platform’s full suite of CSR tools and resources.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">Here’s what you’ll need to get started:</p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Company Name:</strong>
                              Register your business under its official name.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Industry:</strong>
                              Tell us which industry you belong to so we can match you with relevant volunteering
                              opportunities.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">CSR Goals:</strong>
                              Share your company’s CSR vision and the causes that matter most to your organization.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Company Details:</strong>
                              Provide contact information and details about your company size to help us tailor
                              opportunities to your business.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Fill out the Form:</strong>
                              Once your company is registered, you can invite employees, manage your volunteer efforts
                              and track your social impact—all from a single platform.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="section-padding pb-0">
          {" "}
          <div className="container pt-3">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-12">
                <SectionTitle
                  title="Register Your organisation and Make a Difference"
                  paragarap="Joining Sevak Army is quick and straightforward. Simply fill out our Company Registration Form with your basic details and we’ll guide you through the rest. Once you’ve completed the form, you’ll gain access to our platform’s full suite of CSR tools and resources."
                />
              </div>
            </div>
            <div className="service-wrap mt-3">
              <div className="row justify-content-center">
                {Services2.slice(0, 5).map((Service, sitem) => (
                  <div
                    className="col col-lg-4 col-md-6 col-sm-6 col-12"
                    key={sitem}
                  >
                    <div
                      className="service-card-wrapper d-flex flex-column align-items-stretch"
                      style={{ alignSelf: "stretch" }}
                    >
                      <ServiceCard
                        height="330px"
                        title={Service.title}
                        img={Service.sImg}
                        slug={Service.slug}
                        sdescription={Service.description}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-lg-8 col-md-12 col-12">
                <p className="text-center" style={{ fontWeight: "600" }}>
                  Once your organisation is registered, you can invite
                  employees, manage your volunteer efforts, and track your
                  social impact from a single platform.
                </p>
                <div className="w-full d-flex justify-content-center">
                  <button
                    onClick={() => props.setShow(true)}
                    className="theme-btn mt-1"
                  >
                    Fill Out the Registration Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-100">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr just-fy-content-center gap-5">
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title mb-3">
                    <h2 className="about-heading-third text-titleeee">
                      Setup Your organisation’s Profile
                    </h2>
                  </div>
                  <p>
                    Once your organisation is registered, it’s time to set up
                    your organisation Profile. This is where you showcase your
                    organisation’s CSR mission and valuable commitment to
                    volunteerism.<br></br>
                    <span className="mb-2">
                      Your organisation profile includes:
                    </span>
                    <p className="mb-1">
                      <b>organisation Logo: </b>Upload your logo to personalise
                      your profile and reinforce your brand’s commitment to CSR.
                    </p>
                    <p className="mb-1">
                      <b>Industry Information:</b> Share details about your
                      industry and the types of volunteer opportunities you’re
                      most interested in.
                    </p>
                    <p className="mb-1">
                      <b>CSR Goals:</b> Highlight your organisation’s long-term
                      goals for social responsibility, showing potential
                      partners, clients, and employees that your organisation is
                      dedicated to making a difference.
                    </p>
                    <p className="mb-1">
                      <b>Volunteering History:</b> As your employees complete
                      volunteer tasks, their contributions will be tracked and
                      displayed in your organisation’s history, showcasing the
                      impact made through the Sevak Army platform.
                    </p>
                    This profile helps you manage your volunteer efforts and
                    serves as a public commitment to social good, enhancing your
                    organisation’s reputation and value.
                  </p>
                </div>
              </div>
              <br></br>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg1} width={600} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-padding pt-100">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                      <img src={abImg3} alt="img" />
                    </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title mb-3">
                      <h2 className="about-heading-second text-titleeee">
                        Invite Your Employees
                      </h2>
                    </div>
                    <p>
                      Getting your employees involved in volunteering is easy
                      with Sevak Army. After registering your organisation, you
                      can send Employee Registration Invitations directly from
                      our platform. Invite them to join your CSR initiatives.{" "}
                      <br />
                      <span className="mb-2">Employees can: </span>
                      <ul className="list-style-disccc">
                        <li
                          className="mb-1"
                          style={{ width: "fit-content", color: "#1B1212" }}
                        >
                          <span className="list-bullet me-2"></span> Organising
                          and promoting volunteer tasks.
                        </li>
                        <li
                          className="mb-1"
                          style={{ width: "fit-content", color: "#1B1212" }}
                        >
                          <span className="list-bullet me-2"></span>{" "}
                          Collaborating with NGOs and community groups
                        </li>
                        <li
                          className="mb-1"
                          style={{ width: "fit-content", color: "#1B1212" }}
                        >
                          <span className="list-bullet me-2"></span> Ensuring
                          smooth execution of volunteer projects.
                        </li>
                        <li
                          className="mb-1"
                          style={{ width: "fit-content", color: "#1B1212" }}
                        >
                          <span className="list-bullet me-2"></span> Tracking
                          and reporting progress on tasks.
                        </li>
                        <li
                          className="mb-1"
                          style={{ width: "fit-content", color: "#1B1212" }}
                        >
                          <span className="list-bullet me-2"></span> Recognising
                          and motivating student volunteers.
                        </li>
                      </ul>
                      Start building a culture of volunteerism within your
                      organisation by inviting your team today.
                      <p style={{ fontWeight: "600" }}>
                        It’s simple, effective, and it starts with a click!
                      </p>
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                      >
                        Get Started Now
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-12">
                <SectionTitle
                  title="Why Register with Sevak Army?"
                  paragarap="When you sign up for Sevak Army, you unlock features to boost
                  your CSR efforts"
                />
                {/* <p className="text-center">
                  When you sign up for Sevak Army, you unlock features to boost
                  your CSR efforts
                </p> */}
              </div>
            </div>
            <div className="service-wrap mt-3">
              <div className="row justify-content-center">
                {Services3.slice(0, 5).map((Service, sitem) => (
                  <div
                    className="col col-lg-4 col-md-6 col-sm-6 col-12"
                    key={sitem}
                  >
                    <div className="service-card-wrapper d-flex flex-column h-100">
                      <ServiceCard
                        title={Service.title}
                        img={Service.sImg}
                        slug={Service.slug}
                        sdescription={Service.description}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className="text-center">
              Register Today and take the first step.
            </p>
          </div>

          <section className="mt-3">
            <div className="d-flex justify-content-center">
              <Link
                onClick={() => props.setShow(true)}
                to="#"
                className="theme-btn "
              >
                Register Now
              </Link>
            </div>
          </section>
        </div>
      </section>
      <section className={"mb-5 pt-0 p-0" + props.hclass}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12 ">
              <SectionTitle
                // subtitle="Why Choose Us?"
                title="Why Choose Us?"
                paragarap="At Sevak Army, we offer a unique approach to corporate social responsibility that stands out from the rest. Here’s why your organisation should register with us"
              />
            </div>
          </div>
          <div className="service-wrap mt-3">
            <div className="row justify-content-center">
              {Services.slice(0, 4).map((Service, sitem) => (
                <div
                  className="col col-lg-4 col-md-6 col-sm-6 col-12 "
                  key={sitem}
                >
                  <ServiceCard
                    height="550px"
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrganizationRegistration;
