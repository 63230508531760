import React from "react";
import SupportersSlider from "./SupportersSlider";

import Image1 from "../../images1/home/sevak-army-home-1.jpg";
import Videobg from "../../images1/home/sevak-army-home-2.jpg";
import ShapeBg from "../../images/slider/stick-man.svg";

const Hero = () => {
  return (
    <section className="static-hero">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container-fluid responsive-containerrr">
            <div className="hero-content">
              <div className="slide-title-sub">
                <span>Welcome to Sevak Army</span>
              </div>
              <div className="slide-title">
                <h2 className="slide-heading">
                  Empower India Through Service and Volunteering{" "}
                </h2>
              </div>
              <div className="slide-text">
                <p>
                  Connect with verified NGOs, take on meaningful tasks, track
                  your volunteer hours, and make a lasting impact through social
                  work across India.
                </p>
              </div>
              <div className="clearfix"></div>
              <div data-swiper-parallax="500" className="slide-video-content">
                <div className="slide-video-img">
                  <img src={Videobg} alt="img" />
                  {/* <VideoModal /> */}
                </div>
                <div className="slide-video-text">
                  <h4 className="slide-video-text-show">
                    A Commitment to Service, A Brighter Tomorrow{" "}
                  </h4>
                  <p>
                    Every volunteer service hour counts. Every action sparks
                    transformation.{" "}
                  </p>
                </div>
              </div>
              <div className="charius-pic image-positionnnn">
                <div className="charius-pic-main">
                  <img src={Image1} width={700} alt="img" />
                </div>
                {/* <div className="charius-inner"  style={{marginLeft: "800px"}}>
                  <img className="img-fluid" width={500} src={Image2} alt="img"/>
                </div> */}

                <div className="wpo-supporter">
                  <div className="wpo-supporter-text">
                    <div className="icon">
                      <i>
                        <img src={ShapeBg} alt="img" />
                      </i>
                    </div>
                    <div className="content">
                      <h3>120+</h3>
                      <p>Happy Volunteer</p>
                    </div>
                  </div>
                  <SupportersSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
