import React, { Fragment } from "react";
import Logo from "../../images/logo.svg";
import Navbar from "../Navbar/Navbar";
import PageTitle from "../pagetitle/PageTitle";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import VolunteerFaqPage from "./VolunteerFaqPage";

function VolunteerFaq () {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"FAQ"} pagesub={"Volunteer"} />
            <VolunteerFaqPage />
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default VolunteerFaq;
