import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Registration from '../../components/Organization/organizationRegistration';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from "../../images/logo.svg";

const OrganizationHome = ({show, setShow}) => {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={'wpo-site-header'}/>
            <PageTitle pageTitle={'Registration'} pagesub={'Registration'} />
            <Registration show={show} setShow={setShow}/>
            <Scrollbar />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
}
export default OrganizationHome