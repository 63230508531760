import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
// import Path from "../../images1/volunteer/home/path.png";
import Path from "../../images1/volunteer/home/ambassador-photoroom.png";
import Path2 from "../../images1/volunteer/home/ambassador.png";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import Networking from "../../images1/volunteer/home/networking.png";
import Institute from "../../images1/school-college/ambassador/institute.png";
import Reputationn from "../../images1/school-college/ambassador/reputation.png";
import MeetNetwork from "../../images1/volunteer/home/meet-network.png";
import Newsletter from "../../images1/volunteer/home/influencer.jpg";
import Inspire from "../../images1/volunteer/home/inspire.png";
import icon from "../../images/cta/top-icon.png";
import ServiceCard from "../../components/ServiceSection/ServiceCard";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

import rImg1 from "../../images1/school-college/ambassador/leadership.png";
import rImg2 from "../../images1/school-college/ambassador/coordination.png";
import rImg3 from "../../images1/school-college/ambassador/volunteer.png";
import rImg4 from "../../images1/school-college/ambassador/ngo.png";
import rImg5 from "../../images1/school-college/ambassador/recognition.png";

import erImg3 from "../../images1/school-college/ambassador/inspiration.png";
import erImg4 from "../../images1/school-college/ambassador/opportunity.png";

const Services = [
  {
    Id: "01",
    sImg: rImg1,
    sSImg: sSimg1,
    title: "Local Leadership",
    slug: "1",
    description:
      "Ambassadors act as the main link between Sevak Army and their institution, representing its values and guiding students in choosing volunteer opportunities while managing student-NGO collaborations.",
  },
  {
    Id: "02",
    sImg: rImg2,
    sSImg: sSimg2,
    title: "Task Coordination",
    slug: "2",
    description:
      "Ambassadors help schools and colleges organise and execute volunteer tasks.  Ambassadors will also track task progress, ensuring that students are actively contributing to causes they care about.",
  },
  {
    Id: "03",
    sImg: rImg3,
    sSImg: sSimg3,
    title: "Volunteer Promotion",
    slug: "3",
    description:
      "Ambassadors promote a culture of service by encouraging student involvement in volunteer programs, recruiting for roles like event planning, tutoring, and community outreach, and raising awareness about community service.",
  },
  {
    Id: "04",
    sImg: rImg4,
    sSImg: sSimg4,
    title: "Engaging with NGOs",
    slug: "4",
    description:
      "Ambassadors collaborate with NGOs to match students with meaningful tasks aligned to their skills, connecting them to impactful projects that support personal growth and community benefit.",
  },
  {
    Id: "05",
    sImg: rImg5,
    sSImg: sSimg4,
    title: "Recognition and Motivation",
    slug: "4",
    description:
      "Ambassadors are responsible for recognising the contributions of student volunteers and motivating them to participate actively. They will track accomplishments and reward volunteers, encouraging continued involvement.",
  },
];

const Services2 = [
  {
    Id: "01",
    sImg: rImg1,
    sSImg: sSimg1,
    title: "Develop Leadership Skills",
    slug: "1",
    description:
      "Ambassadors take on the responsibility of leading volunteer initiatives, managing teams, and working with NGOs. They gain hands-on experience in leadership and project management, skills that are highly valuable for their future careers.",
  },
  {
    Id: "02",
    sImg: rImg5,
    sSImg: sSimg2,
    title: "Gain Recognition and e-certification",
    slug: "2",
    description:
      "Ambassadors receive official e-certificates of recognition for their contribution to the Sevak Army platform and their role in promoting volunteerism. This recognition not only enhances their resumes but also showcases their commitment to community service.",
  },
  {
    Id: "03",
    sImg: erImg3,
    sSImg: sSimg3,
    title: "Inspire Change in Others",
    slug: "3",
    description:
      "As role models within their schools or colleges, ambassadors inspire their peers to join volunteer efforts, create a culture of giving, and positively influence their local communities. They can play an essential role in changing the lives of others while developing their own sense of purpose.",
  },
  {
    Id: "04",
    sImg: erImg4,
    sSImg: sSimg4,
    title: "Exclusive Opportunities",
    slug: "4",
    description:
      "Outstanding ambassadors may be publicly acknowledged and recognised for their contributions. They may be invited to special events or forums where they can network with like-minded individuals, NGOs, and professionals from different fields, further enhancing their experience and exposure.",
  },
];

const SchoolCollegeAmbassadorPage = (props) => {
  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5 pb-0">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Path} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    {/* <span>Benefits</span> */}
                    <h2 className="about-heading-second text-titleeee">
                      Sevak Army School/College Ambassador Programme
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    The Sevak Army School/College Ambassador Programme provides
                    a unique opportunity for schools and colleges to nominate
                    enthusiastic students to lead volunteering efforts in their
                    institutions. These ambassadors will connect students, NGOs,
                    and the community, playing a vital role in encouraging a
                    culture of service and social responsibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    {/* <span>Benefits</span> */}
                    <h2 className="about-heading-second text-titleeee">
                      What is the Ambassador Programme?
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    The Ambassador Programme is designed to empower students to
                    take on leadership roles in their schools and colleges by
                    promoting volunteer work through Sevak Army. Ambassadors are
                    the face of the platform within their institution,
                    responsible for managing volunteer activities, promoting
                    events, and motivating their peers to contribute to
                    meaningful causes. This role helps ambassadors develop
                    essential life skills like leadership, teamwork, project
                    management, and communication while making a tangible impact
                    in their communities.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Path2} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container section-padding pt-0">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle subtitle="" title="Role of Ambassadors" />
          </div>
        </div>
        <div className="service-wrap">
          <div className="row justify-content-center column-gappp">
            {Services.slice(0, 10).map((Service, sitem) => (
              <div
                className="col col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <ServiceCard
                    width="313px"
                    height="425px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container section-padding pt-0">
        <div className="row justify-content-center mb-2">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle
              subtitle=""
              title="Why Become an Ambassador?"
              paragarap="The Sevak Army Ambassador Programme offers students more than just volunteer opportunities – it’s a leadership development platform that enhances personal growth and makes a lasting impact in society. By becoming an ambassador, students will"
            />
          </div>
        </div>
        <div className="service-wrap mt-3">
          <div className="row justify-content-center column-gappp">
            {Services2.slice(0, 10).map((Service, sitem) => (
              <div
                className="col col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <ServiceCard
                    width="313px"
                    height="472px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <section className="container section-padding pt-0">
        <section className="">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle title="Benefits for Schools and Colleges" />
            </div>
          </div>
          {/* <div className="wpo-features-area pt-0 px-2">
                        <div className="features-wrap">
                            <div className="d-flex justify-content-center">
                                <p className="pb-2 text-center col-12 col-md-10 col-lg-8" style={{ textAlign: 'center' }}>Volunteers who rank high on our Leaderboard or regularly contribute their time and skills will be invited to our Sevak Army Recognition Events. At these events, we: </p>
                            </div>
                            <div className="row justify-content-center column-gappp">
                                {featuresData.map((feature, index) => (
                                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                                        <div className="feature-item-wrap">
                                            <div className="feature-item" style={{ alignItems: 'start', height: '197px' }}>
                                                <div className="feature-icon">
                                                    <div className="icon">
                                                        {feature.iconClass ? <i className={`fi ${feature.iconClass}`}></i> : <img src={feature.svg} />}
                                                    </div>
                                                </div>
                                                <div className="feature-text">
                                                    <h2 className="fs-5">{feature.title}</h2>
                                                    <p className="fs-6" style={{ textAlign: 'justify' }}>{feature.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
        </section>

        <div className="wpo-about-wrap pt-3">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-3 mt-0">
                {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                <div className="custom-panell">
                  <h3>Build a Culture of Service</h3>
                </div>
                <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                  Schools and colleges that participate in the Sevak Army
                  Ambassador Programme will foster a sense of responsibility and
                  service in their students. The programme encourages students
                  to become actively involved in the betterment of society,
                  which contributes to their overall personal development.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img d-flex w-100 justify-content-end">
                <div className="wpo-about-right">
                  {/* <img src={abImg7} alt="img" /> */}
                  <img src={Networking} width={480} alt="networking" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wpo-about-wrap pt-5">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img d-flex w-100 justify-content-start">
                <div className="wpo-about-right pt-lg-3">
                  <img src={Reputationn} width={500} alt="img" />
                  {/* <img src="https://placehold.co/324x304/png" alt="Sign Up" /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-3 mt-0">
                {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                <div className="custom-panell">
                  <h3>Enhance Institutional Reputation</h3>
                </div>
                <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                  By promoting volunteerism and social responsibility,
                  educational institutions can enhance their reputation as
                  organisations that care about the community. The programme
                  helps position schools and colleges as leaders in shaping
                  responsible, empathetic future citizens.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="wpo-about-wrap pt-5">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-3 mt-0">
                {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                <div className="custom-panell">
                  <h3>Recognition for Institutions</h3>
                </div>
                <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                  Schools and colleges that support the ambassador programme
                  will also receive recognition for their role in promoting
                  community service. Institutions can highlight their
                  involvement in creating impactful social change through
                  student participation.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img d-flex w-100 justify-content-end">
                <div className="wpo-about-right pt-lg-3">
                  <img src={Institute} width={500} alt="img" />
                  {/* <img src="https://placehold.co/324x304/png" alt="Sign Up" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section section-padding pt-0">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={Inspire} height={530} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    <span>How to Get Involved?</span>
                    <h2 className="about-heading-second text-titleeee">
                      Unleash the Power of Student Leadership
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    Schools and colleges can nominate students who show
                    enthusiasm and dedication to serve as ambassadors. Once
                    selected, these ambassadors will be responsible for creating
                    volunteer teams, promoting upcoming projects, and ensuring
                    active participation in events. They will work alongside the
                    Sevak Army team and NGOs to organise and manage tasks that
                    align with the goals of the institution. <br />
                    By joining the Sevak Army Ambassador Programme, schools and
                    colleges provide their students with a valuable platform to
                    grow, learn, and make meaningful contributions to society.
                  </p>
                  {/* <p>
                                        Become a Sevak Army Ambassador today and inspire others to
                                        serve!
                                    </p> */}
                  {/* <div>
                                        <button
                                            className="theme-btn"
                                            onClick={() => props.setShow(true)}
                                        >
                                            Apply Now to Be an Ambassador
                                        </button>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section section-padding pt-0">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row ">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    {/* <span>Key Responsibilities of Ambassadors</span> */}
                    <h2 className="about-heading-second text-titleeee w-100">
                      Key Responsibilities of Ambassadors
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    The Sevak Army Ambassador Programme for schools and colleges
                    is an exceptional initiative that not only promotes
                    volunteerism but also empowers students to step up as
                    leaders and changemakers. It offers a structured way for
                    educational institutions to integrate community service into
                    their core values while providing students with real-world
                    experiences that will shape them into responsible,
                    compassionate leaders of tomorrow.
                  </p>
                  <ul className="list-style-disccc">
                    <li
                      className="mb-1"
                      style={{ width: "fit-content", color: "#1B1212" }}
                    >
                      <span className="list-bullet me-2"></span> Organising and
                      promoting volunteer tasks.
                    </li>
                    <li
                      className="mb-1"
                      style={{ width: "fit-content", color: "#1B1212" }}
                    >
                      <span className="list-bullet me-2"></span> Collaborating
                      with NGOs and community groups
                    </li>
                    <li
                      className="mb-1"
                      style={{ width: "fit-content", color: "#1B1212" }}
                    >
                      <span className="list-bullet me-2"></span> Ensuring smooth
                      execution of volunteer projects.
                    </li>
                    <li
                      className="mb-1"
                      style={{ width: "fit-content", color: "#1B1212" }}
                    >
                      <span className="list-bullet me-2"></span> Tracking and
                      reporting progress on tasks.
                    </li>
                    <li
                      className="mb-1"
                      style={{ width: "fit-content", color: "#1B1212" }}
                    >
                      <span className="list-bullet me-2"></span> Recognising and
                      motivating student volunteers.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Newsletter} width={440} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2 mb-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>
                      Join the Ambassador Programme today and inspire the next
                      generation of student leaders!
                    </span>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100 mt-3">
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Join Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchoolCollegeAmbassadorPage;
