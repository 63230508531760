import React, { Fragment } from "react";
import Logo from "../../images/logo.svg";
import Navbar from "../Navbar/Navbar";
import PageTitle from "../pagetitle/PageTitle";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import BecomeVolunteerPage from "./BecomeVolunteerPage";

function BecomeVolunteer({show, setShow}) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={"Become A Volunteer"} pagesub={"Volunteer"} />
            <BecomeVolunteerPage show={show} setShow={setShow}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default BecomeVolunteer;
