import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import AboutPage from "../AboutPage/AboutPage";
import DonatePage from "../DonatePage/DonatePage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import ContactPageMain from "../ContactPage/ContactPageMain";
// import LoginPage from "../LoginPage/index";
// import Register from "../SignUpPage/index";
import SchoolCollegeHome from "../School-CollegePage";
import SchoolCollegePages from "../School-CollegePage/Pages";
import OrganizationRegistration from "../Organization/OrganizationRegistration";
import Influencer from "../Influencer/Influencer";
import EmployeeEngagement from "../Organization/EmployeeEngagement";
import Leaderboard from "../Organization/Leaderboard";
import ContactUs from "../Organization/ContactUs";
import OrganizationHome from "../Organization/OrganizationHome";
import Layout_SEO from "../../components/LayoutSEO";
import ContactUsNgo from "../../components/ContactusNGO/ContactUsNgo";
import NgoHome from "../../components/NgoHome/NgoHome";
import VolunteerEvents from "../../components/VolunteerEvents/VolunteerEvents";
import Volunteerhome from "../../components/Volunteer/Volunteerhome";
import InflunencerPages from "../InfluencerPages/InflunencerPages";
import NgoAbout from "../../components/NgoAbout/NgoAbout";
import VolunteerAboutUs from "../../components/VolunteerAbout/VolunteerAboutUs";
import VolunteerOpportunitiesPage from "../../components/VolunteerOpportunities/VolunteerOpportunitiesPage";

import NgoMainInvlved from "../../components/NgoGetInvolved/NgoMainInvlved";
import TermsAndConditions from "../Tearms-Conditions";
import PrivacyPolicy from "../PrivacyPolicy";
import AuthModal from "../../auth-modal";
import VolunteerKeyFeatures from "../../components/Volunteer/VolunteerKeyFeatures";
import VolunteerBenefits from "../../components/Volunteer/VolunteerBenefits";
import VolunteerLeaderboard from "../../components/Volunteer/VolunteerLeaderboard";
import VolunteerRecognitionEvent from "../../components/Volunteer/VolunteerRecognitionEvent";
import BecomeVolunteer from "../../components/Volunteer/BecomeVolunteer";
import VolunteerFaq from "../../components/Volunteer/VolunteerFaq";
import ReferAndEarn from "../../components/ReferAndEarn";
import DownloadResources from "../../components/Volunteer/DownloadResources";
import FAQPage from "../../components/FAQPage";
import NgoBenefits from "../../components/NgoAbout/NgoBenefits";
import NgoKeyFeatures from "../../components/NgoAbout/NgoKeyFeatures";
import NgoRegistrationProcess from "../../components/NgoAbout/NgoRegistrationProcess";
import NgoFaqPage from "../../components/NgoAbout/NgoFaqPage";
import InfluencerKeyFeatures from "../Influencer/InfluencerKeyFeatures";
import InfluencerBenefits from "../Influencer/InfluencerBenefits";
import InfluencerLeaderboard from "../Influencer/InfluencerLeaderboard";
import InfluencerRecognitionEvents from "../Influencer/InfluencerRecognitionEvents";
import BecomeInfluencer from "../Influencer/BecomeInfluencer";
import InfluencerFaq from "../Influencer/InfluencerFaq";
import SchoolCollegeFeatures from "../School-CollegePage/SchoolCollegeFeatures";
import SchoolCollegeBenefits from "../School-CollegePage/SchoolCollegeBenefits";
import SchoolCollegeAmbassador from "../School-CollegePage/SchoolCollegeAmbassador";
import SchoolCollegeLeaderboard from "../School-CollegePage/SchoolCollegeLeaderboard";
import SchoolCollegeRecognitionEvents from "../School-CollegePage/SchoolCollegeRecognitionEvents";
import SchoolCollegeRegisterInstitute from "../School-CollegePage/SchoolCollegeRegisterInstitute";
import SchoolCollegeFaq from "../School-CollegePage/SchoolCollegeFaq";
import SchoolCollegeContact from "../School-CollegePage/SchoolCollegeContact";
import InfluencerContactUs from "../Influencer/InfluencerContactUs";
import InfluencerReferAndEarn from "../Influencer/InfluencerReferAndEarn";
import OrganizationKeyFeatures from "../Organization/OrganizationKeyFeatures";
import OrganizationBenefits from "../Organization/OrganizationBenefits";
import OrganizationAmbassadorProgramme from "../Organization/OrganizationAmbassadorProgramme";
import OrganizationRecognition from "../Organization/OrganizationRecognition";

const AllRoute = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Layout_SEO>
          <Routes>
            {/* <Route path="home" element={<Homepage show={show} setShow={setShow} />} />
            <Route path="home-2" element={<HomePage2 />} />
            <Route path="home-3" element={<HomePage3 />} />
            <Route path="home-4" element={<HomePage4 />} />
            <Route path="about" element={<AboutPage />} /> */}
            {/* <Route path="testimonial" element={<TestimonialPage />} />
            <Route path="causes-s2" element={<CausePagetwo />} />
            <Route path="causes-s3" element={<CausePagethre />} /> */}
            {/* <Route path="causes-single/:slug" element={<CauseSinglePage />} />
            <Route path="volunteer-single" element={<VolunteerSinglePage />} />
            <Route path="service" element={<ServicePage />} />
            <Route path="service-single/:slug" element={<ServiceSinglePage />} /> */}
            {/* <Route path="event" element={<EventPage />} />
            <Route path="event-single/:slug" element={<EventSinglePage />} /> */}
            {/* <Route path="blog" element={<BlogPage />} />
            <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
            <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
            <Route path="blog-single/:slug" element={<BlogDetails />} />
            <Route path="blog-single-fullwidth/:slug" element={<BlogDetailsFull />} />
            <Route path="blog-single-left-sidebar/:slug" element={<BlogDetailsLeftSiide />} /> */}
            {/* <Route path="forgot" element={<ForgotPassword />} /> */}
            {/* <Route path="causes" element={<CausePageOn />} /> */}
            {/* <Route path="404" element={<ErrorPage />} /> */}
            {/* <Route path="gallery" element={<GalleryPage />} /> */}

            <Route path="/" element={<Homepage show={show} setShow={setShow} />} />
            <Route path="contact-us" element={<ContactPageMain show={show} setShow={setShow} />} />
            <Route path="donate" element={<DonatePage show={show} setShow={setShow} />} />
            {/* <Route path="login" element={<LoginPage show={show} setShow={setShow} />} /> */}
            {/* <Route path="register" element={<Register show={show} setShow={setShow} />} /> */}
            <Route path="school-college" element={<SchoolCollegeHome show={show} setShow={setShow} />} />
            <Route path="volunteer/events" element={<VolunteerEvents show={show} setShow={setShow} />} />
            <Route path="school-college/about-us" element={<SchoolCollegePages show={show} setShow={setShow} />} />
            <Route path="school-college/key-features" element={<SchoolCollegeFeatures show={show} setShow={setShow} />} />
            <Route path="school-college/benefits" element={<SchoolCollegeBenefits show={show} setShow={setShow} />} />
            <Route path="school-college/ambassador-programme" element={<SchoolCollegeAmbassador show={show} setShow={setShow} />} />
            <Route path="school-college/leaderboard" element={<SchoolCollegeLeaderboard show={show} setShow={setShow} />} />
            <Route path="school-college/recognition-events" element={<SchoolCollegeRecognitionEvents show={show} setShow={setShow} />} />
            <Route path="school-college/register-institute" element={<SchoolCollegeRegisterInstitute show={show} setShow={setShow} />} />
            <Route path="school-college/faq" element={<SchoolCollegeFaq show={show} setShow={setShow} />} />
            <Route path="influencer" element={<Influencer show={show} setShow={setShow} />} />
            {/* <Route path="school-college/donations" element={<DonatePage show={show} setShow={setShow} />} />
            <Route path="school-college/events" element={<SchoolCollegeEvents show={show} setShow={setShow} />} />
            <Route path="school-college/contact-us" element={<SchoolContact show={show} setShow={setShow} />} /> */}
            {/* <Route path="school-college/blogs" element={<SchoolBlog show={show} setShow={setShow} />} /> */}
            <Route path="/ngo/contact-us" element={<ContactUsNgo show={show} setShow={setShow} />} />
            <Route path="/ngo/registration-process" element={<NgoRegistrationProcess show={show} setShow={setShow} />} />
            <Route path="/ngo/faq" element={<NgoFaqPage show={show} setShow={setShow} />} />
            <Route path="influencer" element={<Influencer show={show} setShow={setShow} />} />
            {/* <Route path="influencer/blogs" element={<InfluencerBlogPage show={show} setShow={setShow} />} /> */}
            <Route path="influencer/about-us" element={<InflunencerPages show={show} setShow={setShow} />} />
            <Route path="influencer/key-features" element={<InfluencerKeyFeatures show={show} setShow={setShow} />} />
            <Route path="influencer/benefits" element={<InfluencerBenefits show={show} setShow={setShow} />} />
            <Route path="influencer/leaderboard" element={<InfluencerLeaderboard show={show} setShow={setShow} />} />
            <Route path="influencer/recognition-events" element={<InfluencerRecognitionEvents show={show} setShow={setShow} />} />
            <Route path="influencer/join-sevak-army" element={<BecomeInfluencer show={show} setShow={setShow} />} />
            <Route path="influencer/faq" element={<InfluencerFaq show={show} setShow={setShow} />} />
            {/* <Route path="influencer/contact-us" element={<InfluencerContact show={show} setShow={setShow} />} /> */}
            {/* <Route path="influencer/donation" element={<InfluencerDonation show={show} setShow={setShow} />} /> */}
            {/* <Route path="influencer/services" element={<InfluencerService show={show} setShow={setShow} />} /> */}
            <Route path="/ngo" element={<NgoHome show={show} setShow={setShow} />} />
            <Route path="/ngo/about-us" element={<NgoAbout show={show} setShow={setShow} />} />
            <Route path="/ngo/benefits" element={<NgoBenefits show={show} setShow={setShow} />} />
            <Route path="/ngo/key-features" element={<NgoKeyFeatures show={show} setShow={setShow} />} />
            <Route path="organization" element={<OrganizationHome show={show} setShow={setShow} />} />
            <Route
              path="organization/registration"
              element={<OrganizationRegistration show={show} setShow={setShow} />}
            />
            <Route
              path="organization/ambassador-programme"
              element={<OrganizationAmbassadorProgramme show={show} setShow={setShow} />}
            />
            <Route
              path="organization/benefits"
              element={<OrganizationBenefits show={show} setShow={setShow} />}
            />
            <Route
              path="organization/key-features"
              element={<OrganizationKeyFeatures show={show} setShow={setShow} />}
            />
            <Route
              path="organization/employee-engagement"
              element={<EmployeeEngagement show={show} setShow={setShow} />}
            />
            <Route path="organization/leaderboard" element={<Leaderboard show={show} setShow={setShow} />} />
            <Route path="organization/certificates-recognition" element={<OrganizationRecognition show={show} setShow={setShow} />} />
            <Route path="organization/contact-us" element={<ContactUs show={show} setShow={setShow} />} />
            <Route path="organization/about-us" element={<AboutPage show={show} setShow={setShow} />} />
            {/* <Route path="organization/blogs" element={<Blog show={show} setShow={setShow} />} /> */}
            <Route path="/ngo/get-involved" element={<NgoMainInvlved show={show} setShow={setShow} />} />
            <Route path="volunteer" element={<Volunteerhome show={show} setShow={setShow} />} />
            <Route path="volunteer/about-us" element={<VolunteerAboutUs show={show} setShow={setShow} />} />
            <Route path="volunteer/key-features" element={<VolunteerKeyFeatures show={show} setShow={setShow} />} />
            <Route
              path="volunteer/opportunities"
              element={<VolunteerOpportunitiesPage show={show} setShow={setShow} />}
            />
            <Route
              path="volunteer/benefits"
              element={<VolunteerBenefits show={show} setShow={setShow} />}
            />
            <Route
              path="volunteer/leaderboard"
              element={<VolunteerLeaderboard show={show} setShow={setShow} />}
            />
            <Route
              path="volunteer/recognition-events"
              element={<VolunteerRecognitionEvent show={show} setShow={setShow} />}
            />
            <Route
              path="volunteer/become-volunteer"
              element={<BecomeVolunteer show={show} setShow={setShow} />}
            />
            <Route
              path="volunteer/faq"
              element={<VolunteerFaq show={show} setShow={setShow} />}
            />
            {/* <Route path="volunteer/blogs" element={<VolunteerBlogsPage show={show} setShow={setShow} />} /> */}
            <Route path="volunteer/contact-us" element={<ContactPage show={show} setShow={setShow} />} />
            <Route path="school-college/contact-us" element={<SchoolCollegeContact show={show} setShow={setShow} />} />
            <Route path="influencer/contact-us" element={<InfluencerContactUs show={show} setShow={setShow} />} />
            <Route path="volunteer/refer-and-earn" element={<ReferAndEarn show={show} setShow={setShow} />} />
            <Route path="influencer/refer-and-earn" element={<InfluencerReferAndEarn show={show} setShow={setShow} />} />
            <Route path="volunteer/download-resources" element={<DownloadResources show={show} setShow={setShow} />} />
            <Route path="terms-and-conditions" element={<TermsAndConditions show={show} setShow={setShow} />} />
            <Route path="privacy-policy" element={<PrivacyPolicy show={show} setShow={setShow} />} />
            <Route path="faq" element={<FAQPage show={show} setShow={setShow} />} />
            {/* <Route path="refer-and-earn" element={<ReferAndEarn show={show} setShow={setShow} />} /> */}
            <Route path="opportunities/:slug" element={<BlogDetails show={show} setShow={setShow} />} />
          </Routes>
        </Layout_SEO>
        <AuthModal show={show} setShow={setShow} />
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
