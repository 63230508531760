import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../images/logo.svg";
import Footer from "../../components/footer/Footer";
// import abut3 from "../../images1/influencer/pages/sevak-army-influencer-pages-1.jpg";
import abut3 from "../../images1/influencer/pages/abou-2.jpg";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageTitle from "../../components/pagetitle/PageTitle";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Cimg1 from "../../images1/influencer/pages/sevak-army-influencer-pages-5.jpg";
import Cimg2 from "../../images1/influencer/pages/sevak-army-influencer-pages-3.jpg";
import Cimg4 from "../../images1/influencer/pages/sevak-army-influencer-pages-4.png";
import Cimg3 from "../../images1/influencer/pages/sevak-army-influencer-pages-2.jpg";

import Cta1 from "../../images/cta/shape-1.png";
import Cta2 from "../../images/cta/top-icon.png";
import Cta3 from "../../images/cta/shape-2.png";

import Csimg1 from "../../images/cause-single/1.jpg";
import Csimg2 from "../../images/cause-single/2.jpg";
import Csimg3 from "../../images/cause-single/3.jpg";
import Csimg4 from "../../images/cause-single/4.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Instagram from "../../api/Instagram";

import TeamCard from "../../components/TeamSection/TeamCard";
import timg1 from "../../images1/team/sevak-army-team-10.jpg";
import timg2 from "../../images1/team/sevak-army-team-1.jpg";
import timg3 from "../../images1/team/sevak-army-team-8.jpg";
import timg4 from "../../images1/team/sevak-army-team-2.jpg";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ServiceCard from "../../components/ServiceSection/ServiceCard";

import tmImg1 from "../../images1/influencer/about-us/trustworthiness.png";
import tmImg2 from "../../images1/influencer/about-us/compassion.png";
import tmImg3 from "../../images1/influencer/about-us/sustainable-development.png";
import tmImg4 from "../../images1/influencer/about-us/impact.png";

// const Testimonial1 = [
//   {
//     id: "01",
//     text: "Sevak Army Influencer helped me use my platform for meaningful causes. Their team ensured the campaigns aligned with my values, making every collaboration deeply impactful. The feedback and real-world impact I've seen have been incredibly rewarding. It's more than just a partnership—it's a mission.",
//     title: "Ritika Sharma",
//     subtitle: "Social Media Influencer",
//     image: image1,
//   },
//   {
//     id: "02",
//     text: "Sevak Army Influencer helped us expand our healthcare reach and engage a wider, younger audience. Their coordination and authenticity ensured genuine representation, resulting in greater awareness and increased donations. We couldn’t have achieved this without them.",
//     title: "Sunita Rao",
//     subtitle: "Co-Founder, NGO",
//     image: image2,
//   },
//   {
//     id: "03",
//     text: "Sevak Army Influencer transformed our environmental campaign by authentically connecting with audiences. Their influencers boosted our social media engagement by 50%, improving brand awareness across platforms. It was a game-changing collaboration.",
//     subtitle: "Brand Partner",
//     title: "Rajesh Patel",
//     image: image3,
//   },
//   {
//     id: "04",
//     text: "Sevak Army Influencer connected me with causes that align with my values. Their support made content creation seamless and I love seeing the real-world impact my work generates.",
//     title: "Aarti Mehta",
//     subtitle: "Lifestyle Influencer",
//     image: image4,
//   },
//   {
//     id: "05",
//     text: "Sevak Army Influencer showed me the power of promoting change, not just products. Their wellness campaigns resonated with my audience and boosted both engagement and support for important causes.",
//     title: "Kabir Singh",
//     subtitle: "Fitness Influencer",
//     image: image5,
//   },
//   {
//     id: "06",
//     text: "Sevak Army Influencer connected us with passionate influencers who created authentic content. This increased engagement and sales while staying true to our sustainability mission.",
//     title: "Priya Desai",
//     subtitle: "CEO",
//     image: image6,
//   },
//   {
//     id: "06",
//     text: "Sevak Army Influencer’s authentic collaborations elevated our healthcare campaign. Their influencers created meaningful content that increased engagement and participation in our programs.",
//     title: "Ajay Verma",
//     subtitle: "CEO",
//     image: image7,
//   },
// ];

const Team = [
  {
    Id: "1",
    img: timg1,
    title: "Ananya Desai",
    subtitle: " Social Impact Advocate",
  },
  {
    Id: "2",
    img: timg2,
    title: "Kabir Mehta",
    subtitle: "Health and Wellness Influencer",
  },
  {
    Id: "3",
    img: timg3,
    title: "Riya Kapoor",
    subtitle: "Environmental Sustainability Enthusiast",
  },
  {
    Id: "4",
    img: timg4,
    title: "Arjun Malhotra",
    subtitle: " Education and Youth Empowerment Promoter",
  },
];

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const Testimonial = [
  {
    id: "01",
    img: tmImg1,
    text: "We believe in being honest and fair in everything we do. We make sure that our actions are transparent and that everyone involved in the Sevak Army community is treated with respect.",
    title: "Integrity",
  },
  {
    id: "02",
    img: tmImg2,
    text: "Compassion is very important to us. We focus on helping communities in need and encourage influencers to raise awareness about the issues that matter most to these groups.",
    title: "Compassion",
  },
  {
    id: "03",
    img: tmImg3,
    text: "We are committed to finding long-term solutions for big problems. We focus on education, healthcare, and protecting the environment to create positive change that lasts.",

    title: "Sustainability",
  },
  {
    id: "04",
    img: tmImg4,
    text: "We want to see real and measurable change. Every action taken by influencers and their followers aims to improve lives, find lasting solutions to social challenges, and inspire future generations.",
    title: "Impact",
  },
];

const InflunencerPages = (props) => {
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const causesData = [
    {
      id: 1,

      title: "#EducationForAll Campaign: Transforming Lives Through Education",

      description:
        "During the campaign, influencers partnered with education-focused NGOs to bring learning opportunities to underprivileged children in rural areas. The initiative provides scholarships, essential learning materials and mentorship to students who need access to quality education. Influencers shared inspiring stories of children overcoming challenges, motivating their audiences to support the cause. The campaign demonstrated how social influence can drive real-world change, especially in education.",
    },
    {
      id: 2,

      title:
        "#CleanAirForIndia Campaign: Influencers Unite for a Cleaner Tomorrow",

      description:
        "Sevak Army Influencer partnered with environmental advocates to raise awareness about air pollution in major Indian cities. The campaign reached a wide audience, sparking local actions like installing air quality monitoring systems. Influencers educated followers on simple ways to reduce pollution, inspiring community efforts for a cleaner environment. Media coverage further amplified the campaign, drawing attention from community leaders.",
    },
    {
      id: 3,

      title: "#EmpowerWomenNow Campaign: Uplifting Women, One Story at a Time",

      description:
        "This campaign focused on empowering women in underserved communities through vocational training and financial literacy programs. With the help of influencers, many women transformed their lives, gaining skills and knowledge for economic independence. Influencers shared inspiring stories of women’s journeys, motivating their audiences to support the cause. The success of the #EmpowerWomenNow campaign highlighted the powerful role of influencer storytelling in driving meaningful social impact.",
    },
  ];

  const causesData1 = [
    {
      id: 1,
      slug: "1",
      title: "Kids Education",
      campaign:
        "Campaign: Influencers share stories of children who have overcome adversity through education, showcasing how every contribution can make a tangible difference. This drives emotional engagement and action from audiences, helping us reach our goals.",
      image: Cimg1,
      Simage: Csimg1,
      raisedAmount: 8000,
      goalAmount: 10000,
      description:
        "Education is the cornerstone of empowerment. The SavakArmy Influencer programme brings together influencers who advocate for educational equity, raising awareness and funds to support scholarships, learning materials and mentorship programmes for underprivileged children. By collaborating with influencers, we amplify the voices of those fighting for educational opportunities and create impactful campaigns that inspire donors to contribute towards children’s futures. The funds raised provide necessary resources like books, uniforms, technology and access to quality teachers.",
    },
    {
      id: 2,
      slug: "2",
      title: "Pure Water",
      campaign:
        "Campaign: Using real-life examples of communities transformed by clean water access, influencers highlight clean water's health and economic benefits, making the case for continued support of water initiatives.",
      image: Cimg2,
      Simage: Csimg2,
      raisedAmount: 4000,
      goalAmount: 10000,
      description:
        "Access to clean water is a fundamental right, yet millions worldwide lack this basic necessity. Influencers in the SavakArmy programme are instrumental in promoting clean water initiatives. Their platforms help raise awareness of the critical importance of safe drinking water, particularly in vulnerable and underserved communities. Through social media campaigns and interactive engagement, influencers encourage their audience to actively contribute to the construction of wells, water filtration systems and sanitation education, helping to improve access to clean water.",
    },
    {
      id: 3,
      slug: "3",
      title: "Healthy Food",
      campaign:
        "Campaign: Influencers showcase before-and-after stories of children whose lives have been changed by access to nutritious food, demonstrating the value of sustained support for food programmes.",
      image: Cimg3,
      Simage: Csimg3,
      raisedAmount: 8692,
      goalAmount: 10000,
      description:
        "The role of nutrition in a child's physical and cognitive development cannot be overstated. The SavakArmy Influencer programme enlists influencers to support and promote nutrition programmes to provide healthy meals to underserved populations. Influencers use their platforms to emphasise the importance of food security and the direct impact it has on children’s ability to learn, grow and thrive. These campaigns encourage others to take steps to provide support for meal programs in schools and community centres, ensuring that children receive the nourishment they need.",
    },
    {
      id: 4,
      slug: "4",
      title: "Medical Care",
      campaign:
        "Campaign: Influencers document the delivery of medical supplies and healthcare services to remote villages, showcasing the real-world impact of these efforts and inspiring their audience to take meaningful action to support the cause",
      image: Cimg4,
      Simage: Csimg4,
      raisedAmount: 5000,
      goalAmount: 10000,
      description:
        "Healthcare is a critical need in many impoverished regions. SavakArmy passionately advocates for essential healthcare initiatives through partnerships with influencers, providing vital medical supplies, mobile clinics, and emergency care. Influencers spotlight health disparities and inspire their followers to support life-saving services for children and families who lack access to care, while effectively amplifying the message and mobilizing their audiences to make a tangible difference.",
    },
  ];

  const causesData2 = [
    {
      id: 1,
      slug: "1",
      title: "Our Mission",
      campaign: "",
      image: Cimg1,
      Simage: Csimg1,
      raisedAmount: 8000,
      goalAmount: 10000,
      description:
        "Our mission is to inspire and encourage individuals to join the Sevak Army, aiming to achieve 2 million service hours committed to making a significant social impact. By fostering a culture of giving back, we strive to create a platform that uplifts underserved communities and enriches the lives of our volunteers.",
    },
    {
      id: 2,
      slug: "2",
      title: "Our Vision",
      campaign: "",
      image: Cimg2,
      Simage: Csimg2,
      raisedAmount: 4000,
      goalAmount: 10000,
      description:
        "We envision a world where everyone is empowered to contribute to the greater good. Our mission is to build a strong volunteer network addressing social issues, promoting sustainability, and uplifting those in need. By harnessing the power of collective action, we aim to create lasting change and inspire future generations to carry this mission forward.",
    },
    {
      id: 3,
      slug: "3",
      title: "The Role of Influencers",
      campaign: "",
      image: Cimg3,
      Simage: Csimg3,
      raisedAmount: 8692,
      goalAmount: 10000,
      description:
        "Influencers play a crucial role in our mission. By using their platforms, they can inspire their followers to join the Sevak Army and take action. Influencers help spread the word about important causes, encourage people to volunteer, and support our message of social change. ",
    },
  ];

  // const settings1 = {
  //   dots: false,
  //   arrows: false,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   infinite: true,
  //   responsive: [
  //     {
  //       breakpoint: 1399,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1199,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <PageTitle pageTitle={"About Us"} pagesub={"About Us"} />
      <Scrollbar />
      <section className="wpo-about-section-s3 pb-0 pt-100">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  <div className="">
                    <img src={abut3} alt="about us" />
                  </div>
                  {/* <div className="shape">
                      <img src={abutShape3} alt="" />
                    </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="text-titleeee"> About Us</h2>
                  </div>
                  <p>
                    At Sevak Army, we are dedicated to empowering individuals to
                    make a positive difference in their communities through
                    volunteerism. Our platform connects passionate people with
                    meaningful volunteer opportunities, enabling them to
                    contribute to vital causes such as education, healthcare,
                    and sustainability. We believe that together, we can create
                    a brighter future for all.
                  </p>

                  {/* <div className="about-btn">
                    <Link to="/influencer/services" className="theme-btn" onClick={ClickHandler}>
                      Discover More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="wpo-about-section-s4 section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row just-fy-content-center align-items-center">
              <div className="col-lg-6 col-md-12  order-lg-1">
                <div className="wpo-about-text mt-0">
                  <div className="wpo-section-title mt-0">
                    <h2 className="text-titleeee mt-0">Our Mission</h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    Our mission is to inspire and encourage individuals to join the Sevak Army, aiming to achieve 2 million service hours committed to making a significant social impact. By fostering a culture of giving back, we strive to create a platform that uplifts underserved communities and enriches the lives of our volunteers.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title mt-0">
                    <h2 className="text-titleeee">Our Vision</h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    We envision a world where everyone is empowered to contribute to the greater good. Our efforts aim to build a strong network of volunteers who work together to address pressing social issues, promote sustainability, and uplift those in need. We can create lasting change and inspire future generations to continue this mission by harnessing the power of collective action.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="container section-padding pt-100">
        <div className="">
          <section className={"wpo-fun-fact-section-s3 pb-0"}>
            <div className="container">
              {/* <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-12">
                  <SectionTitle subtitle="Causes" title="Communities Thrive with Your Support Today." />
                </div>
              </div> */}
              <div className="service-wrap">
                <div className="row justify-content-center">
                  {causesData2.map((Service, sitem) => (
                    <div
                      className="col col-lg-4 col-md-8 col-sm-6 col-12"
                      key={sitem}
                    >
                      <div
                        className="service-item px-4 sevak-custom-carddd"
                        style={{ minHeight: "400px" }}
                      >
                        {/* <div className="icon">
                            <img src={img} width={60} alt="img" />
                          </div> */}
                        <div className="text">
                          <h2
                            className="fw-bold mt-0"
                            style={{ fontSize: "30px" }}
                          >
                            {Service.title}
                          </h2>
                          <p
                            className="service-paragraph volunteer-home-text text-center pt-3"
                            style={{ textAlign: "justify", fontSize: "26px" }}
                          >
                            {Service.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* <section className={"section-padding pt-0"}>
        <div className="container">
          <SectionTitle subtitle={"Values"} title="Our Core Values" />
          <div className="wpo-testimonial-slider">
            <Slider {...settings}>
              {Testimonial.map((Testimonial, item) => (
                <div className="testimonial-item" key={item}>
                  <div className="content" style={{ minHeight: 520 }}>
                    <div className="text">
                      <h2 style={{ textAlign: "center", fontSize: "28px", fontWeight: "bold" }}>{Testimonial.title}</h2>
                    </div>
                    <p style={{ fontSize: '20px' }}>{Testimonial.text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section> */}

      <section className="wpo-about-section section-padding pt-0">
        <div className="container">
          <div className="service-wrap">
            <SectionTitle title="Our Values" paragarap="" />
            <div className="row justify-content-center mt-5">
              {Testimonial.slice(0, 10).map((Service, sitem) => (
                <div
                  className="col col-xl-3 col-lg-5 col-md-6 col-sm-8 col-8"
                  key={sitem}
                >
                  <div className="service-card-wrapper d-flex flex-column h-100">
                    <ServiceCard
                      height="453px"
                      title={Service.title}
                      img={Service.img}
                      slug={Service.slug}
                      sdescription={Service.text}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* below Section not in requirement */}
      {/* section start */}

      {/* <section className="wpo-instagram-section s2 section-padding pt-0">
        <div className="container">
          <div className="instagram-wrap">
            <div className="wpo-section-title">
              <span>Gallery</span>
              <h2 className="about-heading-third">Our Gallery</h2>
            </div>
            <div className="row">
              {Instagram.map((item) => (
                <div className="col col-lg-4 col-md-6 col-12" key={item.id}>
                  <div className="instagram-card">
                    <div className="image">
                      <Link
                        to={item.imag}
                        className="fancybox"
                        data-fancybox-group="gall-1"
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(item.imag);
                        }}
                      >
                        <img
                          src={item.imag}
                          alt={`Instagram ${item.id}`}
                          className="img img-responsive"
                        />
                        <div className="popup-icon">
                          <i className="ti-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="text">
                      <span>{item.title}</span>
                      <h2>{item.subtitle}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {modalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-wrap">
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage} alt="Modal" />
              </div>
            </div>
          </div>
        )}
      </section> */}

      {/* section end */}

      {/* <section className={"wpo-testimonial-section pt-0 mt-0"}>
        <div className="causes-wrap pt-0 mt-0">
          <div className="container-fluid">
            <SectionTitle title="Communities Thrive with Your Support Today." subtitle="Causes" />
            <div className="row">
              {causesData.slice(0, 3).map((cause, cky) => (
                <div className="col col-lg-4 col-md-6 col-12" key={cky}>
                  <div className="causes-item">
                    <div className="content" style={{ minHeight: 540 }}>
                      <h2>{cause.title}</h2>
                      <p>{cause.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      {/* below Section not in requirement */}
      {/* section start */}
      {/* 
      <section className="container section-padding pt-0">
        <div className="mt-3">
          <section className={"wpo-fun-fact-section-s3 pb-0"}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-12">
                  <SectionTitle
                    subtitle="Causes"
                    title="Communities Thrive with Your Support Today."
                  />
                </div>
              </div>
              <div className="service-wrap">
                <div className="row justify-content-center">
                  {causesData.map((Service, sitem) => (
                    <div
                      className="col col-2xl-4 col-xl-4 col-lg-6 col-md-7 col-sm-10 col-12"
                      key={sitem}
                    >
                      <div
                        className="service-item px-4 sevak-custom-carddd"
                        // style={{ height: "650px" }}
                      >
                        // <div className="icon">
                          //  <img src={img} width={60} alt="img" />
                    //       </div>
                        <div className="text">
                          <h2 className="services-title-text fw-bold mt-0">
                            {Service.title}
                          </h2>
                          <p
                            className="service-paragraph volunteer-home-text text-center pt-3"
                            style={{ textAlign: "justify" }}
                          >
                            {Service.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section> */}

      {/* section end */}

      <section className="wpo-cta-section">
        <div className="shape-1">
          <img src={Cta1} alt="" />
        </div>
        <div className="bg-overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  {/* <div className="icon">
                    <img src={Cta2} alt="" />
                  </div> */}
                  <h2> Join Us in Creating Success Stories </h2>
                  <span style={{ fontSize: "20px" }}>
                    At the Sevak Army, influencer involvement goes beyond
                    raising awareness -it delivers real impact. As an
                    influencer, you have the power to inspire change. Encourage
                    your followers to join the Sevak Army and help drive
                    meaningful social work. Together, we can support NGOs,
                    empower communities, and amplify causes that matter. Whether
                    contributing to education, the environment, or women’s
                    empowerment, the Sevak Army offers the platform, resources,
                    and community to make a difference. Invite your followers to
                    subscribe and start making the world a better place today.
                  </span>

                  {/* <Link to="/register" onClick={ClickHandler} className="theme-btn-cta">
                    Subscribe now
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-2">
          <img src={Cta3} alt="" />
        </div>
      </section>

      {/* below Section not in requirement */}
      {/* section start */}
      {/* <section
        style={{ marginTop: "100px", marginBottom: "100px" }}
        className={props.hclass}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                subtitle="Volunteer"
                title="Meet our expert volunteer team."
              />
            </div>
          </div>
          <div className="volunteer-wraper">
            <div className="row">
              {Team.slice(0, 4).map((teams, tky) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={tky}>
                  <TeamCard
                    Tsubtitle={teams.subtitle}
                    Timg={teams.img}
                    Ttitle={teams.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      {/* section end */}

      {/* <section className="wpo-causes-section-s2 section-padding">
        <div className="causes-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle subtitle="Services" title="Your Influence Sparks Lifelong Change." />
              </div>
            </div>
            <div className="row g-0 align-items-center mt-4">
              <div className="w-100 d-flex flex-wrap">
                {causesData1.slice(0, 4).map((cause, index) => (
                  <div key={index} className="col col-lg-6 col-md-6 col-12">
                    <div className="causes-item mb-4" >
                      <div className="image">
                        <img src={cause.image} alt={cause.title} />
                      </div>
                      <div className="content">
                        <h2>{cause.title}</h2>
                        <p>{cause.description}</p>
                        <p>{cause.campaign}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
};

export default InflunencerPages;
