


const SectionTitle = ({ title, subtitle, paragarap }) => {

    return (
        <div className="wpo-section-title mb-0 pt-0" style={{maxWidth:'860px'}}>
            <span className="text-center pt-0" style={{textTransform:'none'}}>{subtitle}</span>
            <h2 className="about-heading-second text-smallll w-100 mt-0 pt-2" style={{maxWidth:'860px'}}>{title}</h2>
            <div className="d-flex w-100 justify-content-center">
            <p className="text-center mt-2">{paragarap}</p>
            </div>
        </div>
    )
}

export default SectionTitle;

