import React, { Fragment } from "react";
import About1 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-3.jpg";
import Collaboration from '../../images1/ngo/home/collab.png'
import Simplicity from '../../images1/ngo/home/like.png'
import Trust from '../../images1/ngo/home/trust.png'
import Impact from '../../images1/ngo/home/impact.png'
import Growth from '../../images1/ngo/home/graph.png'
import SectionTitle from "../SectionTitle/SectionTitle";

const featuresData2 = [
  {
    // iconClass: 'flaticon-volunteer',
    svg: Collaboration,
    title: 'Collaboration',
    description: 'Bringing NGOs and volunteers together to achieve more.'
  },
  {
    // iconClass: 'flaticon-volunteer',
    svg: Simplicity,
    title: 'Simplicity',
    description: 'Making it easy for NGOs to find the right help.'
  },
  {
    // iconClass: 'flaticon-volunteer',
    svg: Trust,
    title: 'Trust',
    description: 'Ensuring all connections are reliable and trustworthy.'
  },
  {
    // iconClass: 'flaticon-volunteer',
    svg: Impact,
    title: 'Community Impact',
    description: 'Creating positive change in local communities across the nation.'
  },
  {
    // iconClass: 'flaticon-volunteer',
    svg: Growth,
    title: 'Growth',
    description: 'Helping NGOs grow by providing skilled volunteers.'
  },
];

function NgoAboutFirstSection() {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };
  const featuresData = [
    {
      iconClass: "flaticon-volunteer",
      title: "Our Mission",
      description: (
        <>
          Our mission is to support NGOs by connecting them with the resources they need. Sevak Army simplifies the process, making it easier for NGOs to succeed in their initiatives.
        </>
      ),
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Our Vision",
      description: (
        <>
          By 2025, Sevak Army aims to be the platform of choice for NGOs across India, helping them achieve 2 million hours of impactful projects. We strive to empower NGOs to build stronger, more connected communities, creating a brighter future for all.
        </>
      ),
    },
    // {
    //   iconClass: "flaticon-charity",
    //   title: "Our Values",
    //   description: (
    //     <>
    //       <strong>Collaboration:</strong> Bringing NGOs and volunteers together to achieve more.
    //       <br />
    //       <strong>Simplicity:</strong> Making it easy for NGOs to find the right help.
    //       <br />
    //       <strong>Trust:</strong>  Ensuring all connections are reliable and trustworthy.
    //       <br />
    //       <strong>Community Impact:</strong> Creating positive change in local communities across the nation.
    //       <br />
    //       <strong>Growth:</strong>  Helping NGOs grow by providing skilled volunteers.
    //     </>
    //   ),
    // },
  ];


  return (
    <Fragment>
      <section className="wpo-about-section mb-0 mt-5 pb-0 pt-5">
        <div className="container ">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center">
              <div className="">
                <div className="wpo-about-img">
                  <div className="bg-image">
                    <img src={About1} width={650} alt="" />
                  </div>
                  {/* <div className="img-1">
                    <img width={230} className="img-fluid" src={About2} alt="" />
                  </div> */}
                  {/* <div className="img-2">
                    <img width={230} className="img-fluid" src={About3} alt="" />
                  </div> */}
                  {/* <div className="img-3">
                    <img width={230} className="img-fluid" src={About4} alt="" />
                  </div> */}
                  {/* <div className="img-4">
                  <img width={230} className="img-fluid" src={About4} alt="" />
                  </div> */}
                  {/* <div className="img-5">
                    <img width={230} className="img-fluid" src={About6} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="text-titleeee">About Us</h2>
                  </div>
                  <p className="volunteer-home-text">
                    Sevak Army is a platform that connects NGOs with volunteers from all walks of life. We make it simple for NGOs to find passionate and skilled volunteers who can help with various tasks, from teaching to organising events and more. We aim to create a space where NGOs can easily find the right volunteers for their needs. Our goal is to solve the challenges NGOs face in recruiting committed volunteers and to provide volunteers with diverse opportunities to use their skills meaningfully.
                  </p>

                  {/* <div className="about-info-wrap">
                    <div className="about-info-item">
                      <div className="about-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-target"></i>
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Mission</h4>
                        <p>We believe charity is a lifetime investment.</p>
                      </div>
                    </div>
                    <div className="about-info-item">
                      <div className="about-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-mountain"></i>
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Vision</h4>
                        <p>We believe charity is a lifetime investment.</p>
                      </div>
                    </div>
                  </div>

                  <Link
                    onClick={ClickHandler}
                    to="/about"
                    className="theme-btn"
                  >
                    Discover More
                  </Link> */}
                </div>
              </div>
            </div>
            <section className="wpo-features-area">
              <div className="features-wrap">
                <div className="row justify-content-center">
                  {featuresData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-8 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div className="feature-item" style={{ height: '237px' }}>
                          <div className="feature-text">
                            <h2 className=" fw-bold">{feature.title}</h2>
                            <p className="fs-6 p-2" style={{ textAlign: "justify" }}>
                              {feature.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <section className="pt-100">
              <div className='container'>
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-12 col-12">
                    <SectionTitle subtitle="Our Values" title="Our Pillars of Impact" />
                  </div>
                </div>

                <div className="wpo-features-area pt-3">
                  <div className="features-wrap">
                    {/* <p className="pb-2 w-100" style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>Connect with Skilled Volunteers and Grow Your Impact </p> */}
                    <div className="row justify-content-center align-items-start column-gappp">
                      {featuresData2.map((feature, index) => (
                        <div className="col col-lg-4 col-md-6 col-12" key={index}>
                          <div className="feature-item-wrap">
                            <div className="feature-item d-flex align-items-start" style={{ height: '158px' }}>
                              <div className="feature-icon">
                                <div className="icon">
                                  {feature.iconClass ? <i className={`fi ${feature.iconClass}`}></i> : <img src={feature.svg} alt={feature.svg} />}
                                </div>
                              </div>
                              <div className="feature-text">
                                <h2 className="fs-5 mb-0">{feature.title}</h2>
                                <p className="fs-6" style={{ textAlign: 'justify' }}>{feature.description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default NgoAboutFirstSection;
