import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import icon from "../../images/cta/top-icon.png";
import Path from "../../images1/volunteer/home/img.jpg";
import Path2 from "../../images1/school-college/home/inspirestud.png";
import VolunteerOpportunities from "../../images1/volunteer/home/task.png";
import Opportunities from "../../images1/volunteer/home/equal-rights.png";
import Volunterring from "../../images1/volunteer/home/volunterring.png";
import Review from "../../images1/volunteer/home/review.png";
import United from "../../images1/volunteer/home/united.png";
import SignUp from "../../images1/volunteer/home/register.jpg";
import BrowseTask from "../../images1/volunteer/home/BrowseTask.png";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import NewsletterPopup from "../../components/NewsletterPopup";
import ShareLink from "../../images1/volunteer/home/link.png";
import Reccogn from "../../images1/school-college/home/award-transformed.jpeg";

const featuresData = [
  {
    // iconClass: "flaticon-volunteer",
    svg: Opportunities,
    title: "Variety of Opportunities",
    description:
      "Bring your followers into a mission-driven community and empower them to contribute to meaningful causes.",
  },
  {
    // iconClass: "flaticon-charity",
    title: "Flexible Commitments",
    svg: Volunterring,
    description:
      "Students can find tasks that fit seamlessly with their schedules.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: United,
    title: "Skills Development",
    description:
      "Students acquire leadership, teamwork, and social responsibility skills, gaining real-world experience.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: United,
    title: "Institutional Recognition",
    description:
      "Track, celebrate, and reward students’ contributions to the community.",
  },
];

const BecomeInfluencerPage = (props) => {
  const [shownewsletter, setShowNewsletter] = useState(false);
  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-3">
                    <img src={Path2} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    <span>Empowering Schools to Champion Community Impact</span>
                    <h2 className="about-heading-second text-titleeee">
                      Inspire Service in Students
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Empower your institution to encourage community impact
                    through student volunteerism. Sevak Army offers a seamless
                    way for schools and colleges to guide students towards
                    meaningful social contributions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container d-flex flex-column justify-content">
          <div className="wpo-event-details-text pt-0 mt-0">
            <SectionTitle
              subtitle=""
              title="How It Works: From Registration to Engagement"
            />
            {/* <div className="w-100 d-flex justify-content-center pt-0 mt-0">
                       <p className="text-center col-lg-8 col-md-10 col-12">As an influencer, you play a vital role in bringing your followers into a community dedicated to making a difference. Here’s how you can easily join Sevak Army, inspire others, and create a positive impact together:</p>
                       </div> */}
          </div>
        </div>

        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 1: Register Your Institution</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Sign up your school or college with Sevak Army. With a
                    registered account, institutions can encourage students to
                    join tasks aligned with their interests, abilities, and
                    availability.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-4">
                    {/* <img src={abImg7} alt="img" /> */}
                    <img width={500} src={SignUp} alt="Sign Up" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-5">
                    {/* <img src={abImg7} alt="img" /> */}
                    <img width={500} src={ShareLink} alt="Sign Up" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-5">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 2: Share a Unique Link with Students</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Upon registration, each institution receives a personalised
                    referral link to share with students. This link makes it
                    easy for students to create their own profiles on Sevak Army
                    and explore a range of volunteer tasks.
                    {/* <ul className="list-disc list-inside">
                                            <li>
                                                <p className="mb-0">  <b>Skills : </b> Whether you’re experienced in teaching, event planning, or logistics, there’s a task for you.</p>
                                            </li>
                                            <li>
                                                <p className="mb-0"> <b>Interests : </b> Passionate about the environment? Love helping in education? Filter tasks to suit your passion.</p>
                                            </li>
                                            <li>
                                                <p className="mb-0"><b>Time Availability : </b> Whether you have a few hours or want a regular commitment, choose tasks that fit your schedule.</p>
                                            </li>
                                        </ul> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 3: Explore Volunteering Tasks</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Students can use filters to find roles that align with
                    their:
                    <ul className="list-disc list-inside">
                      <li>
                        <p className="mb-0">
                          {" "}
                          <b>Skills : </b> From tutoring to event planning,
                          students can match their abilities to the right tasks.
                        </p>
                      </li>
                      <li>
                        <p className="mb-0">
                          {" "}
                          <b>Interests : </b> Projects span education, the
                          environment, health, and more, letting students pursue
                          areas they’re passionate about.
                        </p>
                      </li>
                      <li>
                        <p className="mb-0">
                          <b>Availability : </b> Flexible opportunities range
                          from short-term projects to ongoing commitments that
                          fit around academic schedules.
                        </p>
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-lg-5">
                    <img
                      src={VolunteerOpportunities}
                      width={500}
                      alt="Volunteer Opportunities"
                    />
                    {/* <img src="https://placehold.co/324x324/png" alt="Sign Up" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img d-flex w-100 justify-content-center">
                  <div className="wpo-about-right pt-3">
                    <img src={Reccogn} width={500} alt="Review" />
                    {/* <img src="https://placehold.co/324x324/png" alt="Sign Up" /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                  <div className="custom-panell">
                    <h3>Step 4: Gain Recognition and Rewards</h3>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Each contribution counts! Students earn points, e-badges,
                    and e-certificates as they volunteer, building a portfolio
                    of achievements that enhance personal and professional
                    growth. The leaderboard feature keeps students motivated to
                    engage more actively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 container pb-5">
        <div className="row justify-content-center">
          {/* <div className="col-lg-6 col-md-12 col-12">
                        <SectionTitle subtitle="Serve with Impact" />
                    </div> */}
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle title="Why Register with Sevak Army?" />
          </div>
        </div>

        <div className="wpo-features-area pt-3">
          <div className="features-wrap">
            <div className="row justify-content-center column-gappp">
              {featuresData.map((feature, index) => (
                <div
                  className="col col-xl-5 col-lg-5 col-md-6 col-12"
                  key={index}
                  style={{}}
                >
                  <div
                    className="feature-item-wrap"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <div
                      className="feature-item px-3 mb-0"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "460px",
                        height: "190px",
                      }}
                    >
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <div className="feature-icon">
                          <div className="icon">
                            {feature.iconClass ? (
                              <i className={`fi ${feature.iconClass}`}></i>
                            ) : (
                              <img src={feature.svg} alt={feature.svg} />
                            )}
                          </div>
                        </div>
                        <div className="feature-text">
                          <h2 className="fs-5">{feature.title}</h2>
                          <p className="fs-6" style={{ textAlign: "justify" }}>
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    {/* <span>Join Sevak Army</span> */}
                    <h2>Empower Your Students Today!</h2>
                    <p
                      className="px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Register your institution with Sevak Army and create a
                      culture of service that shapes responsible and engaged
                      citizens.
                    </p>
                    <Link
                      onClick={() => setShowNewsletter(true)}
                      to="#"
                      className="theme-btn"
                    >
                      Get Started Today
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomeInfluencerPage;
