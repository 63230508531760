import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import Honour from "../../images1/volunteer/home/honour.png";
import Certificates from "../../images1/volunteer/home/certificates.png";
import Stories from "../../images1/volunteer/home/stories.png";
import Networking from "../../images1/volunteer/home/networking.png";
import MeetNetwork from "../../images1/volunteer/home/meet-network.png";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import LeaderboardWorking from "../../images1/school-college/events/work.png";
import DCertificate from "../../images1/school-college/events/certi.png";
import Growth from "../../images1/volunteer/home/growth.png";
import Boost from "../../images1/volunteer/home/boost.png";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import ServiceCard from "../../components/ServiceSection/ServiceCard";
import icon from "../../images/cta/top-icon.png";

import Cimg from "../../images/service-single/award.png";

// const Services = [
//     {
//         Id: '01',
//         sImg: simg1,
//         sSImg: sSimg1,
//         title: 'Track Your Progress in Real Time',
//         slug: '1',
//         description: 'Each volunteer can log in to their profile and view their standing on the leaderboard. You will also receive regular email updates showing your rank and progress. These updates keep you motivated and informed as you continue contributing your time and skills to meaningful causes.',
//     },
//     {
//         Id: '02',
//         sImg: simg2,
//         sSImg: sSimg2,
//         title: 'Boost Your Rank with Referrals',
//         slug: '2',
//         description: "Help grow the Sevak Army by referring others to the platform. You will earn additional points that boost your position on the leaderboard for every person who registers as a volunteer through your referral link. It's a great way to encourage others to get involved while increasing your own impact.",
//     },
//     {
//         Id: '03',
//         sImg: simg3,
//         sSImg: sSimg3,
//         title: 'Why the Leaderboard Matters?',
//         slug: '3',
//         description: 'The Sevak Army Leaderboard isn’t just about competition - it’s about celebrating your dedication and contribution to social good. The more you volunteer, the higher you climb, and the more recognition you earn for your efforts. Plus, it helps you set personal goals and stay motivated as you serve your community.'
//     },
//     {
//         Id: '04',
//         sImg: simg4,
//         sSImg: sSimg4,
//         title: 'Earn Badges and Certificates',
//         slug: '4',
//         description: 'As you rise through the leaderboard, you’ll unlock e-badges and receive e-certificates for your achievements. These milestones recognise your commitment to volunteerism and can be shared on social media or added to your CV, showcasing your dedication to making a positive impact.',
//     },
//     {
//         Id: '05',
//         sImg: simg4,
//         sSImg: sSimg4,
//         title: 'Stay Motivated and Keep Giving Back',
//         slug: '4',
//         description: 'Whether aiming for the top spot or just wanting to track your volunteer hours, the Sevak Army Leaderboard keeps you engaged and inspired. It’s more than just points—it’s about building a legacy of service and making a difference, one task at a time.',
//     },
// ]

const featuresData = [
  {
    // iconClass: "flaticon-volunteer",
    svg: Honour,
    // title: "Honour Top Volunteers",
    description:
      "Share on LinkedIn, your website, and other platforms to showcase your commitment to CSR.",
  },
  {
    // iconClass: "flaticon-charity",
    svg: Certificates,
    // title: "Present Awards and Certificates",
    description:
      "Highlight in CSR reports and presentations as part of your social impact strategy.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: Stories,
    // title: "Share Success Stories",
    description:
      "Use it as a tool to motivate employees by recognising their contributions to a greater cause.",
  },
];

const Services2 = [
  {
    Id: "01",
    sImg: Growth,
    sSImg: sSimg1,
    title: "CSR Participation Certificate",
    slug: "1",
    description:
      "Receive a digital certificate celebrating your organisation’s active participation in volunteer initiatives. This certificate acknowledges your commitment to social responsibility.",
  },
  {
    Id: "02",
    sImg: Boost,
    sSImg: sSimg2,
    title: "Milestone Achievements",
    slug: "2",
    description:
      "Unlock special milestone certificates and badges as your organisation reaches significant volunteer hours or completes a set number of tasks. Recognise these achievements as a testament to your team’s dedication.",
  },
  {
    Id: "03",
    sImg: Cimg,
    sSImg: sSimg2,
    title: "Top Contributor Awards",
    slug: "2",
    description:
      "Organisations with high volunteer engagement will be featured on our Leaderboard. Top Contributor Awards honour the organisations that lead in volunteer hours and participation, giving you visibility and recognition.",
  },
];

const OrganizationRecognitionPage = (props) => {
  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5 mt-3">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img width={580} src={DCertificate} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3 pb-0">
                    {/* <div className="custom-panell"> */}
                    <span className="">
                      Rewards and Recognition for Organisations
                    </span>
                    <h2 className="about-heading-second text-titleeee mb-0 pb-0">
                      Celebrate Your Organisation’s Impact with Digital
                      Certificates and Awards
                    </h2>
                    {/* </div> */}
                  </div>
                  {/* <h3 className="mt-0">
                    How the Sevak Army Influencer Leaderboard Works?
                  </h3> */}
                  <p style={{ textAlign: "justify" }}>
                    At Sevak Army, we understand the importance of recognising
                    your organisation’s dedication to Corporate Social
                    Responsibility (CSR). By encouraging your employees to
                    volunteer, you not only support meaningful causes but also
                    showcase your organisation’s commitment to making a positive
                    impact. That’s why we offer a range of digital certificates
                    and awards to celebrate your organisation’s achievements in
                    driving social change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section pt-0 mt-0">
        <div className="container">
          <div className="wpo-about-wrap mt-0">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3 pb-0">
                    <h2 className="about-heading-second text-titleeee mb-0 pb-0">
                      How It Works?
                    </h2>
                    {/* </div> */}
                  </div>
                  {/* <h3 className="mt-0">
                    How the Sevak Army Influencer Leaderboard Works?
                  </h3> */}
                  <p style={{ textAlign: "justify" }}>
                    Organisations registering with Sevak Army can earn digital
                    certificates and awards based on the collective volunteer
                    efforts of their employees. Every task completed by your
                    team contributes to your CSR impact, and these recognitions
                    highlight your organisation’s role in fostering a culture of
                    giving back.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-3">
                    <img width={580} src={LeaderboardWorking} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle title="Types of Digital Certificates and Awards" />
            </div>
          </div>
          <div className="service-wrap">
            <div className="row justify-content-center column-gappp pt-3">
              {Services2.slice(0, 19).map((Service, sitem) => (
                <div
                  className="col col-lg-4 col-md-8 col-sm-6 col-12"
                  key={sitem}
                >
                  <div className="service-card-wrapper d-flex flex-column h-100">
                    <ServiceCard
                      width="313px"
                      height="400px"
                      title={Service.title}
                      img={Service.sImg}
                      slug={Service.slug}
                      sdescription={Service.description}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10 col-md-12 col-12">
                            <p className="text-center" style={{ fontWeight: '500' }}>Join the Leaderboard today and help your organisation make a positive impact.</p>
                        </div>
                    </div> */}
        </div>
      </section>

      <section className="container section-padding pt-0">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12">
            <SectionTitle
              title="Showcase Your Organisation’s Achievements"
              paragarap="Every digital certificate and award can be downloaded directly from the Sevak Army platform, allowing your organisation to"
            />
          </div>
        </div>

        <div className="wpo-features-area pt-0 px-2">
          <div className="features-wrap">
            <div className="row justify-content-center column-gappp">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div
                      className="feature-item  align-items-center"
                      style={{ alignItems: "center" }}
                    >
                      <div className="feature-icon">
                        <div className="icon">
                          {feature.iconClass ? (
                            <i className={`fi ${feature.iconClass}`}></i>
                          ) : (
                            <img src={feature.svg} />
                          )}
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p
                          className="fs-6"
                          style={{ textAlign: "justify", fontSize: "21px" }}
                        >
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding pt-0">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2 mb-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>
                      Join the Leaderboard and Earn Your First Digital
                      Certificate!
                    </span>
                    <p className="text-center text-white pt-3">
                      The more you volunteer, the more recognition you’ll earn.
                      Track your organisation’s progress on the [Sevak Army
                      Leaderboard], climb the ranks, and start collecting your
                      digital certificates today.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100 mt-3">
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Encourage Your Team to Volunteer and Earn Recognition!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrganizationRecognitionPage;
