import pimg1 from '../images1/volunteer/home/sevak-army-volunteer-home-3.jpg'
import pimg2 from '../images1/volunteer/home/sevak-army-volunteer-home-4.jpg'
import pimg3 from '../images1/volunteer/home/sevak-army-volunteer-home-5.jpg'
import psimg1 from '../images1/volunteer/home/sevak-army-volunteer-home-3.jpg'
import psimg2 from '../images1/volunteer/home/sevak-army-volunteer-home-4.jpg'
import psimg3 from '../images1/volunteer/home/sevak-army-volunteer-home-5.jpg'

const volunteerUpcomingEvents = [
    {
        id: '1',
        title: 'Blood Donation Camp',
        slug: '1',
        pimg: pimg1,
        psimg: psimg1,
        date: "25th",
        month: "Sept"
    },
    {
        id: '2',
        title: 'Community Food Drive',
        slug: '2',
        pimg: pimg2,
        psimg: psimg2,
        date: "1st",
        month: "Oct"
    },
    {
        id: '3',
        title: 'Cleanliness Drive in Mumbai',
        slug: '3',
        pimg: pimg3,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },


]
export default volunteerUpcomingEvents;