import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import About2 from "../../images1/volunteer/about-us/points.jpg";

import About3 from "../../images1/volunteer/about-us/recognition.jpg";
import Referalimg from "../../images1/school-college/home/referal.jpg";
import Compimg from "../../images1/school-college/home/competition.jpg";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import abImg7 from "../../images1/school-college/home/leader-2.jpg";

import LeaderboardWorking from "../../images1/school-college/home/leaderboard.jpg";
import Growth from "../../images1/volunteer/home/growth.png";
import Boost from "../../images1/volunteer/home/boost.png";
import Podium from "../../images1/volunteer/home/podium.png";
import Certification from "../../images1/volunteer/home/certification.png";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Opportunities from "../../images1/volunteer/home/correct.png";
import Award from "../../images/service/benefit.png";
import HelpingNgos from "../../images1/volunteer/home/helping-ngos.png";
import ServiceCard from "../../components/ServiceSection/ServiceCard";
import lImg1 from "../../images1/influencer/leaderboard/motivation.png";
import lImg2 from "../../images1/influencer/leaderboard/influencer.png";
import Img1 from "../../images/service-single/awareness.png";
import Img2 from "../../images/service-single/social-support.png";
import Img3 from "../../images/service-single/school.png";
import icon from "../../images/cta/top-icon.png";

const Services = [
  {
    Id: "01",
    sImg: Growth,
    sSImg: sSimg1,
    title: "Track Your Progress in Real Time",
    slug: "1",
    description:
      "Each influencer can log in to their profile and view their standing on the leaderboard. You will also receive regular email updates showing your rank and progress. These updates keep you motivated and informed as you inspire your followers to join the Sevak Army and make an impact.",
  },
  {
    Id: "02",
    sImg: Boost,
    sSImg: sSimg2,
    title: "Boost Your Rank with Referrals",
    slug: "2",
    description:
      "Help grow the Sevak Army community by inviting your followers to join. Each follower who signs up using your referral link will add to your points, helping you climb higher on the leaderboard.",
  },
  {
    Id: "03",
    sImg: Podium,
    sSImg: sSimg3,
    title: "Why Does the Leaderboard Matters?",
    slug: "3",
    description:
      "The Sevak Army Influencer Leaderboard is more than competition—it celebrates your commitment to social good. The more volunteers you inspire, the higher you climb, earning greater recognition. It also helps you set goals and stay motivated as you work toward a better world.",
  },
  {
    Id: "04",
    sImg: Certification,
    sSImg: sSimg4,
    title: "Earn Digital Badges and Certificates",
    slug: "4",
    description:
      "As you climb the leaderboard, you’ll unlock digital badges and certificates for your achievements. These milestones showcase your leadership in social impact and can be shared on social media or added to your portfolio, highlighting your dedication to meaningful causes.",
  },
  {
    Id: "05",
    sImg: lImg1,
    sSImg: sSimg4,
    title: "Stay Motivated and Keep Inspiring Change",
    slug: "4",
    description:
      "Whether aiming for the top spot or simply wanting to track your success in inspiring others, the Sevak Army Influencer Leaderboard keeps you engaged and driven. It’s more than just points - it’s about building a legacy of social change, one follower at a time.",
  },
  {
    Id: "06",
    sImg: lImg2,
    sSImg: sSimg4,
    title: "Join the Influencer Leaderboard Today",
    slug: "4",
    description:
      "Become a Sevak Army influencer and earn points for every follower you inspire to volunteer. Track your progress, climb the ranks, and gain recognition for your role in making a positive impact.",
  },
];

const Services2 = [
  {
    Id: "01",
    sImg: Growth,
    sSImg: sSimg1,
    title: "Student Leaderboard",
    slug: "1",
    description:
      "This ranks individual student volunteers based on their contribution. Whether it's organising events, tutoring, or participating in community service projects, students can see how their efforts stack up against their peers.",
  },
  {
    Id: "02",
    sImg: Boost,
    sSImg: sSimg2,
    title: "School/College Leaderboard",
    slug: "2",
    description:
      "Institutions are ranked based on the collective efforts of their students. This leaderboard encourages schools and colleges to engage more students in volunteer work, offering an opportunity for educational institutions to take pride in their community contributions.",
  },
  {
    Id: "03",
    sImg: Boost,
    sSImg: sSimg2,
    title: "Leaderboard",
    slug: "2",
    description:
      "Schools and colleges can also nominate influential students or ambassadors who inspire others to join the platform. Points are awarded for every student they bring to the Sevak Army platform. Influencers can share referral links, and each successful registration through their link adds points to their tally.",
  },
];

const featuresData = [
  {
    iconClass: "flaticon-volunteer",
    title: "Points for Participation",
    img: About2,
    description:
      "Every task that a student completes as a volunteer earns them points. Different tasks may carry different point values based on their complexity or time commitment. For example, a short-term task might earn fewer points compared to a long-term project or a more complex challenge.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Recognition for Performance",
    img: About3,
    description:
      "Students and Institutions who top the leaderboard are publicly recognised for their efforts, and schools/colleges can also receive collective recognition if their students perform exceptionally well. This fosters a culture of service and achievement among students and institutions alike.",
  },
];

const featuresData1 = [
  {
    iconClass: "flaticon-volunteer",
    img: Img1,
    title: "Weekly Leaderboard",
    description:
      "Provides short-term motivation for students and schools to perform well and gain immediate recognition.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Monthly Leaderboard",
    img: Img2,
    description:
      "Recognises consistent contributions over a longer period, offering a mid-term goal for students to aim for.",
  },
  {
    iconClass: "flaticon-charity",
    img: Img3,
    title: "Yearly Leaderboard",
    description:
      "Honors top volunteers and institutions for their year-long contributions, representing the highest recognition in the Sevak Army community.",
  },
];

const weeklyLeaderboard = [
  {
    rank: 1,
    name: "Aarav Sharma",
    hours: 15,
    contributions: 3,
    lastContribution: "2024-10-14 09:30",
    badge: "Gold",
  },
  {
    rank: 2,
    name: "Vihaan Patel",
    hours: 12,
    contributions: 2,
    lastContribution: "2024-10-13 16:20",
    badge: "Silver",
  },
  {
    rank: 3,
    name: "Isha Gupta",
    hours: 10,
    contributions: 1,
    lastContribution: "2024-10-12 11:15",
    badge: "Bronze",
  },
].map((item) => ({
  ...item,
  badge: item.hours >= 15 ? "Gold" : item.hours >= 12 ? "Silver" : "Bronze",
}));

const monthlyLeaderboard = [
  {
    rank: 1,
    name: "Rohan Iyer",
    hours: 60,
    contributions: 10,
    lastContribution: "2024-10-14 14:50",
    badge: "Gold",
  },
  {
    rank: 2,
    name: "Neha Mehta",
    hours: 55,
    contributions: 9,
    lastContribution: "2024-10-10 12:35",
    badge: "Silver",
  },
  {
    rank: 3,
    name: "Anjali Kumar",
    hours: 50,
    contributions: 8,
    lastContribution: "2024-10-08 17:45",
    badge: "Bronze",
  },
].map((item) => ({
  ...item,
  badge: item.hours >= 55 ? "Gold" : item.hours >= 50 ? "Silver" : "Bronze",
}));

const yearlyLeaderboard = [
  {
    rank: 1,
    name: "Raj Malhotra",
    hours: 300,
    contributions: 50,
    lastContribution: "2024-09-30 18:00",
    badge: "Gold",
  },
  {
    rank: 2,
    name: "Priya Reddy",
    hours: 280,
    contributions: 48,
    lastContribution: "2024-09-25 15:30",
    badge: "Silver",
  },
  {
    rank: 3,
    name: "Dev Singh",
    hours: 250,
    contributions: 45,
    lastContribution: "2024-09-20 10:00",
    badge: "Bronze",
  },
].map((item) => ({
  ...item,
  badge: item.hours >= 300 ? "Gold" : item.hours >= 200 ? "Silver" : "Bronze",
}));

const SchoolCollegeLeaderboardPage = (props) => {
  // const { slug } = useParams()

  // const EventDetails = events.find(item => item.slug === slug)

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  // const SubmitHandler = (e) => {
  //     e.preventDefault()
  // }

  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img width={580} src={LeaderboardWorking} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3 pb-0">
                    {/* <div className="custom-panell"> */}
                    <h2 className="about-heading-second text-titleeee mb-0 pb-0">
                      Sevak Army School/Colleges Leaderboard
                    </h2>
                    {/* </div> */}
                  </div>
                  {/* <h3 className="mt-0">
                    How the Sevak Army Influencer Leaderboard Works?
                  </h3> */}
                  <p style={{ textAlign: "justify" }}>
                    The Sevak Army Leaderboard for schools and colleges is an
                    exciting feature designed to inspire competition, track
                    volunteer performance, and recognise the efforts of students
                    across different educational institutions. Through this
                    system, we aim to motivate students, and institutions to
                    actively participate in volunteer work while offering a
                    transparent and engaging way to measure their impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container section-padding pt-0">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0">
                    <h2 className="about-heading-second text-titleeee">
                      What is the Leaderboard?{" "}
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    The Sevak Army Leaderboard is a dynamic tracking system that
                    ranks students, schools, and colleges based on their
                    participation in volunteer activities. It’s a tool that
                    encourages healthy competition by assigning points for each
                    task completed by student volunteers. These points
                    accumulate over time, allowing participants to see where
                    they stand compared to others in their institution or across
                    the broader Sevak Army network.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={abImg7} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-features-area section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle title="How it works?" />
            </div>
          </div>
          <div className="features-wrap mt-3">
            <div className="row justify-content-center">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-5 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div className="feature-item">
                      <div className="feature-text">
                        <div className="mb-4 d-flex justify-content-center align-items-center">
                          <img
                            src={feature.img}
                            alt=""
                            className="img-fluid"
                            style={{
                              height: "300px",
                              width: "100%",
                              objectFit: "cover",
                              maxHeight: "320px",
                            }}
                          />
                        </div>
                        <h2 className="ps-3 fw-bold">{feature.title}</h2>
                        <p className="p-2">
                          {<p className="p-2">{feature.description}</p>}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="container section-padding pt-3">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle title="Types of Leaderboards" />
          </div>
        </div>
        <div className="service-wrap">
          <div className="row justify-content-center column-gappp pt-3">
            {Services2.slice(0, 19).map((Service, sitem) => (
              <div
                className="col col-lg-4 col-md-8 col-sm-6 col-12"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <ServiceCard
                    width="313px"
                    height="480px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="wpo-event-details-area section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="wpo-event-item">
                <div className="wpo-event-img">
                </div>
                <div className="wpo-event-details-text mt-2">
                  <SectionTitle
                    subtitle=""
                    title="Weekly, Monthly, and Yearly Rankings"
                  />
                  <p className="text-center">
                    Sevak Army maintains leaderboards on a weekly, monthly, and yearly basis. This time-based ranking allows students and institutions to continuously strive for improvement while offering fresh opportunities for recognition. It keeps the engagement ongoing and motivates participants to remain active throughout the year.
                  </p>
                </div>
                <div className="wpo-event-details-wrap">
                  <div className="wpo-event-details-tab">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 0
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(0)}
                        >
                          Weekly Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 1
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(1)}
                        >
                          Monthly Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 2
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(2)}
                        >
                          Yearly Leaderboard
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="wpo-event-details-content">
                    <div className="tab-content">
                      <div className={activeTab === 0 ? "active" : "hidden"}>
                        <div id="Schedule" className="tab-pane active w-100">
                          <LeaderboardTable
                            data={weeklyLeaderboard}
                            title="Weekly Leaderboard"
                          />
                        </div>
                      </div>
                      <div className={activeTab === 1 ? "active" : "hidden"}>
                        <div id="Map" className="tab-pane">
                          <LeaderboardTable
                            data={monthlyLeaderboard}
                            title="Monthly Leaderboard"
                          />
                        </div>
                      </div>
                      <div className={activeTab === 2 ? "active" : "hidden"}>
                        <div id="Contact" className="tab-pane">
                          <LeaderboardTable
                            data={yearlyLeaderboard}
                            title="Yearly Leaderboard"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <section className="wpo-features-area section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                title="Weekly, Monthly, and Yearly Rankings"
                paragarap="Sevak Army maintains leaderboards on a weekly, monthly, and yearly basis. This time-based ranking allows students and institutions to continuously strive for improvement while offering fresh opportunities for recognition. It keeps the engagement ongoing and motivates participants to remain active throughout the year."
              />
            </div>
          </div>
          <div className="features-wrap">
            <div className="row justify-content-center">
              {featuresData1.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div
                      className="feature-item"
                      style={{ alignItems: "center", height: "200px" }}
                    >
                      <div className="feature-icon">
                        <div className="icon">
                          <img src={feature.img} className="h-[40px]" />
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container section-padding pt-3">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle
              subtitle="Track Your Impact and Earn Recognition for Social Good"
              title="Lead the Change: Join the Sevak Army Influencer Leaderboard"
            />
          </div>
        </div>
        <div className="service-wrap">
          <div className="row justify-content-center column-gappp pt-3">
            {Services.slice(0, 19).map((Service, sitem) => (
              <div
                className="col col-lg-4 col-md-8 col-sm-6 col-12"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <ServiceCard
                    width="313px"
                    height="480px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center mt-5">
          <button className="theme-btn">
            Sign Up and Start Inspiring Change
          </button>
        </div>
      </div> */}

      <section className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle
              title="Gamification and Rewards"
              paragarap="To make volunteering fun and rewarding, the leaderboard leverages gamification. Much like in a mobile game, where players accumulate points and move up levels, Sevak Army’s leaderboard offers a similar sense of achievement and competition"
            />
          </div>
        </div>
        <div className="mt-3">
          <section className={" wpo-fun-fact-section-s3"}>
            <div className="container mt-3">
              <div className="service-wrap">
                <div className="row justify-content-center gap-3">
                  <div className="service-item px-4 py-4 col-lg-4 col-md-6 col-sm-6 col-11 sevak-custom-carddd">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <div className="w-100 d-flex py-4 justify-content-center">
                        <div className="icon">
                          <img src={Award} width={60} alt="img" />
                        </div>
                      </div>
                      <h2 className="text-center">Milestone Rewards</h2>
                      <p
                        className="w-100 text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        Volunteers can receive rewards when they reach specific
                        point thresholds. These rewards could range from
                        e-certificates to special recognition at events, or even
                        opportunities for further leadership roles within Sevak
                        Army.
                      </p>
                    </div>
                  </div>
                  <div className="service-item px-4 py-4 col-lg-4 col-md-6 col-sm-6 col-11 sevak-custom-carddd">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <div className="w-100 d-flex py-4 justify-content-center">
                        <div
                          className="icon"
                          style={{
                            backgroundColor: "#FFFCF4",
                            border: "1px solid #EBB011",
                          }}
                        >
                          <img src={HelpingNgos} width={60} alt="img" />
                        </div>
                      </div>
                      <h2 className="text-center">Certificates and Badges</h2>
                      <p
                        className="w-100 text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        As volunteers climb higher on the leaderboard, they can
                        earn digital badges and digital certificates that
                        recognise their achievements. These digital badges can
                        be shared on social media, showcasing their dedication
                        to community service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="wpo-about-section">
        <div className="container section-padding pt-5 pb-0">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Referalimg} alt="img" width={580} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    {/* <span>Benefits</span> */}
                    <h2 className="about-heading-second text-titleeee">
                      Referral Points and Recognition
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    Schools and colleges get unique referral links, allowing
                    institutions to invite others to join the platform. As
                    students from your institution register and volunteer
                    through this link, your school or college earns referral
                    points. This boosts your institution's rank on the
                    institutional leaderboard, recognising your role in
                    promoting community service and social responsibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3">
                    {/* <span>Benefits</span> */}
                    <h2 className="about-heading-second text-titleeee">
                      Inspiring Healthy Competition
                    </h2>
                  </div>
                  <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                    The leaderboard fosters a healthy sense of competition among
                    students, schools, and colleges, driving them to increase
                    their participation in volunteering. Educational
                    institutions can promote the leaderboard as a way to
                    motivate their students, offering additional recognition and
                    rewards to those who perform well. <br />
                    The leaderboard is a tool not only for tracking but for
                    celebrating the spirit of service. It showcases the tangible
                    impact that students and institutions are making in their
                    communities while also recognising the efforts and
                    dedication that go into every volunteer task.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Compimg} alt="img" width={580} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12">
            <SectionTitle
              title="Why the Leaderboard Matters for Schools and Colleges?"
              paragarap="For schools and colleges, the leaderboard offers a unique way to measure and promote their students’ involvement in social causes. By engaging students through the Sevak Army platform, institutions can"
            />
          </div>
        </div>
        <div className="mt-3">
          <section className={" wpo-fun-fact-section-s3"}>
            <div className="container mt-3">
              <div className="service-wrap">
                <div className="row justify-content-center gap-3">
                  <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-5 col-sm-8 col-11 sevak-custom-carddd">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <div className="w-100 d-flex py-4 justify-content-center">
                        <div className="icon">
                          <img src={Opportunities} width={60} alt="img" />
                        </div>
                      </div>
                      <h2 className="text-center">
                        Foster a Culture of Service
                      </h2>
                      <p
                        className="w-100 text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        The leaderboard helps build a spirit of community
                        involvement, encouraging students to volunteer not just
                        for personal growth but also to represent their
                        institution proudly.
                      </p>
                    </div>
                  </div>
                  <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-5 col-sm-8 col-11 sevak-custom-carddd">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <div className="w-100 d-flex py-4 justify-content-center">
                        <div
                          className="icon"
                          style={{
                            backgroundColor: "#FFFCF4",
                            border: "1px solid #EBB011",
                          }}
                        >
                          <img src={HelpingNgos} width={60} alt="img" />
                        </div>
                      </div>
                      <h2 className="text-center">Recognition and Prestige</h2>
                      <p
                        className="w-100 text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        Schools and colleges that top the leaderboard gain
                        recognition not just within the platform but in the
                        wider community. This highlights their commitment to
                        nurturing socially responsible students and contributing
                        to societal well-being.
                      </p>
                    </div>
                  </div>
                  <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-5 col-sm-8 col-11 sevak-custom-carddd">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <div className="w-100 d-flex py-4 justify-content-center">
                        <div
                          className="icon"
                          style={{
                            backgroundColor: "#FFFCF4",
                            border: "1px solid #EBB011",
                          }}
                        >
                          <img src={HelpingNgos} width={60} alt="img" />
                        </div>
                      </div>
                      <h2 className="text-center">Motivational Tool</h2>
                      <p
                        className="w-100 text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        The competitive nature of the leaderboard encourages
                        more students to participate, as they can see the impact
                        of their efforts in real-time and be rewarded for it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Get Started Today</span>
                    {/* <h2>Register Now and Start Volunteering</h2> */}
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Join the Sevak Army Leaderboard and see how your school or
                      college can rise to the top. Encourage your students to
                      engage in meaningful volunteer work, track their progress,
                      and celebrate their achievements on a platform that values
                      service and social contribution.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchoolCollegeLeaderboardPage;
