import React, { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import Logo from "../../images/logo.svg";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";
import NgoBenefitsSection from "./NgoBenefitsSection";

function NgoBenefits({show, setShow}) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={'Benefits'} pagesub={'Benefits'} />
            <NgoBenefitsSection show={show} setShow={setShow}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default NgoBenefits;
