import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Inspire from "../../images1/influencer/home/award.jpg";


import "slick-carousel/slick/slick-theme.css";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import CountUp from "react-countup";
import Shape from "../../images/testimonial/bg.png";
// import Newsletter from "../../images1/volunteer/home/newsletter.png";
import Newsletter from "../../images1/volunteer/home/influencer.jpg";

import icon from "../../images/cta/top-icon.png";

/* team imags */
import team1 from "../../images1/team/sevak-army-team-1.jpg";
import team2 from "../../images1/team/sevak-army-team-2.jpg";
import team3 from "../../images1/team/sevak-army-team-7.jpg";
import team4 from "../../images1/team/sevak-army-team-8.jpg";
import team5 from "../../images1/team/sevak-army-team-9.jpg";

/* HeroSlide Image  */
import HeroSlide1 from "../../images1/influencer/home/sevak-army-influencer-home-slider-1.jpg";
import HeroSlide2 from "../../images1/influencer/home/sevak-army-influencer-home-slider-2.jpg";
import HeroSlide3 from "../../images1/influencer/home/sevak-army-influencer-home-slider-3.jpg";
import HeroSlide4 from "../../images1/influencer/home/sevak-army-influencer-home-slider-4.png";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.svg";

import simg1 from "../../images/service/inspiration.png";
import simg2 from "../../images/service/share-link.png";
import simg3 from "../../images/service/promote.png";
import simg4 from "../../images/service/increase.png";
import simg5 from "../../images/service/volunteer.png";
import simg6 from "../../images/service/community.png";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";

import img1 from "../../images/service/leadership.png";
import img2 from "../../images/service/stars.png";
import img3 from "../../images/service/social-media-engagement.png";
import img4 from "../../images/service/progress.png";

import ServiceCard from "../../components/ServiceSection/ServiceCard";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

import blogSingleImg1 from "../../images/blog/1.jpg";
import blogSingleImg2 from "../../images/blog/2.jpg";
import blogSingleImg3 from "../../images/blog/3.jpg";

import recent1 from "../../images/recent-posts/img-1.jpg";
import recent2 from "../../images/recent-posts/img-2.jpg";
import recent3 from "../../images/recent-posts/img-3.jpg";

import blog1 from "../../images1/team/sevak-army-team-1.jpg";
import blog2 from "../../images1/team/sevak-army-team-2.jpg";
import blog3 from "../../images1/team/sevak-army-team-8.jpg";

import blogImg1 from "../../images1/influencer/home/sevak-army-influencer-home-1.jpg";
import blogImg2 from "../../images1/influencer/home/sevak-army-influencer-home-2.jpg";
import blogImg3 from "../../images1/influencer/home/sevak-army-influencer-home-3.jpg";

import image1 from "../../images1/influencer/home/image-4.png";
import image4 from "../../images1/influencer/home/image-3.png";
import image5 from "../../images1/influencer/home/image-2.png";
import image2 from "../../images1/influencer/home/image-1.png";
import image3 from "../../images1/team/sevak-army-team-7.jpg";

import AnchorLink from "react-anchor-link-smooth-scroll";
import "../../style.css";
import NewsletterPopup from "../../components/NewsletterPopup";

const causesData = [
  {
    id: 1,

    title: "#EducationForAll  Campaign",

    description:
      "Influencers teamed up with NGOs to provide scholarships, learning materials and mentorship for underprivileged children. Their stories inspired support and demonstrated how social influence drives real educational change.",
  },
  {
    id: 2,

    title: "#CleanAirForIndia Campaign",

    description:
      "Environmental advocates and influencers worked together to raise awareness about air pollution. This campaign promoted cleaner air through education and local action and inspired community involvement.",
  },
  {
    id: 3,

    title: "#EmpowerWomenNow Campaign",

    description:
      "Focused on vocational training and financial literacy, this campaign empowered women in underserved communities. Influencers shared stories that motivated their audiences to support women's independence.",
  },
];

// const ClickHandler = () => {
//   window.scrollTo(10, 0);
// };

const Services = [
  {
    Id: "01",
    sImg: simg1,
    sSImg: sSimg1,
    title: "Inspire Your Followers",
    slug: "1",
    description:
      "Encourage your followers to join Sevak Army as volunteers, helping them find purposeful tasks that contribute to society.",
  },
  {
    Id: "02",
    sImg: simg2,
    sSImg: sSimg2,
    title: "Share Your Unique Link",
    slug: "2",
    description:
      "After registering, you'll receive a personal referral link. Share it with your audience to make it easy for them to join as volunteers.",
  },
  {
    Id: "03",
    sImg: simg3,
    sSImg: sSimg3,
    title: "Promote Important Causes",
    slug: "3",
    description:
      "Use your platform to raise awareness for causes that matter, like healthcare, education, and environmental sustainability. Your reach can help make a real impact.",
  },
  {
    Id: "04",
    sImg: simg4,
    sSImg: sSimg4,
    title: "Increase Your Social Impact",
    slug: "4",
    description:
      "Every new volunteer you bring to Sevak Army adds to your positive influence, growing your impact on communities across India.",
  },
  {
    Id: "05",
    sImg: simg5,
    sSImg: sSimg4,
    title: "Get Recognised as a Changemaker",
    slug: "4",
    description:
      "By driving others toward volunteerism, you strengthen your image as someone dedicated to social change.",
  },
  {
    Id: "06",
    sImg: simg6,
    sSImg: sSimg4,
    title: "Connect with Your Community",
    slug: "4",
    description:
      "Engaging in this mission deepens your bond with your followers, showing them your commitment to making the world a better place.",
  },
];
const Testimonial = [
  {
    id: "03",
    text: "I'm beyond proud to see the ripple effect of my efforts with Sevak Army. Each person who joined through my link was another step toward making a positive impact, and I’m constantly inspired by the stories my followers share from their volunteer experiences. Seeing my name on the leaderboard was exciting, but the best part has been knowing that together, we’re creating a better future.",
    subtitle: "Zara",
    title: "Fitness Influencer",
    image: image1,
  },
  {
    id: "04",
    text: "Sevak Army has brought purpose and depth to my work as an influencer. Watching my followers embrace volunteerism and support causes they’re passionate about has been rewarding. Knowing that I’m helping people find ways to give back to society makes this journey incredibly fulfilling. I feel a deep connection to my followers, and our shared purpose strengthens that bond daily.",
    subtitle: "Rishi",
    title: "Travel Blogger",
    image: image4,
  },
  {
    id: "04",
    text: "Being part of Sevak Army has allowed me to make a genuine impact. Within days of sharing my link, my followers signed up to volunteer and thanked me for helping them find meaningful ways to contribute. The appreciation on social media was amazing, but seeing my followers find joy and purpose through service was even more meaningful. This platform has changed how I think about my influence.",
    subtitle: "Ananya",
    title: "Food & Lifestyle Influencer",
    image: image5,
  },
  {
    id: "01",
    text: "Joining Sevak Army has been one of the most meaningful experiences of my career. I loved seeing my followers involved with causes that truly matter, from education to environmental conservation. Watching them find purpose through volunteering made me feel like I’m part of something bigger than myself. The sense of community and connection is powerful—together, we’re making a difference.",
    title: "Maya",
    subtitle: "Social Media Influencer",
    image: image3,
  },
  {
    id: "02",
    text: "Encouraging my followers to join Sevak Army gave my platform a whole new dimension. I was thrilled to see them connect with NGOs and contribute their skills to impactful causes. It’s fulfilling to know that my influence is sparking real change, not just online but in communities. The feedback I received was inspiring me to keep pushing for social good.",
    title: "Jay",
    subtitle: "Lifestyle Content Creator.",
    image: image2,
  },
  // {
  //     id: "04",
  //     text: "This is the best agency for non profit activities.I love their work so much. I wish one day they would remove all the poverty from the world. I will be there when they need.",
  //     title: "Albert Flores",
  //     subtitle: "President of Sales",
  //     image: image2
  // },
];
const featuresData = [
  {
    iconClass: "flaticon-volunteer",
    img: img1,
    title: "Increase Your Influence with Sevak Army",
    description:
      "Encourage your followers to join meaningful volunteer initiatives.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Earn Points & Recognition",
    img: img2,
    description:
      "Share your unique referral link and earn points for every follower who completes their first task.",
  },
  {
    iconClass: "flaticon-charity",
    title: "Create and Share Content",
    img: img3,
    description:
      "Share reels, videos, or posts with your referral link, encouraging your followers to join Sevak Army.",
  },
  {
    iconClass: "flaticon-charity",
    title: "Track Your Impact",
    img: img4,
    description:
      "Watch yourself grow on the leaderboard, and gain recognition for your contributions to society.",
  },
];

const blogs = [
  {
    id: "1",
    title:
      "How Influencers Are Shaping the Future of Charity and Non-Profit Work?",
    slug: "1",
    subtitle: "Successful Implementation Techniques.",
    create_at: "25 Jan, 24",
    screens: blogImg1,
    bsing: blog1,
    recent: recent1,
    blogSingleImg: blogSingleImg1,
    comment: "35",
    day: "28",
    comments: "5",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Sanket Kamble",
  },
  {
    id: "2",
    title:
      "How Influencers Can Create Lasting Social Impact Through Collaboration?",
    slug: "2",
    subtitle: " How Companies Are Making a Difference?",
    create_at: "25 Jan, 24",
    screens: blogImg2,
    bsing: blog2,
    recent: recent2,
    blogSingleImg: blogSingleImg2,
    comment: "35",
    day: "28",
    month: "AUGUST",
    comments: "14",
    blClass: "format-standard-image",
    athor: "Rishi Rathod",
  },
  {
    id: "3",
    title:
      "How Influencers Can Drive Social Change: Top Tips for Maximising Your Impact",
    slug: "3",
    subtitle: "Why Your Company Should Get Involved?",
    create_at: "25 Jan, 24",
    screens: blogImg3,
    bsing: blog3,
    recent: recent3,
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    comments: "48",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Priyanka Ray",
  },
];

const Influencer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [shownewsletter, setShowNewsletter] = useState(false);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };

  const settingst = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Navbar hclass="wpo-site-header wpo-site-header" Logo={Logo} />
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content">
                <div className="slide-title-sub">
                  <h6>Use Your Influence to Drive Positive Change</h6>
                </div>
                <div className="slide-title">
                  <h2>
                    Inspire Action, Encourage Volunteering
                    {/* Join us in spreading the word! */}
                  </h2>
                </div>
                <div className="slide-text slideee-texttt">
                  <p>
                    As an influencer, you can inspire your followers to make a
                    difference by joining Sevak Army, where they can discover
                    meaningful volunteer opportunities with NGOs. From education
                    and healthcare to sustainability, your influence can
                    encourage others to contribute their time and skills for a
                    better future. Let's create a wave of positive change and
                    make a lasting impact.
                  </p>
                </div>

                <h4 className="text-white fs-2">
                  Join us in spreading the word!
                </h4>
                <div className="hero-btn" style={{ marginTop: "35px" }}>
                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn"
                  >
                    Join Us
                  </Link>
                  <div className="wpo-supporter-img">
                    <ul className="wpo-supporter-slide">
                      <Slider {...settings}>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Aliza Anny"
                        >
                          <img src={team1} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="David Miller"
                        >
                          <img src={team2} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Maria Silva"
                        >
                          <img src={team3} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Takila Mas"
                        >
                          <img src={team4} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Jenny Lawba"
                        >
                          <img src={team5} alt="" />
                        </li>
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          parallax={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1800,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide1})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide2})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide3})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide4})` }}
            ></div>
          </SwiperSlide>
          ...
        </Swiper>
      </section>

      <section className="wpo-features-area">
        <div className="container">
          <div className="features-wrap">
            <div className="row gy-4 justify-content-center">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div className="feature-item">
                      <div className="feature-icon">
                        <div className="icon">
                          <img src={feature.img} className="h-[40px]" />
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={"section-padding " + "wpo-service-section s2"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                subtitle="Inspire Action, Drive Impact"
                title="Use Your Influence for Positive Change"
                paragarap="As an influencer, you have the power to make a significant difference by encouraging your followers to join meaningful volunteer work through Sevak Army. "
              />
            </div>
          </div>
          <div className="service-wrap">
            <div className="row ">
              {Services.map((Service, sitem) => (
                <div className="col col-md-6 col-sm-6 col-12" key={sitem}>
                  <ServiceCard
                className="service-carddd"
               height="310px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="text-center w-full flex flex-column justify-content-center mb-3">
            <p className="fs-3 text-center">
              Let’s work together to inspire action and bring positive change to
              society!
            </p>
            <button onClick={() => props.setShow(true)} className="theme-btn">
              Join Now
            </button>
          </div>
        </div>
      </section>

      <div className="">
        {/* <section style={{ marginTop: "130px" }} className={"wpo-testimonial-section"}>
        <div className="causes-wrap">
          <div className="container-fluid">
            <div className="row">
              {causesData.slice(0, 3).map((cause, cky) => (
                <div className="col col-lg-4 col-md-6 col-12" key={cky}>
                  <div className="causes-item">
                    <div className="content">
                      <h2>{cause.title}</h2>
                      <p>{cause.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

        {/* new Change start */}

        {/* <section className="container section-padding">
          <div className="mt-3">
            <section className={"wpo-fun-fact-section-s3 pb-0"}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-12 col-12">
                    <SectionTitle subtitle="What We Do?" title="Your Support Brings Hope and Change" />
                  </div>
                </div>
                <div className="service-wrap">
                  <div className="row justify-content-center">
                    {causesData.map((Service, sitem) => (
                      <div className="col col-lg-4 col-md-6 col-sm-6 col-12" key={sitem}>
                        <div className="service-item px-4 sevak-custom-carddd" style={{ height: '280px' }}>
                          <div className="text">
                            <h2 className="services-title-text fw-bold mt-0">
                              {Service.title}
                            </h2>
                            <p className="service-paragraph volunteer-home-text text-center" style={{ textAlign: 'justify' }}>{Service.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section> */}

        {/* <section className={" section-padding " + "wpo-fun-fact-section-s3"} style={{ marginTop: "20px" }}>
          <div className="container">
            <div className="fun-fact-wrap">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="info">
                    <h3>
                      <span>
                        <CountUp end={20} enableScrollSpy />+
                      </span>
                    </h3>
                    <p> Sevak Army Influencers</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="info">
                    <h3>
                      <span>
                        <CountUp end={10} enableScrollSpy />+
                      </span>
                    </h3>
                    <p> Sevak Army Campaigns</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="info">
                    <h3>
                      <span>
                        <CountUp end={20} enableScrollSpy />+
                      </span>
                    </h3>
                    <p> Sevak Army Partnerships</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="info">
                    <h3>
                      <span>
                        <CountUp end={50} enableScrollSpy />+
                      </span>
                    </h3>
                    <p> Sevak Army Engagements</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* end */}
      </div>

      <section className={"section-padding mt-4" + ""}>
        <div className="container">
          <div className="wpo-testimonial-slider">
            <Slider {...settingst}>
              {Testimonial.map((Testimonial, item) => (
                <div
                  className="testimonial-item"
                  key={item}
                  style={{ minHeight: 700 }}
                >
                  <div className="content">
                    <div className="icon">
                      <i className="flaticon-quote"></i>
                    </div>
                    <p>{Testimonial.text}</p>
                    <div className="shape">
                      <img src={Shape} alt="" />
                    </div>
                  </div>
                  <div className="bottom-content">
                    <div className="image">
                      <img src={Testimonial.image} alt="" />
                    </div>
                    <div className="text">
                      <h3>{Testimonial.title}</h3>
                      <span>{Testimonial.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      {/* <section className={"wpo-blog-section-s2 section-padding pt-0"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Blogs" title="Our Most Recent Updates and Insights" />
            </div>
          </div>
          <div className="blog-wrap mt-5 mb-5 pb-5">
            <div className="row">
              {blogs.map((blog, bl) => (
                <div className="col col-lg-4 col-md-6 col-12" key={bl}>
                  <div className="blog-single-card">
                    <div className="image">
                      <img src={blog.screens} alt="" />
                      <div className="top-date">
                        {blog.athor.split(" ")[0]}
                        <div className="shape">
                          <svg width="8" height="9" viewBox="0 0 8 9" fill="none">
                            <path d="M7.99999 9.0001L-9.79769e-05 9.00023L8.00005 0.500149L7.99999 9.0001Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <ul className="top-content">
                        <li>
                          <div className="image-single">
                            <img src={blog.bsing} alt="" />
                          </div>
                          {blog.athor}
                        </li>
                        <li>
                          <i className="flaticon-chatting"></i>
                          {blog.comments} Comments
                        </li>
                      </ul>
                      <h2 className="pb-5">
                        {blog.title}
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* Image placeholder */}
                  <div className="wpo-about-right pt-0">
                    <img src={Newsletter} height={530} alt="Newsletter" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0">
                    <span>Subscribe to Sevak Army</span>
                    <h2 className="about-heading-second">
                      Stay Updated and Inspired
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    Be the first to know about new volunteer opportunities,
                    impactful projects, and campaigns that need your voice. Join
                    our newsletter to receive exclusive updates and resources to
                    inspire your followers to join Sevak Army and make a real
                    change.
                  </p>
                  <h3>Sign Up and Be Part of the Change!</h3>
                  <p>
                    Stay connected with meaningful updates across India and help
                    motivate others to make a lasting impact.
                  </p>
                  <div>
                    <button
                      className="theme-btn"
                      onClick={() => setShowNewsletter(true)}
                    >
                      Subscribe Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle
              title="Climb the Leaderboard and Shine!"
              paragarap="Make an impact, gain recognition, and see your name rise among top influencers who are driving real change. Every volunteer you inspire brings you closer to the top!"
            />
          </div>
        </div>

        <div className="mt-3">
          <section className={" section-padding wpo-fun-fact-section-s3"}>
            <div className="container">
              <div className="fun-fact-wrap">
                <div className="row justify-content-center gap-3">
                  <div className="blog-single-card py-4 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <h4 className="blog-title-home">@InspireWithMaya</h4>
                      <h3 className="w-100">
                        <span style={{ fontSize: "50px" }}>
                          <CountUp end={300} enableScrollSpy />
                        </span>
                      </h3>
                      <p
                        className="w-100 text-nowrap text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        {" "}
                        Volunteers Recruited
                      </p>
                    </div>
                  </div>
                  <div className="blog-single-card py-4 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <h4 className="blog-title-home">@JayLifestyle</h4>
                      <h3>
                        <span style={{ fontSize: "50px" }}>
                          <CountUp end={250} enableScrollSpy />
                        </span>
                      </h3>
                      <p
                        className="w-100 text-nowrap text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        Volunteers Recruited
                      </p>
                    </div>
                  </div>
                  <div className="blog-single-card py-4 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <h4 className="blog-title-home">@FitWithZara</h4>
                      <h3>
                        <span style={{ fontSize: "50px" }}>
                          <CountUp end={220} enableScrollSpy />
                        </span>
                      </h3>
                      <p
                        className="w-100 text-nowrap text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        Volunteers Recruited
                      </p>
                    </div>
                  </div>
                  <div className="blog-single-card py-4 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <h4 className="blog-title-home">@ChangeMaker_Ravi</h4>
                      <h3>
                        <span style={{ fontSize: "50px" }}>
                          <CountUp end={180} enableScrollSpy />
                        </span>
                      </h3>
                      <p
                        className="w-100 text-nowrap text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        Volunteers Recruited
                      </p>
                    </div>
                  </div>
                  <div className="blog-single-card py-4 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
                    <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                      <h4 className="blog-title-home">@GreenFuture_Aditi</h4>
                      <h3>
                        <span style={{ fontSize: "50px" }}>
                          <CountUp end={150} enableScrollSpy />
                        </span>
                      </h3>
                      <p
                        className="w-100 text-nowrap text-center ms-0"
                        style={{ maxWidth: "100%" }}
                      >
                        Volunteers Recruited
                      </p>
                    </div>
                  </div>
                </div>
                {/* <h4 className="blog-title-home w-100 text-center mt-5">See Full Leaderboard</h4> */}
                <p className="w-100 text-center mt-4 fs-3">
                  Can you beat the top spot? Keep inspiring and climb higher!{" "}
                </p>
                <div className="w-100 d-flex justify-content-center">
                  <Link
                    onClick={ClickHandler}
                    className="theme-btn"
                    to="/volunteer/leaderboard"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* Image placeholder */}
                  <div className="wpo-about-right pt-0">
                    <img src={Inspire} height={530} alt="Recognition Event" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0">
                    <span>Get Recognized for Your Impact!</span>
                    <h2 className="about-heading-second">
                      Your Moment to Shine Awaits
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    Your dedication deserves the spotlight. Join exclusive
                    recognition events, connect with fellow changemakers, and
                    receive awards for your outstanding contributions to
                    positive change in communities across India.
                  </p>
                  <h3>Stay Tuned for Upcoming Awards & Recognition Events</h3>
                  <p>
                    Together, let’s celebrate the difference we’re making. Get
                    ready to be recognized for the impact you’re creating!
                  </p>
                  <div>
                    <button
                      className="theme-btn"
                      onClick={() => props.setShow(true)}
                    >
                      Know More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Ready to Use Your Influence for Good</span>
                    {/* <h2>Register Now and Start Volunteering</h2> */}
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Join us in inspiring change across India. Encourage your
                      followers to volunteer, support important causes, and
                      build a legacy of social impact. Become an influencer with
                      a purpose — Start today with Sevak Army.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <span>Sign Up and Make a Difference Together!</span>
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Join the Movement
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="col-lg-12">
          <div className="header-menu">
            <ul className="smothscroll">
              <li>
                <AnchorLink href="#scrool">
                  <i className="ti-arrow-up"></i>
                </AnchorLink>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Footer />
      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
    </>
  );
};

export default Influencer;
