import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
// import abImg7 from "../../images1/volunteer/opportunities/sevak-army-volunteer-opportunities-5.jpg";
import abImg7 from "../../images1/volunteer/opportunities/bg-img.jpg";
// import LeaderboardWorking from "../../images1/volunteer/home/leaderboard-work.png";
import LeaderboardWorking from "../../images1/volunteer/opportunities/image.jpg";
import Growth from "../../images1/volunteer/home/growth.png";
import Boost from "../../images1/volunteer/home/boost.png";
import Podium from "../../images1/volunteer/home/podium.png";
import Certification from "../../images1/volunteer/home/certification.png";
import GivingBack from "../../images1/volunteer/home/giving-back.png";
import icon from "../../images/cta/top-icon.png";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import LeaderboardTable from "../../components/LeaderboardTable";
import ServiceCard from "../../components/ServiceSection/ServiceCard";

import lImg1 from "../../images1/influencer/leaderboard/motivation.png";
import lImg2 from "../../images1/influencer/leaderboard/influencer.png";

const Services = [
  {
    Id: "01",
    sImg: Growth,
    sSImg: sSimg1,
    title: "Track Your Progress in Real Time",
    slug: "1",
    description:
      "Each influencer can log in to their profile and view their standing on the leaderboard. You will also receive regular email updates showing your rank and progress. These updates keep you motivated and informed as you inspire your followers to join the Sevak Army and make an impact.",
  },
  {
    Id: "02",
    sImg: Boost,
    sSImg: sSimg2,
    title: "Boost Your Rank with Referrals",
    slug: "2",
    description:
      "Help grow the Sevak Army community by inviting your followers to join. Each follower who signs up using your referral link will add to your points, helping you climb higher on the leaderboard.",
  },
  {
    Id: "03",
    sImg: Podium,
    sSImg: sSimg3,
    title: "Why Does the Leaderboard Matters?",
    slug: "3",
    description:
      "The Sevak Army Influencer Leaderboard is more than competition—it celebrates your commitment to social good. The more volunteers you inspire, the higher you climb, earning greater recognition. It also helps you set goals and stay motivated as you work toward a better world.",
  },
  {
    Id: "04",
    sImg: Certification,
    sSImg: sSimg4,
    title: "Earn Digital Badges and Certificates",
    slug: "4",
    description:
      "As you climb the leaderboard, you’ll unlock digital badges and certificates for your achievements. These milestones showcase your leadership in social impact and can be shared on social media or added to your portfolio, highlighting your dedication to meaningful causes.",
  },
  {
    Id: "05",
    sImg: lImg1,
    sSImg: sSimg4,
    title: "Stay Motivated and Keep Inspiring Change",
    slug: "4",
    description:
      "Whether aiming for the top spot or simply wanting to track your success in inspiring others, the Sevak Army Influencer Leaderboard keeps you engaged and driven. It’s more than just points - it’s about building a legacy of social change, one follower at a time.",
  },
  {
    Id: "06",
    sImg: lImg2,
    sSImg: sSimg4,
    title: "Join the Influencer Leaderboard Today",
    slug: "4",
    description:
      "Become a Sevak Army influencer and earn points for every follower you inspire to volunteer. Track your progress, climb the ranks, and gain recognition for your role in making a positive impact.",
  },
];

const weeklyLeaderboard = [
  {
    rank: 1,
    name: "Aarav Sharma",
    hours: 15,
    contributions: 3,
    lastContribution: "2024-10-14 09:30",
    badge: "Gold",
  },
  {
    rank: 2,
    name: "Vihaan Patel",
    hours: 12,
    contributions: 2,
    lastContribution: "2024-10-13 16:20",
    badge: "Silver",
  },
  {
    rank: 3,
    name: "Isha Gupta",
    hours: 10,
    contributions: 1,
    lastContribution: "2024-10-12 11:15",
    badge: "Bronze",
  },
].map((item) => ({
  ...item,
  badge: item.hours >= 15 ? "Gold" : item.hours >= 12 ? "Silver" : "Bronze",
}));

const monthlyLeaderboard = [
  {
    rank: 1,
    name: "Rohan Iyer",
    hours: 60,
    contributions: 10,
    lastContribution: "2024-10-14 14:50",
    badge: "Gold",
  },
  {
    rank: 2,
    name: "Neha Mehta",
    hours: 55,
    contributions: 9,
    lastContribution: "2024-10-10 12:35",
    badge: "Silver",
  },
  {
    rank: 3,
    name: "Anjali Kumar",
    hours: 50,
    contributions: 8,
    lastContribution: "2024-10-08 17:45",
    badge: "Bronze",
  },
].map((item) => ({
  ...item,
  badge: item.hours >= 55 ? "Gold" : item.hours >= 50 ? "Silver" : "Bronze",
}));

const yearlyLeaderboard = [
  {
    rank: 1,
    name: "Raj Malhotra",
    hours: 300,
    contributions: 50,
    lastContribution: "2024-09-30 18:00",
    badge: "Gold",
  },
  {
    rank: 2,
    name: "Priya Reddy",
    hours: 280,
    contributions: 48,
    lastContribution: "2024-09-25 15:30",
    badge: "Silver",
  },
  {
    rank: 3,
    name: "Dev Singh",
    hours: 250,
    contributions: 45,
    lastContribution: "2024-09-20 10:00",
    badge: "Bronze",
  },
].map((item) => ({
  ...item,
  badge: item.hours >= 300 ? "Gold" : item.hours >= 200 ? "Silver" : "Bronze",
}));

const InfluencerLeaderboardPage = (props) => {
  // const { slug } = useParams()

  // const EventDetails = events.find(item => item.slug === slug)

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  // const SubmitHandler = (e) => {
  //     e.preventDefault()
  // }

  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={LeaderboardWorking} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3 pb-0">
                    {/* <div className="custom-panell"> */}
                    <h2 className="about-heading-second text-titleeee mb-0 pb-0">
                      Track Your Impact, Rise Through the Ranks, and Gain
                      Recognition
                    </h2>
                    {/* </div> */}
                  </div>
                  <h3 className="mt-0">
                    How the Sevak Army Influencer Leaderboard Works?
                  </h3>
                  <p style={{ textAlign: "justify" }}>
                    At Sevak Army, we recognize influencers who grow our
                    volunteer community. Our Influencer Leaderboard tracks your
                    progress, ranking you among peers and motivating continued
                    impact. Earn points, digital badges, and certificates as
                    your followers sign up and volunteer through your link.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container section-padding pt-0">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0">
                    <h2 className="about-heading-second text-titleeee">
                      Earn Points for Every Sign-Up{" "}
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    You will earn points for every follower who registers as a
                    volunteer using your unique link. Different actions, like
                    encouraging sign-ups for various tasks or causes, carry
                    different point values based on the number of volunteers
                    recruited and the overall impact generated. Whether it’s
                    promoting educational initiatives, environmental projects,
                    or social causes, your points will help you rise on the
                    leaderboard.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={abImg7} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="container section-padding pt-0">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <SectionTitle subtitle="" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <SectionTitle title="Climb the Weekly, Monthly, and Yearly Leaderboards" />
                    </div>
                </div>

                <div className="wpo-features-area pt-5">
                    <div className="features-wrap">
                        <p className="pb-2" style={{ width: '100%', textAlign: 'center' }}>We have leaderboards that refresh on a weekly, monthly, and yearly basis. You can track your performance in real-time and see how you compare to other volunteers:</p>

                        <div className="w-100 d-flex flex-column justify-content-center pb-3">
                            <div className="custom-panell" style={{ width: '100%' }}>
                                <h2>Weekly Leaderboard</h2>
                            </div>
                            <img className="border" style={{ borderRadius: '10px' }} src={Weekly} alt="Weekly" />
                        </div>
                        <div className="w-100 d-flex flex-column justify-content-center pt-5 pb-3">
                            <div className="custom-panell" style={{ width: '100%' }}>
                                <h2>Monthly Leaderboard</h2>
                            </div>
                            <img className="border" style={{ borderRadius: '10px' }} src={Monthly} alt="Monthly" />
                        </div>
                        <div className="w-100 d-flex flex-column justify-content-center pt-5 pb-3">
                            <div className="custom-panell" style={{ width: '100%' }}>
                                <h2>Yearly Leaderboard</h2>
                            </div>
                            <img className="border" style={{ borderRadius: '10px' }} src={Yearly} alt="Yearly" />
                        </div>

                    </div>
                </div>
            </section> */}

      <div className="wpo-event-details-area section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="wpo-event-item">
                <div className="wpo-event-img">
                  {/* <img src={EventDetails.psimg} alt="" /> */}
                </div>
                <div className="wpo-event-details-text">
                  <SectionTitle
                    subtitle=""
                    title="Climb the Weekly, Monthly, and Yearly Leaderboards"
                    paragarap="We offer leaderboards that refresh on a weekly, monthly, and
                    yearly basis so you can track your performance in real-time
                    and see how you compare with other influencers"
                  />
                </div>
                <div className="wpo-event-details-wrap">
                  <div className="wpo-event-details-tab">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 0
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(0)}
                        >
                          Weekly Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 1
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(1)}
                        >
                          Monthly Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 2
                              ? "nav-link active w-100"
                              : "nav-link w-100"
                          }
                          onClick={() => handleTabClick(2)}
                        >
                          Yearly Leaderboard
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="wpo-event-details-content">
                    <div className="tab-content">
                      <div className={activeTab === 0 ? "active" : "hidden"}>
                        <div id="Schedule" className="tab-pane active w-100">
                          <LeaderboardTable
                            data={weeklyLeaderboard}
                            title="Weekly Leaderboard"
                          />
                        </div>
                      </div>
                      <div className={activeTab === 1 ? "active" : "hidden"}>
                        <div id="Map" className="tab-pane">
                          <LeaderboardTable
                            data={monthlyLeaderboard}
                            title="Monthly Leaderboard"
                          />
                        </div>
                      </div>
                      <div className={activeTab === 2 ? "active" : "hidden"}>
                        <div id="Contact" className="tab-pane">
                          <LeaderboardTable
                            data={yearlyLeaderboard}
                            title="Yearly Leaderboard"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container section-padding pt-3">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12">
            <SectionTitle
              subtitle="Track Your Impact and Earn Recognition for Social Good"
              title="Lead the Change: Join the Sevak Army Influencer Leaderboard"
            />
          </div>
        </div>
        <div className="service-wrap">
          <div className="row justify-content-center column-gappp pt-3">
            {Services.slice(0, 19).map((Service, sitem) => (
              <div
                className="col col-lg-4 col-md-8 col-sm-6 col-12"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <ServiceCard
                    width="313px"
                    height="480px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center mt-5">
          <button onClick={() => props.setShow(true)} className="theme-btn">
            Sign Up and Start Inspiring Change
          </button>
        </div>
      </div>

      {/* <section className="wpo-cta-section-s3 section-padding pt-0">
                <div className="container">
                    <div className="bg-color" style={{ background: "#007560" }}>
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="cta-wrap">

                                    <h3 className="pt-3" style={{ color: 'white', fontSize: '38px' }}>
                                        Join the Leaderboard Today
                                    </h3>
                                    <p className="px-2" style={{ color: 'whitesmoke' }}>Become a Sevak Army volunteer and earn points for every task you complete. Track your progress, climb the ranks, and gain recognition for the meaningful work you do. </p>

                                    <Link onClick={() => props.setShow(true)} to="#" className="theme-btn mt-3">
                                        Sign Up and Start Volunteering
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
    </>
  );
};

export default InfluencerLeaderboardPage;
