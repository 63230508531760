import React, { useState } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import icon from "../../images/cta/top-icon.png";

import abImg2 from "../../images1/organization/employee-engagement/helping.png";
import abImg4 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-44.png";
import abImg5 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-5.jpg";
import abImg8 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-8.png";
import SectionTitle from "../SectionTitle/SectionTitle";
import TrackEmployee from "../Volunteer/TrackEmployee";
import RangeOfTasks from "../Volunteer/RangeOfTasks";
import AuthModal from "../../auth-modal";

const EmployeeEngagementPage = (props) => {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };
  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 500,
  //   autoplay: true,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  // };
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-5">
              <div className="w-lg-50 w-100">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    {/* <span>Welcome to Sevak Army!</span> */}
                    <h2 className="about-heading-third text-titleeee">
                      Helping Employees Make a Difference
                    </h2>
                  </div>
                  <p>
                    At Sevak Army, we believe every employee can help make the
                    world a better place. By encouraging your employees to join
                    volunteer activities, your organisation can improve its
                    social responsibility and make employees feel good about
                    their work. Our platform makes it simple for organisations
                    to invite employees, track their volunteer hours, and
                    celebrate their efforts.
                    <p style={{ fontWeight: "500" }}>
                      Get your team involved—invite them to sign up on Sevak
                      Army and choose tasks that matter to them today!
                    </p>
                  </p>
                </div>
              </div>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={abImg2} width={1200} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-padding">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg3} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} alt="img" height={520} width={560} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <h2 className="about-heading-second text-titleeee">
                        Engaging Employees in Volunteering
                      </h2>
                    </div>
                    <p>
                      Getting your employees involved in volunteering is easy
                      with Sevak Army. Once your organisation is registered, you
                      can invite employees to choose volunteer tasks through our
                      platform. Here's how we help:
                      <p className="mt-2 mb-1">
                        <b>Custom Volunteer Tasks:</b> Employees pick tasks that
                        match their skills and interests. From helping the
                        environment to community projects, there are options for
                        everyone.
                      </p>
                      <p>
                        <b>Track Progress:</b> Easily see who’s involved, how
                        many hours they’ve volunteered, and the tasks they've
                        completed.
                      </p>
                      Sevak Army makes it simple for organisations to encourage
                      meaningful volunteer work while keeping employees engaged
                      in causes they care about.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
      <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="col col-lg-8 col-md-10 ">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="text-center text-titleeee">Track Employee Contributions with Ease</h2>
              </div>
              <div className="wpo-event-details-wrap">
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              Sevak Army’s platform includes powerful tools for tracking employee volunteer efforts.
                              Through the <b>Volunteer Progress</b> dashboard, organizations can monitor and assess
                              their employees’ participation, ensuring that everyone has the opportunity to contribute
                              and make an impact.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">Here’s how it works:</p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Real-Time Data:</strong>
                              Track volunteer hours, tasks completed and the overall impact of each employee’s efforts
                              in real-time.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Detailed Reports:</strong>
                              Generate reports that highlight your company’s collective contributions.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Individual Employee Profiles:</strong>
                              Each employee has their volunteer profile where they can track their progress.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              Tracking progress not only ensures that your CSR goals are met, but it also recognises the
                              dedication of your employees—boosting morale and encouraging further participation.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <TrackEmployee />
        {/* <section>
       <div className="col-12 d-flex align-items-center justify-content-center pt-100">
          <div className="col col-lg-8 col-md-10">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="text-center text-titleeee">Explore a Range of Tasks for Your Employees.</h2>
              </div>
              <div className="wpo-event-details-wrap">
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              At Sevak Army, we understand that volunteerism is not one-size-fits-all. That’s why we
                              offer a diverse array of <b>Volunteering Opportunities</b> tailored to different
                              industries, causes and company cultures.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">Available Volunteer Tasks Include:</p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Community Development:</strong>
                              From building homes to organising community events, these tasks focus on improving local
                              infrastructure and fostering stronger communities.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Environmental Initiatives:</strong>
                              Tasks like beach clean-ups, tree planting and wildlife conservation are perfect for
                              companies committed to sustainability and environmental stewardship.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Education & Mentorship:</strong>
                              Empower the next generation by volunteering in schools, mentoring underprivileged students
                              and leading workshops that equip young people with skills for the future.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Healthcare & Wellness:</strong>
                              Get involved in health camps, blood donation drives and mental health awareness campaigns.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Poverty Alleviation:</strong>
                              Help provide basic necessities like food, clothing and shelter to those living in poverty.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              By offering a wide range of volunteering opportunities, Sevak Army ensures that every
                              company can find tasks that align with their mission and values, while also giving
                              employees the chance to volunteer for causes they are passionate about.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section> */}

        {/* below section not in requirements */}
        {/* section start */}

        {/* <div className="section-padding pb-0">
          <RangeOfTasks />
        </div> */}

        {/* section end */}
        <section className="section-padding pt-100">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="flex-containerrr justify-content-center gap-5">
                <div className="w-lg-50 w-100">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      {/* <span>Welcome to Sevak Army!</span> */}
                      <h2 className="about-heading-third text-titleeee">
                        Celebrate Volunteer Work with Digital Badges
                      </h2>
                    </div>
                    <p>
                      Recognising and celebrating your employees' efforts is key
                      to keeping them engaged
                      <p className="mt-2 mb-1">
                        <b>Achievement Badges:</b> Employees earn digital badges
                        when they reach certain milestones, like completing a
                        set number of volunteer hours or tasks.
                      </p>
                      <p className="mb-1">
                        <b>Top Volunteer Recognition:</b> Employees who
                        volunteer regularly can appear on the leaderboard,
                        showcasing the top contributors.{" "}
                      </p>
                      <p className="mb-1">
                        <b>organisation Celebrations:</b> Highlight your top
                        volunteers during organisation events or meetings to
                        recognise their dedication.
                      </p>
                      Celebrating these milestones boosts engagement and shows
                      that your organisation values giving back.
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left-side">
                    <img src={abImg6} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg5} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg8} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg8} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="about-heading-second text-titleeee">
                      Why Employee Engagement Matters?
                    </h2>
                  </div>
                  <p>
                    Getting employees involved in volunteering isn’t just good
                    for the community—it helps your organisation, too
                    <p className="mt-2 mb-1">
                      <b>Better Employee Retention:</b> Employees who volunteer
                      feel more connected to their organisation and are more
                      likely to stay long-term.
                    </p>
                    <p className="mb-1">
                      <b>Improved Morale:</b> Volunteering builds stronger
                      relationships among employees and boosts team spirit.{" "}
                    </p>
                    <p className="mb-1">
                      <b>Stronger organisation Reputation:</b> organisations
                      that support social good are seen in a better light by
                      customers, partners, and job seekers.
                    </p>
                    <p className="mb-1">
                      <b>Employee Growth:</b> Volunteering helps employees
                      develop new skills, grow as leaders, and feel more
                      satisfied with their jobs.
                    </p>
                    Engaging employees in volunteering builds a positive work
                    culture where giving back and employee happiness go
                    together.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding pt-0">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2 mb-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Get started with employee engagement today.</span>
                    <p className="text-center text-white pt-3">
                      Sevak Army helps your organisation motivate, engage, and
                      track employee volunteering. Our easy-to-use tools makes
                      it simple to encourage volunteerism and build a culture of
                      giving back that benefits your organisation and the
                      community.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100 mt-3">
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Register your organisation now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AuthModal show={show} setShow={setShow} />
    </>
  );
};

export default EmployeeEngagementPage;
