import React, { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import Logo from "../../images/logo.svg";
import Footer from "../footer/Footer";
import Scrollbar from "../scrollbar/scrollbar";
import PageTitle from "../pagetitle/PageTitle";
import NgoKeyFeaturesSection from "./NgoKeyFeaturesSection";

function NgoKeyFeatures({show, setShow}) {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={"wpo-site-header"} />
            <PageTitle pageTitle={'Key Features'} pagesub={'Key Features'} />
            <NgoKeyFeaturesSection show={show} setShow={setShow}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
}

export default NgoKeyFeatures;
