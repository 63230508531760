import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Shape from "../../images/testimonial/bg.png";

import image1 from "../../images1/team/sevak-army-team-4.jpg";
import image2 from "../../images1/team/sevak-army-team-9.jpg";
import image3 from "../../images1/team/sevak-army-team-1.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";

function NgoHomeTestimonal({ props }) {
  const TestimonialData = [
    {
      id: "01",
      text: "As an NGO director, I believe Sevak Army is a game-changer for organisations like ours. It helps us find committed volunteers and provides resources to increase our impact. Since joining, we’ve seen more community involvement and support. Together, we are building a sustainable future for the people we serve.",
      title: "— Rajesh K., NGO Director",
      // subtitle: "Manager",
      image: image1,
    },
    {
      id: "02",
      text: "Sevak Army has truly transformed the way we engage with volunteers. The platform helps us find skilled individuals quickly, which has made a huge difference in the success of our projects. With Sevak Army, we've been able to reach more people and improve lives faster than ever before.",
      title: '— Priya S., NGO Coordinator',
      // subtitle: "Leader",
      image: image2,
    },
    {
      id: "03",
      text: "For our NGO, Sevak Army has been a blessing. We no longer struggle with volunteer recruitment, and the easy-to-use platform makes managing tasks simple. Our work has become more organised and efficient, and we’ve seen a significant boost in community support.",
      // subtitle: "Driving Environmental Sustainability Initiatives",
      title: "— Anjali M., NGO Project Manager",
      image: image3,
    },

    // {
    //     id: "04",
    //     text: "This is the best agency for non profit activities.I love their work so much. I wish one day they would remove all the poverty from the world. I will be there when they need.",
    //     title: "Albert Flores",
    //     subtitle: "President of Sales",
    //     image: image2
    // },
  ];
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className={"" + props} style={{paddingBottom:'100px'}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle subtitle="" title="Testimonials" />
          </div>
        </div>
        <div className="wpo-testimonial-slider mt-3">
          <Slider {...settings}>
            {TestimonialData.map((Testimonial, item) => (
              <div className="testimonial-item" key={item}>
                <div className="content" style={{height:'640px'}}>
                  <div className="icon">
                    <i className="flaticon-quote"></i>
                  </div>
                  <p style={{ textAlign: "justify" }}>{Testimonial.text}</p>
                  <div className="shape">
                    <img src={Shape} alt="" />
                  </div>
                </div>
                <div className="bottom-content">
                  <div className="image">
                    <img src={Testimonial.image} alt="" />
                  </div>
                  <div className="text">
                    <h3>{Testimonial.title}</h3>
                    <span>{Testimonial.subtitle}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default NgoHomeTestimonal;
