import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.svg";
import Img1 from "../images1/volunteer/home/sevak-army-volunteer-home-3.png";
import { FormFeedback } from "reactstrap";

const NewsletterPopup = ({ shownewsletter, setShowNewsletter }) => {
  const handleClose = () => {
    setShowNewsletter(false);
  };

  return (
    <div
      className={`modal fade bd-example-modal-lg ${
        shownewsletter ? "show d-block" : "d-none"
      }`}
      tabIndex="-1"
      data-target=".bd-example-modal-lg"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div
        className="modal-dialog modal-xl d-flex justify-content-center"
        role="document"
      >
        <div className="modal-content d-flex p-3">
          <div className="">
            <div className="modal-header w-100 d-flex justify-content-end">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5f6368"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                </svg>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between gap-5 flex-lg-row flex-column">
              <div className="py-4">
                <img
                  src={Img1}
                  className="h-100"
                  width={600}
                  alt="Sevak Army"
                />
              </div>
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 d-flex justify-content-center align-items-center ">
                  <img src={Logo} width={80} />
                </div>
                <h2
                  className="mt-4 text-center w-100"
                  style={{ fontSize: "40px", fontWeight: "700" }}
                >
                  Stay Informed, Stay Inspired: <br />
                  <span className="secondary-headerrrr">
                    {" "}
                    Join the Sevak Army Newsletter!
                  </span>
                </h2>
                <form className="mt-5 w-100">
                  <div className="form-group py-2">
                    <label
                      className="mb-1"
                      style={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                      htmlFor="email"
                    >
                      Your Email Address
                    </label>
                    <input
                      style={{
                        borderRadius: 10,
                        outline: "none",
                      }}
                      type="text"
                      className="form-control py-3"
                      id="email"
                      name="email"
                      // value=""
                      // onChange={handleInputChange}
                      placeholder="Your Email Address"
                    />
                    {/* {formErrors.fullName && <p className="text-danger">{formErrors.fullName}</p>} */}
                  </div>

                  <div className="w-100 d-flex justify-content-center align-items-center mt-4 mb-2">
                    <button
                      className="theme-btn w-100"
                      style={{ fontWeight: 700 }}
                    >
                      Subscribe to our Newsletter
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterPopup;
