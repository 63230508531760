import React from "react";

const TermsAndConditionsContent = () => {
  return (
    <div className="wpo-terms-wrap pt-0 mt-3 container section-padding">
      <h1 className="fw-bold w-100 text-center mb-5">Terms and Conditions</h1>
      <h2 style={{fontSize:'38px'}}>Agreement to Our Legal Terms</h2>
      <p>
        We are Jainam Jivika Foundation ("Company", "we", "us" or "our"), a company registered in India at 3, Jainam
        Tower, 3, Sai Park, Belthika Nagar, Thergaon (Chinchwad), Pune, Maharashtra 411033. We operate the website{" "}
        <a href="https://jjfindia.com/index.php">https://jjfindia.com/index.php</a> (the "Site"), as well as any other
        related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the
        "Services"). SevakArmy.com is a comprehensive platform designed to empower individuals, NGOs, organisations,
        schools, colleges, influencers and volunteers to impact their communities positively. It serves as a hub where
        these groups can connect, collaborate and find meaningful opportunities that align with their interests.
        SevakArmy.com aims to build stronger, more resilient communities through volunteer opportunities and community
        service projects. As an initiative of the Jainam Jivika Foundation, all donations made through the platform
        support the foundation's mission and initiatives.
      </p>
      <p>
        You can contact us by email at info@jjfindia.com or by mail to 3, Jainam Tower, 3, Sai Park, Belthika Nagar,
        Thergaon (Chinchwad), Pune, Maharashtra 411033, India.
      </p>
      <p>
        These terms constitute a legally binding agreement between you, whether personally or on behalf of an entity
        ("you") and Jainam Jivika Foundation, concerning your access to and use of the Services. You agree that by
        accessing the Services, you have read, understood and agreed to be bound by these legal terms. If you do not
        agree with all of these legal terms, you are expressly prohibited from using the services and must discontinue
        use immediately.
      </p>
      <p>
        We will provide you with prior notice of any scheduled changes to the Services you are using. As stated in the
        email, the modified legal terms will become effective upon posting or notifying you by info@jjfindia.com. By
        continuing to use the services after the effective date of any changes, you agree to be bound by the modified
        terms.
      </p>
      <p>
        The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted
        to use or register for the Services.
      </p>
      <h2 style={{fontSize:'38px'}}>1. Our Services</h2>
      <p>
        The SevakArmy.com platform is intended to help individuals, organisations, NGOs, schools/colleges and
        influencers engage in and contribute to community service initiatives. The platform connects people with
        volunteer opportunities and facilitates donations for social welfare projects. Our Services focus on promoting
        volunteerism and community-building initiatives.
      </p>
      <h2 style={{fontSize:'38px'}}>2. Intellectual Property Rights</h2>
      <h3>Our intellectual property</h3>
      <p>
        We are the owners or authorised licensees of all intellectual property rights in the Services, which include but
        are not limited to:
        <ul>
          <li>Source code and software underlying the platform.</li>
          <li>Website design and user interface.</li>
          <li>Multimedia content (images, audio, video, text, photographs).</li>
          <li>Trademarks, logos and service marks used in the platform (collectively, the "Content" and "Marks").</li>
        </ul>
        These are protected under Indian and international copyright laws.
      </p>
      <h3>Permitted Use</h3>
      <p>
        You are permitted to access the Services for personal or internal business use. You may not copy, modify,
        distribute or commercially exploit any of the Content or Marks without our prior written consent. If you wish to
        use any Content or Marks for commercial purposes, contact us at info@jjfindia.com for explicit permission. Any
        violation of these Intellectual Property Rights may result in the immediate suspension or termination of your
        access to the Services and may lead to legal action.
      </p>
      <h2 style={{fontSize:'38px'}}>3. User Representations</h2>
      <p>
        By registering for or using the Services, you agree and represent that:
        <ul>
          <li>
            <p className="mb-1"> <strong>True Information:</strong> You provide true, accurate, current and complete information during registration and
              maintain its accuracy over time.</p>
          </li>
          <li>
            <p className="mb-1"><strong>Legal Authority:</strong> You are of legal age (at least 18 years old) or otherwise legally capable of entering into
              binding agreements.</p>
          </li>
          <li><p className="mb-1"><strong>Compliant Use</strong>: Your use of the Services will comply with applicable laws and these Terms.</p></li>
        </ul>
        Inaccurate or false information can result in suspension or termination of your account and access to the
        Services.
      </p>
      <h2 style={{fontSize:'38px'}}>4. User Registration</h2>
      <p>
        To access some features of our Services, you must register and create an account. You agree to:
        <ul>
          <li>
            Account Security: Keep your login credentials confidential. You are responsible for all actions under your
            account.
          </li>
          <li>
            Username: We reserve the right to reclaim or modify any username deemed inappropriate, offensive or
            misleading.
          </li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>5. Prohibited Activities</h2>
      <p>
        You may not access or use the Services for any purpose other than those for which we make the Services
        available. The Services may not be used in connection with any commercial endeavours except those specifically
        endorsed or approved by us. Misuse of the Services can result in suspension, account termination or legal
        action.
      </p>
      <p>
        As a user of the Services, you agree not to engage in the following prohibited activities:
        <ul>
          <li>Data Mining and Extraction</li>
          <li>Deception and Fraud</li>
          <li>Circumventing Security Features</li>
          <li>Defamation and Harmful Actions</li>
          <li>Improper Use of Support Services</li>
          <li>Violation of Laws and Regulations</li>
          <li>Unauthorised Framing or Linking</li>
          <li>Uploading Malicious Content</li>
          <li>Automated System Use</li>
          <li>Tampering with Copyright Information</li>
          <li>Impersonation and Identity Theft</li>
          <li>Spyware and Passive Collection Mechanisms</li>
          <li>Disruption and Interference</li>
          <li>Harassment of Employees or Agents</li>
          <li>Circumventing Access Controls</li>
          <li>Unauthorised Software Use</li>
          <li>Automated Systems and Bots</li>
          <li>Unauthorised Email Collection</li>
          <li>Use of Services for Competitive Purposes</li>
          <li>Profile Transfer</li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>6. User-Generated Contributions</h2>
      <p className="mb-0">
        The Services may offer opportunities to interact through various features, such as blogs, message boards, forums
        and other channels where you can create, submit, post, display and share content with us and other users. This
        content may include but is not limited to, text, writings, videos, audio, photographs, graphics, comments,
        suggestions or personal information (collectively, “Contributions”). Your Contributions may be viewable by other
        users of the Services and on third-party platforms. Please be aware that any Contributions you share may be
        treated as non-confidential and non-proprietary.
      </p>
      <p>
        By creating and making available Contributions, you represent and warrant that:
        <ul>
          <li>
            <p className="mb-1">  <strong>Ownership:</strong> You own the Contributions or have the necessary rights, licenses, consents and permissions to use
              them and to grant us rights.</p>
          </li>
          <li>
            <p className="mb-1"><strong>No Harmful Content:</strong> Your Contributions do not include content that is harmful, unlawful, inappropriate or
              that violates any applicable law.</p>
          </li>
          <li>
            <p className="mb-1"> <strong> Waiver:</strong> You waive all moral rights to the Contributions, and you have no right to enforce any claims against
              us arising from their use.</p>
          </li>
        </ul>
        We may remove or block Contributions that violate these Terms.
      </p>
      <h2 style={{fontSize:'38px'}}>7. Contribution Licence</h2>
      <p>
        By posting your Contributions to any part of the Services, you automatically grant (and warrant that you have
        the right to grant) to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable,
        royalty-free, worldwide right and licence to host, use, copy, reproduce, disclose, sell, resell, publish,
        broadcast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt, distribute
        and prepare derivative works of your Contributions. This includes commercial use.
      </p>
      <h2 style={{fontSize:'38px'}}>8. Guidelines for Reviews</h2>
      <p>
        When posting a review, you must comply with the following guidelines:
        <ul>
          <li><p className="mb-1"><strong>Honesty:</strong> Reviews must be based on your real experience with the Services.</p></li>
          <li><p className="mb-1"><strong>Prohibited Content:</strong> Do not post defamatory, abusive or misleading reviews.</p></li>
          <li><p className="mb-1"><strong>Conflict of Interest:</strong> Do not post reviews of services offered by your own competitors.</p></li>
          <li><p className="mb-1"><strong>Review Deletion:</strong> We reserve the right to remove any review that violates these guidelines.</p></li>
        </ul>
      </p>{" "}
      <h2 style={{fontSize:'38px'}}>9. Social Media Integration</h2>
      <p>
        SevakArmy.com allows users to link their accounts with various third-party social media accounts to enhance
        their experience. By linking your social media accounts, you agree to the following:
        <ul>
          <li>
            <p className="mb-1"> <strong>Account Integration:</strong> You may link your SevakArmy.com account with a third-party social media account (e.g.
              Facebook, Instagram, LinkedIn) by providing your login information for those accounts. You warrant that you
              have the legal right to disclose this information and grant us access.</p>
          </li>
          <li>
            <p className="mb-1"><strong>Accessing Social Media Content:</strong> Once linked, SevakArmy.com may access, retrieve and store content such as
              friend lists, profile data and posts from your third-party accounts. This content will be displayed on the
              SevakArmy.com platform and made available to enhance your user experience.</p>
          </li>
          <li>
            <p className="mb-1"><strong>Privacy and Permissions:</strong> Content shared from your third-party accounts will follow the privacy settings you
              have established on those platforms. SevakArmy.com will not alter or override any privacy settings. You are
              responsible for managing these settings on the social media platform.</p>
          </li>
          <li>
            <p className="mb-1"><strong>Social Media Content Use:</strong> We may use social media content to identify contacts who are also registered on
              SevakArmy.com or for other platform-related purposes. However, we are not responsible for monitoring the
              accuracy, legality or appropriateness of the content retrieved from these platforms.</p>
          </li>
          <li>
            <p className="mb-1"><strong>Deactivating Social Media Integration:</strong> You can disable the connection between SevakArmy.com and your
              third-party accounts at any time through your account settings or by contacting us. Upon deactivation, we
              will attempt to delete any content we obtained from your social media accounts, excluding any profile
              information.</p>
          </li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>10. Third-Party Websites and Content</h2>
      <p>
        SevakArmy.com may provide links to third-party websites or display third-party content on the platform. By
        interacting with third-party content or websites, you agree to the following:
        <ul>
          <li>
            <p className="mb-1"><strong>Third-Party Responsibility:</strong> SevakArmy.com does not investigate or monitor third-party content or websites
              for accuracy, legality or appropriateness. We are not responsible for any third-party content or for any
              claims or damages arising from your interaction with such content.</p>
          </li>
          <li>
            <p className="mb-1"><strong> Risk of Engagement:</strong> Accessing third-party websites or using third-party content is entirely at your own
              risk. These websites or platforms may have their own terms of service and privacy policies that are beyond
              the control of SevakArmy.com. We advise you to review their terms before interacting with them.</p>
          </li>
          <li>
            <p className="mb-1"><strong>No Endorsement:</strong> The inclusion of third-party links or content does not imply that SevakArmy.com endorses,
              approves or guarantees the accuracy or reliability of the information provided by these third parties.</p>
          </li>
          <li>
            <p className="mb-1">            <strong>Purchases on Third-Party Platforms:</strong> Any transactions made through third-party websites, including the
              purchase of products or services, are between you and the third-party provider. SevakArmy.com bears no
              responsibility for these transactions and disclaims any liability for issues arising from such purchases.</p>
          </li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>11. Services Management</h2>
      <p>
        To maintain the integrity of the platform, SevakArmy.com reserves the right to manage the Services in the
        following ways:
        <ul>
          <li>
            <p className="mb-1">   <strong>Platform Monitoring: </strong>We may monitor user activity on the platform to ensure compliance with our terms and to
              prevent misuse. This includes monitoring for violations of these terms, as well as illegal or inappropriate
              behaviour.</p>
          </li>
          <li>
            <p className="mb-1"><strong>Taking Action:</strong> If you violate these legal terms or engage in any unlawful activity, SevakArmy.com reserves
              the right to take legal action, including reporting the violation to law enforcement authorities,
              restricting your access or disabling your account.</p>
          </li>
          <li>
            <p className="mb-1"> <strong>Content Moderation:</strong> SevakArmy.com may, at its discretion, limit, restrict or disable access to certain
              content or user contributions. We may also remove files or content deemed excessive in size or detrimental
              to our systems’ performance.</p>
          </li>
          <li>
            <p className="mb-1">  <strong>Maintenance and Operations:</strong> We reserve the right to remove, suspend or limit access to any part of the
              platform to ensure proper functionality or conduct necessary maintenance.</p>
          </li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>12. Privacy Policy</h2>
      <p>
        Your privacy is of utmost importance to us at SevakArmy.com. We collect, process and store your data in
        compliance with relevant data protection laws. By using our Services, you consent to the collection and use of
        your personal data as described in our Privacy Policy.
        <ul>
          <li>
           <p className="mb-1"> <strong>Data Security:</strong> We take necessary precautions to protect your personal data. Please refer to our Privacy
           Policy for detailed information on how we handle your data.</p>
          </li>
          <li>
            <p className="mb-1"><strong>International Data Transfer:</strong> If you access the Services from outside India, you acknowledge and agree that
            your data will be transferred to and processed in India, as we operate under Indian data protection laws.</p>
          </li>
          <li>
            <p className="mb-1"><strong>User Responsibility:</strong> You are responsible for the security of your login credentials and any personal
            information you provide on SevakArmy.com.</p>
          </li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>13. Term and Termination</h2>
      <p>
        These legal terms remain in effect as long as you use SevakArmy.com Services. However, we reserve the right to
        terminate or suspend your access for any reason, with or without notice, under the following circumstances:
        <ul>
          <li>
            <p className="mb-1"><strong>Violation of Terms:</strong> If you breach any provision of these legal terms or engage in illegal activities, your
            access to the Services may be terminated immediately. SevakArmy.com may also take legal action, including
            civil or criminal proceedings, if necessary.</p>
          </li>
          <li>
          <p className="mb-1"> <strong> Account Deactivation:</strong> If your account is terminated or suspended, you are prohibited from creating a new
          account under your own name or the name of any third-party.</p>
          </li>
          <li>
            <p className="mb-1"><strong>User Responsibility Post-Termination:</strong> Upon termination, you remain responsible for any prior activity on the
            platform and any obligations you owe to SevakArmy.com will survive the termination.</p>
          </li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>14. Modifications and Interruptions</h2>
      <p>
        SevakArmy.com reserves the right to modify, update or remove any aspect of the Services at any time without
        prior notice. This includes changes in content, features or the discontinuation of parts of the platform.
        SevakArmy.com is under no obligation to provide updates or corrections to the Services. We cannot guarantee
        uninterrupted availability of the Services at all times. Periodic maintenance, technical issues or unforeseen
        circumstances may cause delays or interruptions in the Services. SevakArmy.com will not be liable for any
        inconvenience, damage or loss arising from the unavailability of the platform during such times. The decision to
        update, suspend or discontinue any feature is at SevakArmy.com’s sole discretion.
      </p>
      <h2 style={{fontSize:'38px'}}>15. Governing Law</h2>
      <p>
        These legal terms shall be governed by the laws of India. All disputes or legal issues arising in connection
        with these terms will be subject to the exclusive jurisdiction of the courts located in India. Both
        SevakArmy.com and the users agree to submit to the legal process within the Indian judicial system.
      </p>
      <h2 style={{fontSize:'38px'}}>16. Dispute Resolution</h2>
      <p>
        Informal Negotiations In the event of any dispute between SevakArmy.com and its users, the parties agree to
        attempt to resolve the issue informally before initiating legal or arbitration proceedings. Either party can
        provide written notice of the dispute, initiating a 30-day informal resolution process. Binding Arbitration If
        the dispute cannot be resolved through informal negotiations, it shall be referred to binding arbitration in
        India. Arbitration will be conducted under the rules of the European Arbitration Chamber, with the proceedings
        in English. The number of arbitrators shall be four (4) and the arbitration will only cover disputes between the
        involved parties. Restrictions Arbitration will only involve the dispute between the parties individually and no
        group or class actions will be permitted. Additionally, arbitration cannot address claims on behalf of the
        public or other parties not directly involved in the proceedings. Exceptions Certain disputes, such as those
        involving intellectual property rights, privacy violations or allegations of unauthorised use, may not be
        subject to arbitration and can be pursued in the court of competent jurisdiction.
      </p>
      <h2 style={{fontSize:'38px'}}>17. Corrections</h2>
      <p>
        SevakArmy.com strives to ensure the accuracy of the information provided through its platform. However, there
        may be instances where errors, inaccuracies or omissions occur in the Services’ content, including pricing,
        availability and descriptions. SevakArmy.com reserves the right to correct any such errors and make necessary
        updates at any time, without prior notice. We are not responsible for any consequences arising from such
        corrections.
      </p>
      <h2 style={{fontSize:'38px'}}>18. Disclaimer</h2>
      <p>
        SevakArmy.com offers its Services on an “as-is” and “as-available” basis and users agree to use the platform at
        their own risk. To the fullest extent allowed by law, SevakArmy.com disclaims all warranties, both express and
        implied, including but not limited to:
        <ul>
          <li>The accuracy or completeness of the Services or any linked third-party content.</li>
          <li>Any personal injury or property damage arising from accessing or using the Services.</li>
          <li>Unauthorised access to or use of personal or financial information stored on our servers.</li>
          <li>
            Any interruptions, errors or security breaches, including viruses or harmful components transmitted through
            the platform.
          </li>
        </ul>
        You acknowledge and agree that SevakArmy.com will not be liable for any damages arising from your use of the
        Services, whether direct, indirect, incidental, consequential, special or punitive.
      </p>
      <h2 style={{fontSize:'38px'}}>19. Indemnification</h2>
      <p>
        By using SevakArmy.com Services, you agree to indemnify and hold harmless SevakArmy.com, its affiliates,
        partners, employees, agents and representatives from any claims, losses, liabilities, damages, costs or
        expenses, including legal fees, arising from:
        <ul>
          <li>Your violation of these legal terms.</li>
          <li>Your violation of any rights of a third-party, including intellectual property or privacy rights.</li>
          <li>Your violation of any applicable laws or regulations.</li>
          <li>Your use of the Services in any manner that causes damage or harm to SevakArmy.com or others.</li>
        </ul>
      </p>
      <h2 style={{fontSize:'38px'}}>20. Entire Agreement</h2>
      <p>
        These legal terms constitute the entire agreement between you and SevakArmy.com regarding the use of the
        Services. Any failure by SevakArmy.com to enforce any provision of these terms shall not be construed as a
        waiver of that provision or any other right. If any part of these terms is found to be invalid or unenforceable,
        that provision shall be interpreted in accordance with applicable law and the remaining provisions shall remain
        in effect.
      </p>
      <h2 style={{fontSize:'38px'}}>21. Contact Information</h2>
      <p>
        For questions or concerns regarding these legal terms, please contact us at{" "}
        <a href="mailto:info@SevakArmy.com ">info@SevakArmy.com </a> or through our{" "}
        <a href="/contact-us">contact page</a>.
      </p>
      <h2 style={{fontSize:'38px'}}>9. Social Media Integration</h2>
      <p>
        SevakArmy.com allows users to link their accounts with various third-party social media accounts to enhance
        their experience. By linking your social media accounts, you agree to the following:
      </p>
      <h3>Account Integration:</h3>
      <p>
        You may link your SevakArmy.com account with a third-party social media account (e.g. Facebook, Instagram,
        LinkedIn) by providing your login information for those accounts. You warrant that you have the legal right to
        disclose this information and grant us access.
      </p>
      <h3>Accessing Social Media Content:</h3>
      <p>
        Once linked, SevakArmy.com may access, retrieve and store content such as friend lists, profile data and posts
        from your third-party accounts. This content will be displayed on the SevakArmy.com platform and made available
        to enhance your user experience.
      </p>
      <h3>Privacy and Permissions:</h3>
      <p>
        Content shared from your third-party accounts will follow the privacy settings you have established on those
        platforms. SevakArmy.com will not alter or override any privacy settings. You are responsible for managing these
        settings on the social media platform.
      </p>
      <h3>Social Media Content Use:</h3>
      <p>
        We may use social media content to identify contacts who are also registered on SevakArmy.com or for other
        platform-related purposes. However, we are not responsible for monitoring the accuracy, legality or
        appropriateness of the content retrieved from these platforms.
      </p>
      <h3>Deactivating Social Media Integration:</h3>
      <p>
        You can disable the connection between SevakArmy.com and your third-party accounts at any time through your
        account settings or by contacting us. Upon deactivation, we will attempt to delete any content we obtained from
        your social media accounts, excluding any profile information.
      </p>
      <h2 style={{fontSize:'38px'}}>10. Third-Party Websites and Content</h2>
      <p>
        SevakArmy.com may provide links to third-party websites or display third-party content on the platform. By
        interacting with third-party content or websites, you agree to the following:
      </p>
      <h3>Third-Party Responsibility:</h3>
      <p>
        SevakArmy.com does not investigate or monitor third-party content or websites for accuracy, legality or
        appropriateness. We are not responsible for any third-party content or for any claims or damages arising from
        your interaction with such content.
      </p>
      <h3>Risk of Engagement:</h3>
      <p>
        Accessing third-party websites or using third-party content is entirely at your own risk. These websites or
        platforms may have their own terms of service and privacy policies that are beyond the control of SevakArmy.com.
        We advise you to review their terms before interacting with them.
      </p>
      <h3>No Endorsement:</h3>
      <p>
        The inclusion of third-party links or content does not imply that SevakArmy.com endorses, approves or guarantees
        the accuracy or reliability of the information provided by these third parties.
      </p>
      <h3>Purchases on Third-Party Platforms:</h3>
      <p>
        Any transactions made through third-party websites, including the purchase of products or services, are between
        you and the third-party provider. SevakArmy.com bears no responsibility for these transactions and disclaims any
        liability for issues arising from such purchases.
      </p>
      <h2 style={{fontSize:'38px'}}>11. Services Management</h2>
      <p>
        To maintain the integrity of the platform, SevakArmy.com reserves the right to manage the Services in the
        following ways:
      </p>
      <h3>Platform Monitoring:</h3>
      <p>
        We may monitor user activity on the platform to ensure compliance with our terms and to prevent misuse. This
        includes monitoring for violations of these terms, as well as illegal or inappropriate behaviour.
      </p>
      <h3>Taking Action:</h3>
      <p>
        If you violate these legal terms or engage in any unlawful activity, SevakArmy.com reserves the right to take
        legal action, including reporting the violation to law enforcement authorities, restricting your access or
        disabling your account.
      </p>
      <h3>Content Moderation:</h3>
      <p>
        SevakArmy.com may, at its discretion, limit, restrict or disable access to certain content or user
        contributions. We may also remove files or content deemed excessive in size or detrimental to our systems’
        performance.
      </p>
      <h3>Maintenance and Operations:</h3>
      <p>
        We reserve the right to remove, suspend or limit access to any part of the platform to ensure proper
        functionality or conduct necessary maintenance.
      </p>
      <h2 style={{fontSize:'38px'}}>12. Privacy Policy</h2>
      <p>
        Your privacy is of utmost importance to us at SevakArmy.com. We collect, process and store your data in
        compliance with relevant data protection laws. By using our Services, you consent to the collection and use of
        your personal data as described in our Privacy Policy.
      </p>
      <h3>Data Security:</h3>
      <p>
        We take necessary precautions to protect your personal data. Please refer to our Privacy Policy for detailed
        information on how we handle your data.
      </p>
      <h3>International Data Transfer:</h3>
      <p>
        If you access the Services from outside India, you acknowledge and agree that your data will be transferred to
        and processed in India, as we operate under Indian data protection laws.
      </p>
      <h3>User Responsibility:</h3>
      <p>
        You are responsible for the security of your login credentials and any personal information you provide on
        SevakArmy.com.
      </p>
      <h2 style={{fontSize:'38px'}}>13. Term and Termination</h2>
      <p>
        These legal terms remain in effect as long as you use SevakArmy.com Services. However, we reserve the right to
        terminate or suspend your access for any reason, with or without notice, under the following circumstances:
      </p>
      <h3>Violation of Terms:</h3>
      <p>
        If you breach any provision of these legal terms or engage in illegal activities, your access to the Services
        may be terminated immediately. SevakArmy.com may also take legal action, including civil or criminal
        proceedings, if necessary.
      </p>
      <h3>Account Deactivation:</h3>
      <p>
        If your account is terminated or suspended, you are prohibited from creating a new account under your own name
        or the name of any third-party.
      </p>
      <h3>User Responsibility Post-Termination:</h3>
      <p>
        Upon termination, you remain responsible for any prior activity on the platform and any obligations you owe to
        SevakArmy.com will survive the termination.
      </p>
      <h2 style={{fontSize:'38px'}}>14. Modifications and Interruptions</h2>
      <p>
        SevakArmy.com reserves the right to modify, update or remove any aspect of the Services at any time without
        prior notice. This includes changes in content, features or the discontinuation of parts of the platform.
        SevakArmy.com is under no obligation to provide updates or corrections to the Services.
      </p>
      <p>
        We cannot guarantee uninterrupted availability of the Services at all times. Periodic maintenance, technical
        issues or unforeseen circumstances may cause delays or interruptions in the Services. SevakArmy.com will not be
        liable for any inconvenience, damage or loss arising from the unavailability of the platform during such times.
        The decision to update, suspend or discontinue any feature is at SevakArmy.com’s sole discretion.
      </p>
      <h2 style={{fontSize:'38px'}}>15. Governing Law</h2>
      <p>
        These legal terms shall be governed by the laws of India. All disputes or legal issues arising in connection
        with these terms will be subject to the exclusive jurisdiction of the courts located in India. Both
        SevakArmy.com and the users agree to submit to the legal process within the Indian judicial system.
      </p>
      <h2 style={{fontSize:'38px'}}>16. Dispute Resolution</h2>
      <h3>Informal Negotiations</h3>
      <p>
        In the event of any dispute between SevakArmy.com and its users, the parties agree to attempt to resolve the
        issue informally before initiating legal or arbitration proceedings. Either party can provide written notice of
        the dispute, initiating a 30-day informal resolution process.
      </p>
      <h3>Binding Arbitration</h3>
      <p>
        If the dispute cannot be resolved through informal negotiations, it shall be referred to binding arbitration in
        India. Arbitration will be conducted under the rules of the European Arbitration Chamber, with the proceedings
        in English. The number of arbitrators shall be four (4) and the arbitration will only cover disputes between the
        involved parties.
      </p>
      <h3>Restrictions</h3>
      <p>
        Arbitration will only involve the dispute between the parties individually and no group or class actions will be
        permitted. Additionally, arbitration cannot address claims on behalf of the public or other parties not directly
        involved in the proceedings.
      </p>
      <h3>Exceptions</h3>
      <p>
        Certain disputes, such as those involving intellectual property rights, privacy violations or allegations of
        unauthorised use, may not be subject to arbitration and can be pursued in the court of competent jurisdiction.
      </p>
      <h2 style={{fontSize:'38px'}}>17. Corrections</h2>
      <p>
        SevakArmy.com aims to maintain accurate and up-to-date content. However, there may be instances of errors,
        inaccuracies or omissions on the platform. If you notice any incorrect information, please contact us promptly.
      </p>
      <h3>Responsibility for Corrections</h3>
      <p>
        While we strive to correct any errors, we do not guarantee that the content will be free from inaccuracies, and
        SevakArmy.com is not responsible for any reliance placed on such information.
      </p>
      <h2 style={{fontSize:'38px'}}>18. Disclaimer</h2>
      <p>
        SevakArmy.com is provided "as is" and "as available" without any warranties of any kind. We disclaim all
        warranties, express or implied, including but not limited to, implied warranties of merchantability, fitness for
        a particular purpose, non-infringement, and accuracy of information.
      </p>
      <p>
        SevakArmy.com does not guarantee uninterrupted or error-free access to the Services, and we will not be liable
        for any disruptions or errors that may occur during your use of the platform.
      </p>
      <h2 style={{fontSize:'38px'}}>19. Limitations of Liability</h2>
      <p>
        To the fullest extent permitted by law, SevakArmy.com will not be liable for any direct, indirect, incidental,
        consequential or punitive damages arising out of or related to your use of the Services, including but not
        limited to, damages for loss of profits, goodwill, data, or other intangible losses, even if we have been
        advised of the possibility of such damages.
      </p>
      <h2 style={{fontSize:'38px'}}>20. Indemnification</h2>
      <p>
        You agree to indemnify, defend and hold harmless SevakArmy.com, its affiliates, officers, directors, and
        employees from any claims, liabilities, damages, losses or expenses, including legal fees, arising out of or
        related to your violation of these legal terms, misuse of the Services, or infringement of any rights of another
        party.
      </p>
      <h2 style={{fontSize:'38px'}}>21. User Data</h2>
      <p>
        Any personal data or information you provide while using SevakArmy.com is governed by our Privacy Policy. You
        are responsible for ensuring that all information provided is accurate, complete and up-to-date. If you fail to
        provide accurate data, SevakArmy.com may suspend or terminate your account.
      </p>
      <h2 style={{fontSize:'38px'}}>22. Electronic Communications</h2>
      <p>
        By using the Services, you consent to receive communications from SevakArmy.com electronically. We may
        communicate with you via email or by posting notices on the platform. You agree that all agreements, notices,
        disclosures and other communications we provide to you electronically satisfy any legal requirements.
      </p>
      <h2 style={{fontSize:'38px'}}>23. SMS Text Messaging</h2>
      <p>
        SevakArmy.com may send SMS text messages to users who provide their phone numbers. You consent to receive such
        messages and understand that standard messaging rates may apply. You can opt-out of receiving SMS messages at
        any time.
      </p>
      <h2 style={{fontSize:'38px'}}>24. Miscellaneous</h2>
      <p>
        These legal terms constitute the entire agreement between you and SevakArmy.com regarding your use of the
        Services. If any provision is found to be invalid or unenforceable, the remaining provisions will continue to be
        valid and enforceable.
      </p>
      <p>
        Our failure to enforce any right or provision of these terms shall not be considered a waiver of those rights or
        provisions.
      </p>
      <h2 style={{fontSize:'38px'}}>25. Donations to Jainam Jivika Foundation</h2>
      <p>
        SevakArmy.com facilitates donations to Jainam Jivika Foundation through the platform. All donations are
        voluntary and processed through secure payment methods. By making a donation, you acknowledge and agree to the
        terms outlined in this section.
      </p>
      <h3>Use of Donations</h3>
      <p>
        Donations will be used solely for the purposes stated by Jainam Jivika Foundation. We ensure that funds are
        allocated responsibly and transparently to support charitable initiatives.
      </p>
      <h2 style={{fontSize:'38px'}}>26. Refunds</h2>
      <p>
        All donations made through SevakArmy.com are non-refundable. Once a donation is processed, it cannot be reversed
        or refunded. By making a donation, you acknowledge and accept this policy.
      </p>
      <h2 style={{fontSize:'38px'}}>27. Ethical Responsibilities for Volunteers</h2>
      <p>
        Volunteers engaged through SevakArmy.com are expected to adhere to high ethical standards in their conduct. This
        includes respect for the communities they serve, maintaining confidentiality, and acting in the best interests
        of the foundation and its beneficiaries.
      </p>
      <h2 style={{fontSize:'38px'}}>28. Use of Services</h2>
      <p>
        By using the Services, you agree to comply with all applicable laws and regulations. You are prohibited from
        using the Services for any unlawful or prohibited purpose.
      </p>
      <h2 style={{fontSize:'38px'}}>29. Contact Us</h2>
      <p>If you have any questions about these legal terms, please contact us at <a href="mailto:info@sevakarmy.com" target="_blank" rel="noreferrer"> info@SevakArmy.com </a>.</p>
      <p>
        Jainam Jivika Foundation 3, Jainam Tower, 3, Sai Park Belthika Nagar, Thergaon (Chinchwad) Pune, Maharashtra
        411033 India
      </p>
      <p>
        {" "}
        Email: <a href="mailto:info@jjfindia.com">info@jjfindia.com</a> Our team will respond to your queries promptly and provide guidance on how to best
        engage with the platform or resolve any concerns.
      </p>
    </div>
  );
};

export default TermsAndConditionsContent;
