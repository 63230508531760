import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import Honour from "../../images1/volunteer/home/honour.png";
import Certificates from "../../images1/volunteer/home/certificates.png";
import Stories from "../../images1/volunteer/home/stories.png";
import Networking from "../../images1/volunteer/home/networking.png";
import MeetNetwork from "../../images1/volunteer/home/meet-network.png";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import LeaderboardWorking from "../../images1/school-college/events/celebrate.jpg";
import icon from "../../images/cta/top-icon.png";

// const Services = [
//     {
//         Id: '01',
//         sImg: simg1,
//         sSImg: sSimg1,
//         title: 'Track Your Progress in Real Time',
//         slug: '1',
//         description: 'Each volunteer can log in to their profile and view their standing on the leaderboard. You will also receive regular email updates showing your rank and progress. These updates keep you motivated and informed as you continue contributing your time and skills to meaningful causes.',
//     },
//     {
//         Id: '02',
//         sImg: simg2,
//         sSImg: sSimg2,
//         title: 'Boost Your Rank with Referrals',
//         slug: '2',
//         description: "Help grow the Sevak Army by referring others to the platform. You will earn additional points that boost your position on the leaderboard for every person who registers as a volunteer through your referral link. It's a great way to encourage others to get involved while increasing your own impact.",
//     },
//     {
//         Id: '03',
//         sImg: simg3,
//         sSImg: sSimg3,
//         title: 'Why the Leaderboard Matters?',
//         slug: '3',
//         description: 'The Sevak Army Leaderboard isn’t just about competition - it’s about celebrating your dedication and contribution to social good. The more you volunteer, the higher you climb, and the more recognition you earn for your efforts. Plus, it helps you set personal goals and stay motivated as you serve your community.'
//     },
//     {
//         Id: '04',
//         sImg: simg4,
//         sSImg: sSimg4,
//         title: 'Earn Badges and Certificates',
//         slug: '4',
//         description: 'As you rise through the leaderboard, you’ll unlock e-badges and receive e-certificates for your achievements. These milestones recognise your commitment to volunteerism and can be shared on social media or added to your CV, showcasing your dedication to making a positive impact.',
//     },
//     {
//         Id: '05',
//         sImg: simg4,
//         sSImg: sSimg4,
//         title: 'Stay Motivated and Keep Giving Back',
//         slug: '4',
//         description: 'Whether aiming for the top spot or just wanting to track your volunteer hours, the Sevak Army Leaderboard keeps you engaged and inspired. It’s more than just points—it’s about building a legacy of service and making a difference, one task at a time.',
//     },
// ]

const featuresData = [
  {
    // iconClass: "flaticon-volunteer",
    svg: Honour,
    title: "Honour Top Volunteers",
    description:
      "Recognising those who have made a lasting impact through their dedication and commitment.",
  },
  {
    // iconClass: "flaticon-charity",
    svg: Certificates,
    title: "Present Awards and Certificates",
    description:
      "Volunteers earn certificates and awards for their exceptional contributions, marking key milestones in their journey.",
  },
  {
    // iconClass: "flaticon-solidarity",
    svg: Stories,
    title: "Share Success Stories",
    description:
      "Volunteers have the opportunity to share their experiences, motivating others to participate and make a difference.",
  },
];

const SchoolCollegeRecognitionEventsPage = (props) => {
  // const ClickHandler = () => {
  //     window.scrollTo(10, 0);
  // };

  useEffect(() => {
    const rotate = () => {
      const lastChild = document
        .querySelector(".manroted div:last-child")
        .cloneNode(true);
      document
        .querySelectorAll(".manroted div")
        .forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="wpo-about-section">
        <div className="container section-padding pt-5">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img width={580} src={LeaderboardWorking} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div className="wpo-section-title mt-0 mb-3 pb-0">
                    {/* <div className="custom-panell"> */}
                    <span className="">
                      Volunteer Recognition Events for Schools and Colleges
                    </span>
                    <h2 className="about-heading-second text-titleeee mb-0 pb-0">
                      Celebrate Achievements and Recognise Student Contributions
                    </h2>
                    {/* </div> */}
                  </div>
                  {/* <h3 className="mt-0">
                    How the Sevak Army Influencer Leaderboard Works?
                  </h3> */}
                  <p style={{ textAlign: "justify" }}>
                    At Sevak Army, we proudly recognise the dedication and hard
                    work of student volunteers from schools and colleges. To
                    honour their efforts, we organise Volunteer Recognition
                    Events to highlight the most active participants, present
                    awards, and offer special recognition to those making a
                    notable impact in their communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container section-padding pt-3">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle
              title="Recognition and Awards"
              paragarap="Volunteers who rank high on our Leaderboard or consistently
                contribute their time and efforts are invited to our Sevak Army
                Recognition Events. At these events, we"
            />
          </div>
        </div>

        <div className="wpo-features-area pt-0 px-2">
          <div className="features-wrap">
            {/* <div className="d-flex justify-content-center">
              <p
                className="pb-2 text-center col-12 col-md-10 col-lg-8"
                style={{ textAlign: "center" }}
              >
                Volunteers who rank high on our Leaderboard or consistently
                contribute their time and efforts are invited to our Sevak Army
                Recognition Events. At these events, we
              </p>
            </div> */}
            <div className="row justify-content-center column-gappp">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div
                      className="feature-item  align-items-start"
                      style={{ alignItems: "start", height: "197px" }}
                    >
                      <div className="feature-icon">
                        <div className="icon">
                          {feature.iconClass ? (
                            <i className={`fi ${feature.iconClass}`}></i>
                          ) : (
                            <img src={feature.svg} />
                          )}
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6" style={{ textAlign: "justify" }}>
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* new start */}

      <div className="container pt-3">
        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-3 mt-0">
                {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                <div className="custom-panell">
                  <h3>Networking and Community Building</h3>
                </div>
                <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                  These events offer a platform for student volunteers to meet
                  like-minded individuals, connect with NGOs, and engage with
                  community leaders. It's a chance to grow your network and be
                  part of a larger initiative for positive social impact.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img d-flex w-100 justify-content-center">
                <div className="wpo-about-right">
                  {/* <img src={abImg7} alt="img" /> */}
                  <img src={Networking} width={404} alt="networking" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 section-padding">
        <div className="wpo-about-wrap">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img d-flex w-100 justify-content-center">
                <div className="wpo-about-right pt-lg-3">
                  <img src={MeetNetwork} height={280} alt="img" />
                  {/* <img src="https://placehold.co/324x304/png" alt="Sign Up" /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-3 mt-0">
                {/* <div className="wpo-section-title mt-0 mb-5">
                                        <span>Become a Volunteer</span>
                                        <h2 className="about-heading-second">Join Sevak Army: Your Path to Make a Difference</h2>
                                    </div> */}
                <div className="custom-panell">
                  <h3>Be Part of the Celebration</h3>
                </div>
                <p className="mt-0 pt-0" style={{ textAlign: "justify" }}>
                  Whether you are just beginning your volunteering journey or
                  are already an active contributor, these events provide an
                  opportunity to celebrate the collective contributions of Sevak
                  Army volunteers and acknowledge the student change-makers
                  making a difference.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* new End */}

      {/* <section className="wpo-cta-section-s3 section-padding">
        <div className="container">
          <div className="bg-color" style={{ background: "#007560" }}>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <h3
                    className="pt-3"
                    style={{ color: "white", fontSize: "38px" }}
                  >
                    Join Sevak Army and Start Making a Difference
                  </h3>
                  <p className="px-2" style={{ color: "whitesmoke" }}>
                    Volunteer through Sevak Army today, and you could be among
                    those honoured for your contributions at our next event.
                    Your efforts deserve to be recognised and celebrated!
                  </p>

                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn mt-3"
                  >
                    Sign Up Now to Volunteer
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="wpo-cta-section section-padding">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>
                    <span>Join Sevak Army and Start Making a Difference</span>
                    {/* <h2>Register Now and Start Volunteering</h2> */}
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Volunteer through Sevak Army today, and you could be among
                      those honoured for your contributions at our next event.
                      Your efforts deserve to be recognised and celebrated!
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <button
                        onClick={() => props.setShow(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Sign Up Now to Volunteer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchoolCollegeRecognitionEventsPage;
