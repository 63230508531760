import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
// import About from '../../components/about/about';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo.svg'
import AboutSection from '../../components/about/about-section';
import CausesSecionS3About from '../../components/CausesSecionS3/CausesSecionS3-about';

const AboutPage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={'wpo-site-header'} />
            <PageTitle pageTitle={'About Us'} pagesub={'Organization'} />
            <AboutSection />
            {/* <CausesSecion /> */}
            {/* <ServiceSectionAbout/> */}
            {/* <CausesSecionS3About/> */}
            {/* <CtaSection /> */}
            {/* <EventSection /> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
