import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon from "../../images/cta/top-icon.png";
import abImg2 from "../../images1/organization/employee-engagement/person.png";
import SectionTitle from "../SectionTitle/SectionTitle";
// import Services from "../../api/Services";
// import ServiceCard from "./ServiceCard";
// import SectionTitle from "../SectionTitle/SectionTitle";
import blogs from "../../api/blogs";

import Instagram from "../../api/Instagram";
import Newsletterrr from "../../images1/organization/home/news.png";

import vsImg1 from "../../images/service/opportunity.png";
import vsImg2 from "../../images/service/experience.png";
import vsImg3 from "../../images/service/accredited.png";
import vsImg4 from "../../images/service/customer-service.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";

/* team imags */
import team1 from "../../images1/team/sevak-army-team-1.jpg";
import team2 from "../../images1/team/sevak-army-team-2.jpg";
import team3 from "../../images1/team/sevak-army-team-7.jpg";
import team4 from "../../images1/team/sevak-army-team-5.jpg";
import team5 from "../../images1/team/sevak-army-team-10.jpg";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";

/* HeroSlide Image  */
import HeroSlide1 from "../../images1/organization/home/sevak-army-organization-home-slider-11.png";
import HeroSlide2 from "../../images1/organization/home/sevak-army-organization-home-slider-2.jpg";
import HeroSlide3 from "../../images1/organization/home/sevak-army-organization-home-slider-33.png";
import HeroSlide4 from "../../images1/organization/home/sevak-army-organization-home-slider-14.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import About4 from "../../images1/organization/home/sevak-army-organization-home-1.jpg";
import About4s2 from "../..//images/slider/stick-man.svg";
import image1 from "../../images1/team/sevak-army-team-8.jpg";
import image2 from "../../images1/team/sevak-army-team-4.jpg";
import image3 from "../../images1/team/sevak-army-team-5.jpg";
import Shape from "../../images/testimonial/bg.png";
import ServiceCard from "../ServiceSection/ServiceCard";
import NewsletterPopup from "../NewsletterPopup";
import Difference from "../../images1/volunteer/home/difference.png";
import Pace from "../../images1/volunteer/home/speed.png";
import Productivity from "../../images1/volunteer/home/productivity.png";

const Services2 = [
  {
    Id: "01",
    sImg: Difference,
    sSImg: sSimg1,
    title: "ABC Tech Pvt Ltd",
    slug: "1",
    description: "1,500 Volunteer Hours",
    subdes: "CSR Focus: Education and Sustainability",
  },
  {
    Id: "02",
    sImg: Pace,
    sSImg: sSimg2,
    title: "Green Earth Solutions ",
    slug: "2",
    description: "1,350 Volunteer Hours",
    subdes: "CSR Focus: Environmental Conservation",
  },
  {
    Id: "03",
    sImg: Productivity,
    sSImg: sSimg3,
    title: "Inspire Enterprises",
    slug: "3",
    description: "950 Volunteer Hours",
    subdes: "CSR Focus: Education and Youth Mentorship",
  },
  {
    Id: "04",
    sImg: Productivity,
    sSImg: sSimg3,
    title: "Harmony Innovations",
    slug: "3",
    description: "700 Volunteer Hours",
    subdes: "CSR Focus: Women’s Empowerment and Child Welfare",
  },
];

const Services = [
  {
    Id: "01",
    sImg: vsImg1,
    sSImg: sSimg1,
    title: "Motivate Employees to Volunteer",
    slug: "1",
    description:
      "organisations can inspire employees to join the Sevak Army, where they can use their skills to contribute to important causes. This helps build a strong sense of community and responsibility.",
  },
  {
    Id: "02",
    sImg: vsImg2,
    sSImg: sSimg2,
    title: "Digital Certificates for Recognition",
    slug: "2",
    description:
      "Employees earn digital certificates for their volunteering work, recognising their dedication to helping others. They can proudly share these accomplishments, which are great for both personal and professional growth.",
  },
  {
    Id: "03",
    sImg: vsImg3,
    sSImg: sSimg3,
    title: "Measure CSR Impact",
    slug: "3",
    description:
      "Each employee’s volunteer hours count towards the organisation’s overall CSR impact, making it easy to showcase results and the organisation’s dedication to giving back.",
  },
  {
    Id: "04",
    sImg: vsImg4,
    sSImg: sSimg3,
    title: "Create a Culture of Service",
    slug: "3",
    description:
      "Our points, digital badges, and leaderboard make volunteering more engaging for employees. This system keeps them motivated to participate, enhancing the organisation’s overall CSR impact.",
  },
];

const Testimonial = [
  {
    id: "01",
    text: "Sevak Army enabled our organization to give back meaningfully. Through CSR initiatives, we engaged employees in impactful volunteer projects, strengthening community ties and fostering a culture of social responsibility.",
    title: "CSR Manager",
    subtitle: "Manager",
    image: image1,
  },
  {
    id: "02",
    text: "Registering with Sevak Army transformed our CSR efforts. The platform provided seamless access to volunteer opportunities that aligned with our values, making it easy for us to contribute to lasting change.",
    title: "CSR Lead",
    subtitle: "Leader",
    image: image2,
  },
  {
    id: "03",
    text: "Sevak Army made our CSR initiatives more impactful. Their streamlined approach allowed us to connect with causes that resonated with our mission, helping us drive real social impact.",
    subtitle: "CSR Coordinator",
    title: "CSR Coordinator",
    image: image3,
  },
];

const Home = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [shownewsletter, setShowNewsletter] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const settings3 = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content heroo-contenttt">
                <div className="slide-title-sub">
                  <h6>Empowering Purpose Through Service</h6>
                </div>
                <div className="slide-title">
                  <h2>Helping You Achieve Your CSR Goals</h2>
                </div>
                <div className="slide-text">
                  <p>
                    Register your organisation on Sevak Army platform and
                    encourage your employees to engage in social work.
                  </p>
                </div>
                <div className="hero-btn">
                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn"
                  >
                    Join Sevak Army
                  </Link>
                  <div className="wpo-supporter-img">
                    <ul className="wpo-supporter-slide">
                      <Slider {...settings}>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Aliza Anny"
                        >
                          <img src={team1} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="David Miller"
                        >
                          <img src={team2} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Maria Silva"
                        >
                          <img src={team3} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Takila Mas"
                        >
                          <img src={team4} alt="" />
                        </li>
                        <li
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title="Jenny Lawba"
                        >
                          <img src={team5} alt="" />
                        </li>
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          parallax={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1800,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide1})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide2})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide3})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${HeroSlide4})` }}
            ></div>
          </SwiperSlide>
          ...
        </Swiper>
      </section>
      <section className="wpo-about-section-s4 section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12  order-lg-2">
                <div className="wpo-about-img-s5">
                  <div className="image">
                    <img src={About4} alt="" />
                  </div>
                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      <div className="icon">
                        <i>
                          <img src={About4s2} alt="" />
                        </i>
                      </div>
                      <div className="content">
                        <h3>100+</h3>
                        <p>Happy Organizations</p>
                      </div>
                    </div>
                    <div className="wpo-supporter-img">
                      <ul className="wpo-supporter-slide">
                        <Slider {...settings}>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Aliza Anny"
                          >
                            <img src={team1} alt="" />
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="David Miller"
                          >
                            <img src={team2} alt="" />
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Maria Silva"
                          >
                            <img src={team3} alt="" />
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Takila Mas"
                          >
                            <img src={team4} alt="" />
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Jenny Lawba"
                          >
                            <img src={team5} alt="" />
                          </li>
                        </Slider>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>We Are Here To Help You!</span>
                    <h2 className="text-titleeee">
                      Achieve Your CSR Goals With Ease
                    </h2>
                  </div>
                  <p>
                    By registering your organisation with Sevak Army, you can
                    encourage your employees to engage in social work and
                    strengthen your CSR initiatives while creating a meaningful
                    impact in society.
                  </p>
                  {/* <div className="about-btn ">
                    <Link to="/about" className="theme-btn d-flex justify-constent-center">
                      Discover More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-about-section section-padding pt-0">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-5">
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={abImg2} width={850} alt="img" />
                  </div>
                </div>
              </div>
              <div className="w-lg-50 w-100">
                <div className="wpo-about-text">
                  <div className="wpo-section-title mb-3">
                    {/* <span>Welcome to Sevak Army!</span> */}
                    <h2 className="about-heading-third text-titleeee">
                      Who Are We?
                    </h2>
                  </div>
                  <p>
                    We’re a platform dedicated to helping organisations engage
                    their employees in impactful volunteer work, making it
                    easier to support communities and achieve Corporate Social
                    Responsibility (CSR) goals. Our mission is to enable
                    businesses to make a difference by integrating social
                    responsibility into their daily work. We envision a future
                    where organisations lead the way in creating positive change
                    - focusing not only on profit but also on uplifting
                    communities and contributing to a better world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"section-padding pt-0 " + "wpo-service-section "}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <SectionTitle
                // subtitle="How Does It Work?"
                title="How organisations Can Drive CSR through Sevak Army?"
                paragarap="Sevak Army is a platform for organisations to involve their employees in meaningful volunteer work as part of Corporate Social Responsibility (CSR) efforts. By signing up, organisations can encourage their teams to take part in various social initiatives, making a real change in communities."
              />
            </div>
          </div>
          <div className="service-wrap mt-3">
            <div className="row justify-content-center">
              {Services.slice(0, 4).map((Service, sitem) => (
                <div
                  className="col col-xl-5 col-lg-5 col-md-7 col-sm-8 col-12"
                  key={sitem}
                >
                  <ServiceCard
                    height="390px"
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section-s3 section-padding pt-0 pb-0">
        <div className="container">
          <div className="bg-color" style={{ background: "#007560" }}>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  {/* <div className="icon mx-2">
                    <img src={icon} alt="" />
                  </div> */}
                  {/* <span>Start Your CSR Journey Today</span> */}
                  <h2 className="mb-3">Start Your CSR Journey Today</h2>
                  <p className="text-center" style={{ color: "whitesmoke" }}>
                    Empower your teams, encourage community service, and make a
                    positive impact. Join us today!{" "}
                  </p>
                  <Link
                    onClick={() => props.setShow(true)}
                    to="#"
                    className="theme-btn mt-3"
                  >
                    Get Started Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"section-padding pb-0"}>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="" title="Testimonials" />
            </div>
          </div>
          <div className="wpo-testimonial-slider">
            <Slider {...settings1}>
              {Testimonial.map((Testimonial, item) => (
                <div className="testimonial-item" key={item}>
                  <div className="content" style={{ height: "540px" }}>
                    <div className="icon">
                      <i className="flaticon-quote"></i>
                    </div>
                    <p>{Testimonial.text}</p>
                    <div className="shape">
                      <img src={Shape} alt="" />
                    </div>
                  </div>
                  <div className="bottom-content">
                    <div className="image">
                      <img src={Testimonial.image} alt="" />
                    </div>
                    <div className="text">
                      <h3>{Testimonial.title}</h3>
                      <span>{Testimonial.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="w-100 d-flex justify-content-center mt-5 mb-3">
            <div className="col-lg-6 col-md-12 col-12 justify-content-center">
              <h2 className="text-center" style={{ fontWeight: "bold" }}>
                Register Your organisation on Sevak Army and Make A Positive
                Impact
              </h2>
              <p className="text-center">
                Take the first step towards building a socially responsible
                organisation.
              </p>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="theme-btn"
                  onClick={() => props.setShow(true)}
                >
                  Register Your organisation Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section added as per requirement */}

      <div className="container section-padding">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12">
            <SectionTitle
              subtitle="Leaderboard"
              title="Celebrate Your Impact!"
              paragarap="Climb the ranks, track your CSR contributions, and see your team's community impact grow."
            />
          </div>
        </div>
        <div className="service-wrap">
          <p className="w-100 text-center"></p>
          <div
            className="row justify-content-center align-items-center pt-3"
            style={{ columnGap: "30px", rowGap: "30px" }}
          >
            {Services2.slice(0, 19).map((Service, sitem) => (
              <div
                className="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                key={sitem}
              >
                <div className="service-card-wrapper d-flex flex-column h-100">
                  <div
                    className="service-item px-3"
                    style={{
                      height: "400px",
                      width: "313px",
                      alignSelf: "stretch",
                      marginBottom: "10px",
                      margin: "auto",
                    }}
                  >
                    <div className="icon">
                      <img src={Service.sImg} width={60} alt="img" />
                    </div>
                    <div className="text">
                      <h2 className="services-title-text fw-bold mt-4">
                        {/* <Link className="services-title-text" to={``} style={{ cursor: "default" }}> */}
                        {Service.title}
                        {/* </Link> */}
                      </h2>
                      <p
                        className="service-paragraph volunteer-home-text text-center"
                        style={{ textAlign: "justify" }}
                      >
                        {" "}
                        {Service.description} <br />
                        {Service.subdes}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <section className={" section-padding pt-5"}>
        <div className="container-fluid">
          <SectionTitle subtitle="Our Gallery" title="Look Into Our Gallery." />

          <div className="instagram-wrap">
            <div className="instagram-slider">
              <Slider {...settings3}>
                {Instagram.map((item) => (
                  <div className="instagram-card" key={item.id}>
                    <div className="image">
                      <Link
                        to={item.imag}
                        className="fancybox"
                        data-fancybox-group="gall-1"
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(item.imag);
                        }}
                      >
                        <img src={item.imag} alt={`Instagram ${item.id}`} className="img img-responsive" />
                        <div className="popup-icon">
                          <i className="ti-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="text">
                      <span>{item.title}</span>
                      <h2>{item.subtitle}</h2>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        {modalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-wrap">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage} alt="Modal" />
              </div>
            </div>
          </div>
        )}
      </section> */}

      {/* <section className={props.hclass}>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Blogs" title="Explore our latest insights on corporate volunteerism and CSR." />
            </div>
          </div>
          <div className="blog-wrap mt-5">
            <div className="row">
              {blogs.map((blog, bl) => (
                <div className="col col-lg-3 col-md-6 col-12" key={bl}>
                  <div className="blog-single-card">
                    <div className="image">
                      <img src={blog.screens} alt="" />
                      <div className="top-date">
                        {blog.create_at}
                        <div className="shape">
                          <svg width="8" height="9" viewBox="0 0 8 9" fill="none">
                            <path d="M7.99999 9.0001L-9.79769e-05 9.00023L8.00005 0.500149L7.99999 9.0001Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <ul className="top-content">
                        <li>
                          <div className="image-single">
                            <img src={blog.bsing} alt="" />
                          </div>
                          {blog.athor}
                        </li>
                        <li className="mt-3">
                          03 Comments
                        </li>
                      </ul>
                      <h2>
                        <Link className="blog-title-home" onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                          {blog.title}
                        </Link>
                      </h2>
                      <p>{blog.subtitle}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className={"section-padding wpo-service-section s1"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-12">
              <SectionTitle
                subtitle="How Does It Work?"
                title="Sevak Army makes volunteer management easy and rewarding."
              />
            </div>
          </div>
          <div className="service-wrap mt-3">
            <div className="row">
              {Services.slice(0, 4).map((Service, sitem) => (
                <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                  <ServiceCard
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      <div className="container section-padding pt-0">
        <div className="wpo-about-wrap">
          <div className="row ">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-3 mt-0">
                <div
                  className="wpo-section-title  mt-0"
                  style={{
                    textAlign: "left",
                    margin: "0px",
                    marginBottom: "10px",
                  }}
                >
                  <span>Stay Connected with Sevak Army!</span>
                  <h2 className="about-heading-second text-titleeee w-100">
                    Join Us in Making a Difference!{" "}
                  </h2>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Get the latest updates on volunteer opportunities, CSR
                  initiatives, and impactful stories. Be the first to know about
                  events, resources, and ways to make a difference.
                </p>
                <h3>Subscribe to Our Newsletter</h3>

                <div>
                  <button
                    className="theme-btn mt-3"
                    onClick={() => setShowNewsletter(true)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Subscribe to us
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                <div className="wpo-about-right pt-5">
                  <img src={Newsletterrr} width={540} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>

                    <span>Become a CSR Ambassador!</span>
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Lead your team’s social initiatives, inspire others, and
                      drive positive change.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <button
                        onClick={() => setShowNewsletter(true)}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Join as Ambassador
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="wpo-cta-section-s3 section-padding pt-0 pb-5">
        <div className="container">
          <div className="bg-color" style={{ background: "#007560" }}>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <h2 className="mb-3 mt-0 ">Join as Ambassador</h2>
                  <p className="text-center" style={{ color: "whitesmoke" }}>
                    Lead your team’s social initiatives, inspire others, and
                    drive positive change.{" "}
                  </p>
                  <Link
                    onClick={ClickHandler}
                    to="ambassador-programme"
                    className="theme-btn mt-3"
                  >
                    Join as Ambassador
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section section-padding mt-5">
        <div className="bg-overlay">
          <div className="container">
            <div className="bg-color">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="cta-wrap">
                    <div className="icon mx-2">
                      <img src={icon} alt="" />
                    </div>

                    <span>Get Recognised for Your Commitment!</span>
                    <p
                      className="text-center pt-2 px-2"
                      style={{ textAlign: "justify", color: "white" }}
                    >
                      Join us in celebrating milestones, achievements, and your
                      dedication to social good.
                    </p>
                    <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                      <Link
                        to="certificates-recognition"
                        onClick={ClickHandler}
                        className="theme-btn"
                        style={{ width: "fit-content" }}
                      >
                        Explore Recognition Events
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
    </>
  );
};

export default Home;
