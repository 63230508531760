import React, { Fragment } from "react";
import Navbar from '../../components/Navbar/Navbar';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from "../../images/logo.svg";
import LeaderboardPage from "../../components/Organization/leaderboard";
import PageTitle from "../../components/pagetitle/PageTitle";

const Leaderboard = () => {
    return (
        <>
        <Fragment>
            <Navbar Logo={Logo} hclass={'wpo-site-header'}/>
            <PageTitle pageTitle={'Leaderboard'} pagesub={'Organization'} />
            <LeaderboardPage />
            <Scrollbar />
            <Footer />
            <Scrollbar />
        </Fragment>
        </>
    );
};
export default Leaderboard