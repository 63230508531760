import React from "react";
import abImg2 from "../../images1/organization/about-us/sevak-army-organization-about-us-22.png";
import Empowering from "../../images1/organization/home/empowering.png";
import Difference from "../../images1/organization/home/csr.png";
import Helporg from "../../images1/organization/home/help.png";

import abImg5 from "../../images1/organization/about-us/sevak-army-organization-about-us-5.jpg";
import WhyChooseSevak from "../Volunteer/WhyChooseSevak";

const AboutSection = (props) => {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };

  return (
    <section className="wpo-about-section">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row align-items-start mt-5 pt-2">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left">
                  <img src={abImg2} alt="img" />
                </div> */}
                <div className="wpo-about-right pt-5">
                  <img src={Difference} alt="img" height={400} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title mb-3">
                  <span>About Us</span>
                  <h2 className="about-section-heading-forth">
                    Make a Difference with Corporate Social Responsibility
                  </h2>
                </div>
                <p>
                  Welcome to Sevak Army! We are a platform where organisations
                  can sign up and encourage their employees to get involved in
                  volunteer work. Our aim is to make it easy for businesses to
                  help communities and reach their CSR (Corporate Social
                  Responsibility) goals. We believe every organisation, big or
                  small, can make the world a better place. By working with us,
                  businesses can give back to society and help solve important
                  problems while keeping their employees engaged in meaningful
                  activities.
                </p>

                {/* <div className="about-info-wrap">
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-target"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Mission</h4>
                      <p className="mission-paragraph">
                        Enable businesses to transform the world around them
                        through organized volunteerism.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-mountain"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Vision</h4>
                      <p className="vision-paragraph">
                        Create a future where corporate volunteerism becomes the
                        cornerstone of social change.
                      </p>
                    </div>
                  </div>
                </div>

                <Link
                  onClick={ClickHandler}
                  to="/about"
                  className="theme-btn"
                >
                  Discover More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container section-padding">
        <div className="wpo-about-wrap">
          <div className="flex-containerrr justify-content-center gap-5">
            <div className="">
              <div
                className="wpo-about-text mt-0"
                style={{ marginLeft: "20px" }}
              >
                <div className="wpo-section-title mb-3">
                  <span>Our Mission</span>
                  <h2 className="about-section-heading-forth">
                    Help organisations Lead Social Work
                  </h2>
                </div>
                <p>
                  Our mission is simple: to help businesses do good in the
                  world. We make it easy for organisations to include social
                  responsibility in their daily work. Whether it's helping the
                  environment, improving education, or supporting health and
                  well-being, Sevak Army is a platform where organisations
                  encourage their employees to join as volunteers for their CSR
                  activities that make a real difference. We believe every
                  organisation can help make the world better.
                </p>
              </div>
            </div>
            <div className="">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left-side">
                  <img src={abImg4} alt="img" />
                </div> */}
                <div className="wpo-about-right pt-5">
                  <img src={Helporg} width={800} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container section-padding pt-0">
        <div className="wpo-about-wrap">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left">
                  <img src={abImg6} alt="img" />
                </div> */}
                <div className="wpo-about-right pt-5">
                  <img src={abImg5} alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-2 mt-0">
                <div className="wpo-section-title mt-0 mb-3">
                  <span>Our Vision</span>
                  <h2 className="about-section-heading-fifth text-titleeee">
                    A World Where Businesses Help Change The Communities
                  </h2>
                </div>
                <p>
                  We dream of a world where businesses lead the way in making
                  positive changes. Our vision is for organisations to not only
                  focus on making money but also on helping others. We want to
                  inspire businesses to give back to their communities, creating
                  a wave of positive action that changes lives. By joining Sevak
                  Army, your organisation can be part of this change.
                </p>

                {/* <div className="about-info-wrap">
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-target"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Mission</h4>
                      <p className="mission-paragraph">
                        Enable businesses to transform the world around them
                        through organized volunteerism.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-mountain"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Vision</h4>
                      <p className="vision-paragraph">
                        Create a future where corporate volunteerism becomes the
                        cornerstone of social change.
                      </p>
                    </div>
                  </div>
                </div>

                <Link
                  onClick={ClickHandler}
                  to="/about"
                  className="theme-btn"
                >
                  Discover More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Below section not in requirement */}
      {/* section start */}
      {/* <div className="">
        <WhyChooseSevak />
      </div> */}
      {/* section end */}
    </section>
  );
};

export default AboutSection;
