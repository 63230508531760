import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cta1 from "../../images/cta/shape-1.png";
import Cta2 from "../../images/cta/top-icon.png";
import Cta3 from "../../images/cta/shape-2.png";
import Newsletter from "../../images1/volunteer/home/newsletter.png";
import Inform from "../../images1/volunteer/home/inform.jpg";
import NewsletterPopup from "../NewsletterPopup";

const NgoCtaSection = () => {
  const [shownewsletter, setShowNewsletter] = useState(false);
  return (
    <>
      <div className="w-100 container ">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row ">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text p-3 mt-0">
                  <div
                    className="wpo-section-title mt-0"
                    style={{ textAlign: "start", margin: "0px" }}
                  >
                    <span>Stay Informed, Stay Inspired</span>
                    <h2 className="about-heading-second w-100">
                      Stay Connected with Sevak Army{" "}
                    </h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    Subscribe to our newsletter and stay informed! Get the
                    latest updates on volunteer initiatives, upcoming events,
                    inspiring stories, and valuable tips for your NGO. Be the
                    first to know about new projects, volunteer drives, and
                    impactful collaborations happening across India.
                  </p>
                  <h3>Sign Up Today</h3>
                  <p>
                    Join our network and receive regular updates straight to
                    your inbox.{" "}
                  </p>
                  <div className="mt-4">
                    <button
                      className="theme-btn"
                      onClick={() => setShowNewsletter(true)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Subscribe to Our Newslette
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-12"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg5} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={Inform} width={580} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewsletterPopup
        shownewsletter={shownewsletter}
        setShowNewsletter={setShowNewsletter}
      />
    </>
  );
};

export default NgoCtaSection;
