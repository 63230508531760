import React, { Fragment } from "react";
import SocialImpact from "../../images1/ngo/home/social-impact.png";
import Free from "../../images1/ngo/home/free.png";
import User from "../../images1/ngo/home/user.png";
import Expansion from "../../images1/ngo/home/expansion.png";
import Communication from "../../images1/ngo/home/communication.png";
import Clipboards from "../../images1/ngo/home/clipboard.png";
import Flexibility from "../../images1/ngo/home/flexibility.png";
import Matching from "../../images1/ngo/home/link.png";
import Visibility from "../../images1/ngo/home/high-visibility.png";
import Requirements from "../../images1/ngo/home/personal-assistant.png";
import SimplifiedCommunication from "../../images1/ngo/home/simplified-communication.png";
import Growth from "../../images1/ngo/home/growth.png";
import Reliability from "../../images1/ngo/home/reliability.png";
import Ranking from "../../images1/ngo/home/ranking.png";
import Events from "../../images1/ngo/home/event.png";
import Feedback from "../../images1/ngo/home/customer-feedback.png";
import Project from "../../images1/ngo/home/project-management.png";
import Support from "../../images1/ngo/home/customer.png";
import Improvement from "../../images1/ngo/home/continuous-improvement.png";
import icon from "../../images/cta/top-icon.png";

function NgoBenefitsSection(props) {
    return (
        <Fragment>
            <>
                <section className="wpo-about-section mb-0 mt-5 pb-0 pt-5">
                    <div className="container ">
                        <div className="wpo-about-wrap">
                            <div className="flex-containerrr justify-content-center gap-5">
                                <div className="">
                                    <div className="wpo-about-img" style={{ marginRight: '10px' }}>
                                        <div className="bg-image">
                                            <img src={SocialImpact} width={800} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="wpo-about-text">
                                        <div className="wpo-section-title">
                                            <span>Amplify Your Reach with Sevak Army</span>
                                            <h2 className="text-titleeee">Why Should NGOs Register on Sevak Army?</h2>
                                        </div>
                                        <p className="volunteer-home-text">
                                            Sevak Army is more than just a platform; it's a powerful tool designed to connect NGOs with dedicated volunteers across India. By joining Sevak Army, your organisation gains access to passionate individuals, streamlined task management, and increased visibility - all to help you make a bigger, more lasting impact.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="wpo-causes-section-s2 section-padding">
                    <div className="causes-wrap">
                        <div className="container">
                            <h3 className="text-center">Here's why you should register your NGO with Sevak Army </h3>

                            <section className="container mt-5">
                                <div className="">
                                    <section className={" wpo-fun-fact-section-s3 pb-0"}>
                                        <div className="container mt-3">
                                            <div className="w-100 service-wrap">
                                                <div className="w-100 row justify-content-center gap-3">
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon">
                                                                    <img src={Free} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">No Cost for Registration</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Registering your NGO on Sevak Army is free, allowing you to connect with volunteers without any financial burden.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#FFFCF4', border: '1px solid #EBB011' }}>
                                                                    <img src={User} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Access to Committed Volunteers</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Connect with a dedicated pool of volunteers who are eager to support your cause. Save time by finding passionate individuals ready to make a real difference.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F3FFFC', border: '1px solid #00AF90' }}>
                                                                    <img src={Expansion} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Expand Your Reach Across India </h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Sevak Army brings visibility to NGOs nationwide, helping you reach volunteers from cities, towns, and rural areas who might otherwise not have known about your organisation.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FFF8', border: '1px solid #66BB6A' }}>
                                                                    <img src={Communication} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Direct Communication with Volunteers</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Once you select a volunteer for your task after reviewing their application, you can directly chat with them through the platform. This makes coordination easy and ensures smooth task management.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FDFF', border: '1px solid #1ECFFE' }}>
                                                                    <img src={Clipboards} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Easy Task Posting & Management</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Simplify your operations by posting tasks directly on the platform. Easily assign, track, and manage volunteer tasks with a user-friendly interface.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#FFFCF4', border: '1px solid #EBB011' }}>
                                                                    <img src={Flexibility} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Flexible Volunteer Options</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Find volunteers for both short-term and long-term projects, giving your NGO the flexibility to manage different kinds of tasks as needed.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F3FFFC', border: '1px solid #00AF90' }}>
                                                                    <img src={Matching} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Efficient Volunteer Matching</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Save time on recruitment. Sevak Army helps match your tasks with volunteers who have the skills and passion to meet your needs.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FFF8', border: '1px solid #66BB6A' }}>
                                                                    <img src={Visibility} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Enhanced Visibility for Your Cause</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Gain exposure to a larger audience and attract more support for your initiatives. Let more people learn about the great work you and your NGO are doing.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FDFF', border: '1px solid #1ECFFE' }}>
                                                                    <img src={Requirements} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Customisable Volunteer Requirements</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Specify the skills, time commitment, and other requirements you need, and get volunteers who fit those needs perfectly.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#FFFCF4', border: '1px solid #EBB011' }}>
                                                                    <img src={SimplifiedCommunication} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Simplified Communication</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Easily communicate with volunteers through the platform’s chat feature. Coordinate tasks, share updates, and keep everyone informed without hassle.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F3FFFC', border: '1px solid #00AF90' }}>
                                                                    <img src={Growth} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Track Progress Effortlessly</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Monitor the progress of tasks in real-time. Stay updated on volunteer activities, ensuring everything runs smoothly and efficiently.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FFF8', border: '1px solid #66BB6A' }}>
                                                                    <img src={Reliability} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Reliable and Verified Volunteers</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>All volunteers on Sevak Army are verified, so you can trust that you’re connecting with genuine, motivated individuals who are committed to helping.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FDFF', border: '1px solid #1ECFFE' }}>
                                                                    <img src={Ranking} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Gamified Leaderboards for Engagement</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Sevak Army’s leaderboard feature encourages volunteers to stay active and engaged. NGOs can also see which tasks are most popular and learn from top performers.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#FFFCF4', border: '1px solid #EBB011' }}>
                                                                    <img src={Events} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Promote Your Events & Initiatives</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Easily share information about your NGO’s events, drives, or campaigns on Sevak Army, attracting more volunteers and gaining wider support.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F3FFFC', border: '1px solid #00AF90' }}>
                                                                    <img src={Feedback} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Feedback Mechanism for Improvement</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Provide feedback on volunteer performance to help them grow, and receive feedback to improve your own volunteer programmes. </p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FFF8', border: '1px solid #66BB6A' }}>
                                                                    <img src={Project} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Effortless Project Coordination</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Manage various projects simultaneously with user-friendly tools. Focus on your core mission while Sevak Army simplifies volunteer coordination and task tracking.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#F5FDFF', border: '1px solid #1ECFFE' }}>
                                                                    <img src={Support} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Support for Urban & Rural Initiatives</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Whether you operate in a city or a village, Sevak Army helps you reach volunteers who are ready to support your local projects.</p>
                                                        </div>
                                                    </div>
                                                    <div className="service-item px-4 py-4 col-xl-3 col-lg-4 col-md-7 col-sm-8 col-11 sevak-custom-carddd">
                                                        <div className="info d-flex flex-column justify-content-center flex-no-wrap">
                                                            <div className="w-100 d-flex py-4 justify-content-center">
                                                                <div className="icon" style={{ backgroundColor: '#FFFCF4', border: '1px solid #EBB011' }}>
                                                                    <img src={Improvement} width={60} alt="img" />
                                                                </div>
                                                            </div>
                                                            <h2 className="text-center">Continuous Improvement Through Insights</h2>
                                                            <p className="w-100 text-center ms-0" style={{ maxWidth: '100%' }}>Access insights from volunteer feedback and platform data, helping you improve your programmes and strategies over time.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>

                        </div>
                    </div>
                </section>

                {/* <section className="wpo-cta-section-s3 section-padding">
                    <div className="container">
                        <div className="bg-color" style={{ background: "#007560" }}>
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="cta-wrap">
                                        <span>Join Hands, Amplify Impact</span>
                                        <h2 className="mb-3 mt-2">
                                            Ready to Empower Your Mission?
                                        </h2>
                                        <p className="text-center" style={{ color: 'whitesmoke' }}>Join Sevak Army today and start connecting with volunteers who share your passion for making a difference. Together, we can drive real, positive change.</p>
                                        <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                                            Register Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="wpo-cta-section section-padding">
                    <div className="bg-overlay">
                        <div className="container">
                            <div className="bg-color">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="cta-wrap">
                                            <div className="icon mx-2">
                                                <img src={icon} alt="" />
                                            </div>
                                            <span>Join Hands, Amplify Impact</span>
                                            <p
                                                className="text-center pt-2 px-2"
                                                style={{ textAlign: "justify", color: "white" }}
                                            >
                                                Join Sevak Army today and start connecting with volunteers who share your passion for making a difference. Together, we can drive real, positive change.
                                            </p>
                                            <div className="d-flex flex-column gap-3 justify-content-center align-items-center w-100">
                                                <span>Ready to Empower Your Mission?</span>
                                                <button
                                                    onClick={() => props.setShow(true)}
                                                    className="theme-btn"
                                                    style={{ width: "fit-content" }}
                                                >
                                                    Register Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Fragment>
    );
}

export default NgoBenefitsSection;
